









































import { Component, Vue, Emit } from 'vue-property-decorator'
import { AddDrugStore,UpdateDrugStore} from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import constant from '@/utils/constant'
import { timeFormate } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'
import { VImageUpload } from '@/components/upload'
import area from '@/assets/area.json'

@Component({
  components: {
    VImageUpload
  },
})
export default class EditDrugStore extends Vue {
  private constant = constant
  private show = false
  private data: any = {}
  private areaOptions = area.data
  private areaProps = {value: 'value'}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    drugstoreName: [this.requiredRule],
    customerId: [this.requiredRule],
  }
  private supplierSelect = []
  private get role() {
    return getRole();
  }

  private get isAllValid(){
    return this.data.busicardValid == '长期'
  }
  private changeValid(v: boolean){
    if(v){
      this.data.busicardValid = '长期'
    } else {
      this.data.busicardValid = ''
    }
  }

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {}
  }
  public async open(param: any) {
    this.show = true
    if(param){
      this.data = {...param}
      this.data.area = [param.provinceName,param.cityName,param.districtName]
    } else {
      const d = new Date()
      this.data.customerId = "C"+timeFormate(new Date(),"YYMMDDHHmmss")
    }
  }

  private areaChange(data: Array<string>){
    this.data.provinceName = data[0]
    this.data.cityName = data[1]
    this.data.districtName = data[2]
  }

  public async save() {
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      if(!this.data.id ){
        await AddDrugStore(this.data)
      } else {
        await UpdateDrugStore(this.data)
      }
      Message.success('保存成功')
      this.close()
    })
  }
}
