




























import { Component, Vue, Emit } from 'vue-property-decorator'
import { AddDrugBatch, UpdateOrerDetailsBatchInfo} from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import constant from '@/utils/constant'
import { timeFormate } from '@/utils/date/DateUtil'
import InputRestrict from '@/utils/InputRestrict'

@Component({
  components: {
  },
})
export default class EditOrderBatch extends Vue {
  private constant = constant
  private InputRestrict = InputRestrict
  private show = false
  private data: any ={drugCode:"",newQty:0,price: 0,batchNum:"",prodDate:"",validity:"",batchCount:0}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private dateRule = { validator: this.prodDateRule,trigger: 'blur' }
  private batchCountRule2 = { validator: this.batchCountRule,trigger: 'blur' }
  private batchCountAccordRulev = { validator: this.batchCountAccordRule,trigger: 'blur' }
  private rules = {
    drugCode: [this.requiredRule],
    newQty: [this.requiredRule],
    price: [this.requiredRule],
    batchNum: [this.requiredRule,{ validator: this.batchNumRule,trigger: 'blur' }],
    prodDate: [this.requiredRule,this.dateRule,this.batchCountAccordRulev],
    validity: [this.requiredRule,this.dateRule,this.batchCountAccordRulev],
    batchCount: [this.requiredRule,this.batchCountRule2,this.batchCountAccordRulev],
  }

  private dateReg = /^\d{4}-\d{2}-\d{2}(,\d{4}-\d{2}-\d{2})*$/
  private numReg = /^\d+(,\d+)*$/
  private prodDateRule(rule, value, callback){
    if (value === '') {
      callback();
    } else {
      if(!this.dateReg.test(value)){
        callback(new Error('日期格式正确，正确因为 2023-05-27 如有多个用,隔开'));
      }
      callback();
    }
  }
  private batchCountAccordRule(rule, value, callback){
    if (value === '') {
      callback();
    } else {
      const  bnc = this.data.batchNum ? this.data.batchNum.split(",").length : 0
      const vc = value.split(",").length
      if(bnc != vc){
        callback(new Error('数量与比批次个数不一致，当前批次个数为'+bnc));
      }
      callback();
    }
  }
  private batchNumRule(rule, value, callback){
    if (value === '') {
      callback(new Error('批次信息为空'));
    } else {
      //触发其他校验
      const f = this.$refs.form as ElForm
      f.validateField('prodDate')
      f.validateField('validity')
      f.validateField('batchCount')

      callback();
    }
  }
  private batchCountRule(rule, value, callback){
    if (value === '') {
      callback();
    } else {
      const vc = value.split(",")
      let sum = 0;
      vc.forEach(e => {
        sum += Number(e)
      });
      if(this.data.newQty){
        if(sum > this.data.newQty){
          callback(new Error('批次总数量大于销售单的数量'));
        }
      }
      callback();
    }
  }

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {drugCode:"",newQty:0,price: 0,batchNum:"",prodDate:"",validity:"",batchCount:0}
  }
  public open(param: any) {
    this.show = true
    this.data.id = param.id
    this.data.drugCode = param.drugCode
    this.data.batchNum = param.batchNum
    this.data.validity = param.validity
    this.data.prodDate = param.prodDate
    this.data.batchCount = param.batchCount
    this.data.drugName = param.drugName
    this.data.newQty = param.newQty
    this.data.price = param.price
    this.data.newAmount = param.newAmount
    
  }

  public async save() {
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      await UpdateOrerDetailsBatchInfo(this.data)
      Message.success('保存成功')
      this.close()
    })
  }
}
