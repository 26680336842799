







































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import constan from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import router from '@/router'
import { SubmitAuditDrug } from '@/api'
import { Message } from 'element-ui'
import { getRole } from '@/store/userStore'
import H5Image from '@/components/h5/image/H5Image.vue'

@Component({
  components: {
   H5Image
  },
})
export default class DrugItem extends Vue {
  @Prop(Object) private data: any
  private StatusFormat = StatusFormat
  private constan = constan

  private get role() {
    return getRole();
  }

  private openBatch(){
    router.push("/h5/manageDrugBatch?drugCode="+this.data.drugCode+"&name="+this.data.drugName)
  }
  private openPriceAudit(){
    router.push("/h5/suppliyPriceAudit?drugCode="+this.data.drugCode+"&name="+this.data.drugName+"&suppliyPrice="+this.data.suppliyPrice+"&id="+this.data.id)
  }

  public async submitAudit(){
    await SubmitAuditDrug({ids:[this.data.id]})
    Message.success('提交成功')
    this.refresh()
  }

  @Emit('refresh')
  private refresh(){
    return
  }
}
