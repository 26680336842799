









import { Component, Vue } from 'vue-property-decorator'
import store from '@/store'
import router from '@/router';
import {saveWxMpCode} from '@/store/wxMpCode'

@Component({
  components: {
  },
})
export default class H5Layout extends Vue {
  private title = ""

  private created() {
    console.log("首位")
    const t = router.currentRoute?.meta?.title
    this.title = t || "";
    window.document.title =this.title

    // 获取微信token
    let code = router.currentRoute.params.code
    if(!code) {
      code = router.currentRoute.query.code as string
    }
    if(code){
      // 保存到缓存
      saveWxMpCode(code)
    }
    const pageTitle = router.currentRoute.query.pageTitle
    if(pageTitle){
      this.title =  pageTitle as string
      window.document.title = this.title
    }
    console.log(this.title)
  }

  private back() {
    router.back()
  }

}
