




























































import { Component, Vue, Emit } from 'vue-property-decorator'
import { AddDrug,UpdateDrug,SupplierListForSelect, QuerySystemConfig, QuerySystemConfigByKey, SubmitAuditDrug, QueryPartnerShopForSelect} from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import constant from '@/utils/constant'
import { timeFormate } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'
import { VImageUpload } from '@/components/upload'

@Component({
  components: {
    VImageUpload
  },
})
export default class CreatePlatformDialog extends Vue {
  private constant = constant
  private show = false
  private data: any = {id: 0,partnerShopIdsSelect: [],price: 0,drugCode:"",stock:0,step:1}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    supplierId: [this.requiredRule],
    drugCode: [this.requiredRule],
    drugName: [this.requiredRule],
    pack: [this.requiredRule],
    factory: [this.requiredRule],
    unit: [this.requiredRule],
    approval: [this.requiredRule],
    stock: [this.requiredRule],
    price: [this.requiredRule],
    suppliyPrice: [this.requiredRule],
    busiType: [this.requiredRule],
    // partnerShopIds: [this.requiredRule],
  }
  private supplierSelect = []
  private shopSelect = []
  private get role() {
    return getRole();
  }
  private salesPriceRatio = 1;

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {id:0,partnerShopIdsSelect: [],price: 0,drugCode:"",stock:0,step:1}
  }
  public created() {
    if(this.role == 1){
      this.shopRmote("") 
    }
  }
  public async open(param: any) {
    this.show = true
    this.loadSystemConfig()
    if(param){
      await this.supplierRmote("",param.supplierId)
      this.data = {...param,partnerShopIdsSelect:[]}
      if(param.partnerShopIds){
        const c = param.partnerShopIds.split(',')
        for(let i = 0;i < c.length; i++){
          this.data.partnerShopIdsSelect.push(Number(c[i]))
        }
      }
      
    } else {
      this.supplierRmote("")
      const d = new Date()
      this.data.drugCode = "D"+timeFormate(new Date(),"YYMMDDHHmmss")
    }
  }

  private async loadSystemConfig(){
    const res = await QuerySystemConfigByKey({configKey:'salesPriceRatio'})
    this.salesPriceRatio = Number(res.configValue)
  }


  private async supplierRmote(query: any,id?: number) {
    if(this.role != 1 && this.role != 4){
      return
    }
    const list = await SupplierListForSelect({name:query,id:id})
    list.list.forEach(e=>{
      e.value = Number(e.value)
    })
    this.supplierSelect = list.list
  }

  private async changeSupplier(e: any){
    const list = await SupplierListForSelect({id:e})
    this.data.supplierName = list.list[0].label
  }
  public async save(){
    await this.save2()
    this.close()
  }
  public async save2() {
    return new Promise((result) => {
      const f = this.$refs.form as ElForm
      f.validate(async (success: boolean) => {
        if (!success) {
          return
        }
        if(!this.data.id || this.data.id == 0 ){
          const id = await AddDrug(this.data)
          this.data.id = id;
        } else {
          await UpdateDrug(this.data)
        }
        Message.success('保存成功')
        result(true)
      })
    })
  }

  private suppliyPriceBlur(){
    this.data.price = this.data.suppliyPrice * this.salesPriceRatio
  }

  public async submitAudit(){
    await this.save2()
    await SubmitAuditDrug({ids:[this.data.id]})
    Message.success('提交成功')
    this.close()
  }
  private changeShop(e: any){
    this.data.partnerShopIds = e.join(',')
    this.data.partnerShopIdsSelect = e
  }
  private async shopRmote(query: any,id?: number) {
    const list = await QueryPartnerShopForSelect({name:query,id:id})
    this.shopSelect = list.list
  }
  private formShop(value: string){
    const vs = value.split(',')
    const result: Array<string> = []
    vs.forEach(v => {
      this.shopSelect.forEach((s:any)=>{
        if(s.value == v){
          result.push(s.label) 
        }
      })
    });
    return result.join(',')
  }
}
