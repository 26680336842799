











import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { QueryPlatformAccountList,ChangePlatformAccountStatus, SupplierListForSelect, DeletePlatformAccountStatus } from '@/api'
import { DateFormat, DateFormat2 } from '@/utils/date/DateUtil'
import { PlatformStatusFormat,StatusFormat } from '@/utils/tyepconvert'
import constant from '@/utils/constant'
import RestPwdDialog from './RestPwdDialog.vue'
import CreatePlatformDialog from './CreatePlatformDialog.vue'
import { MessageBox } from 'element-ui'

@Component({
  components: {
    VQuery,
    VTable,
    RestPwdDialog,
    CreatePlatformDialog,
  },
})
export default class PlatformManager extends Vue {
  private queryParam = {
    limit: 10,
    // role: 1,
  }
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private supplierSelect= []

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '名称', param: 'name' },
    { title: '账号', param: 'account' },
    { title: '供应商', type:"select", param: 'supplierId', selectData: this.supplierSelect,remoteMethod: this.supplierRmote },
    { title: '角色', param: 'role', type:'select',selectData: constant.PlatformRole },
    { title: '状态', param: 'status', type:'select',selectData: constant.PlatformStatus },
  ]
  // 全局操作
  private queryOperation: Array<TableOperation> = [
    { title: '创建账号', type: 'primary', click: () => this.openDialog('CreatePlatformDialog') },
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '序号', param: 'id', type: 'index' },
    { title: '名称', param: 'name' },
    { title: '账号', param: 'account' },
    { title: '角色', param: 'role' , formatter: (value)=>StatusFormat(constant.Role,value)},
    // { title: '所属供应商', param: 'supplierName' },
    { title: '状态', param: 'status', formatter: PlatformStatusFormat },
    { title: '创建时间', param: 'createTime', formatter: DateFormat },
    {
      title: '操作',
      width: 250,
      operation: [
        {
          title: '禁用',
          show: (data)=>data.status == 1,
          click: (data) => this.changeStats(data,0),
        },
        {
          title: '启用',
          show: (data)=>data.status != 1,
          click: (data) => this.changeStats(data,1),
        },
        {
          title: '编辑',
          click: (data) => this.openDialog('CreatePlatformDialog',data),
        },
        {
          title: '重置密码',
          icon: 'el-icon-edit',
          click: (data) => this.openDialog('RestPwdDialog', data),
        },
        {
          title: '删除',
          icon: 'el-icon-delete',
          click: this.deleteAccount,
        },
      ],
    },
  ]

  public async created() {
    this.loadData()
    this.supplierRmote("")
  }

  public async loadData() {
    const param:any = { ...this.queryParam }
    if(!param.role){
      param.roleIn = [1,3,4,5]
    }
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QueryPlatformAccountList(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  private async supplierRmote(query: any,id?: number) {
    const list = await SupplierListForSelect({name:query,id:id})
    this.supplierSelect = list.list
    this.queryModels[2].selectData = this.supplierSelect
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private async changeStats(data: any,value: number){
    console.log(data)
    const sn = data.status == 1 ? '禁用':"启用"
    MessageBox.confirm('确认将账号（'+data.account+'）状态变为'+sn+'吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      await ChangePlatformAccountStatus({
          id: data.id,
          status: value
        })
      this.loadData()
    })
    
  }

  private deleteAccount(data: any){
    MessageBox.confirm('确认将账号（'+data.account+'）删除吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      await DeletePlatformAccountStatus({
          id: data.id
        })
      this.loadData()
    })
  }
}
