export default {
    ENV: 'dev',
    API_HOST: 'http://localhost:9003',
    // API_HOST: 'https://www.kangennuo.com/api',
    // API_HOST: 'http://192.168.2.72:9003',
    // API_HOST: 'https://api.t9sports.cn',
    HOST: '',
    APP_ID: '',
    WX_APP_ID: 'wxd0ba71665d141047'
}
