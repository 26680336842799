import format from '@/plugins/format'
import dayjs from "dayjs";

export const  timeFormate = (time: Date, format?: string) =>{
  const d = new Date(Number(time));

  return dayjs(d).format(format || "YYYY-MM-DD");
}

export const DateFormat = (data: any) => {
  if(data == null){
    return '-'
  }
  try {
    return format.dateFormat(data,  "yyyy-MM-dd hh:mm:ss"); 
  } catch (e) {
    return data
  }
}
export const DateFormat2 = (data: any) => {
  if(data == null){
    return '-'
  }
  try {
    return format.dateFormat(data, 'yyyy-MM-dd')
  } catch (e) {
    return data
  }
}
export const DateFormat3 = (data: any) => {
  if(data == null){
    return '-'
  }
  try {
    return format.dateFormat(data, 'yyyyMMdd')
  } catch (e) {
    return data
  }
}
export const DateFormat4 = (data: any) => {
  if(data == null){
    return '-'
  }
  try {
    return format.dateFormat(data, 'yyyy-MM-dd.hh')
  } catch (e) {
    return data
  }
}
export const DateFormat5 = (data: any) => {
  if(data == null){
    return '-'
  }
  try {
    return format.dateFormat(data, 'MM.dd')
  } catch (e) {
    return data
  }
}
export const startDate = (data: Date) =>{
  data.setHours(0)
  data.setMinutes(0)
  data.setSeconds(0)
  return data
}
export const endDate = (data: Date) =>{
  data.setHours(23)
  data.setMinutes(59)
  data.setSeconds(59)
  return data
}
export const addDay = (data: Date, day: number) =>{
  data.setDate(data.getDate() + day)
  return data
}

