




















import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {  UploadShipper as UploadShipperApi} from '@/api'
import constant from '@/utils/constant'
import { getRole } from '@/store/userStore'
import { StatusFormat } from '@/utils/tyepconvert'
import copy from '@/utils/copy'
import { ElForm } from 'element-ui/types/form'
import { VImageUpload } from '@/components/upload'
import { Message } from 'element-ui'

@Component({
  components: {
    VImageUpload
  },
})
export default class UploadShipper extends Vue {
  // 是否是审核
  @Prop({type: Boolean,default: false}) private audit!: boolean
  private constant = constant
  private show = false
  private data: any = {deliverFee: 0}
  
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    shipperName: [this.requiredRule],
    logisticsNo: [this.requiredRule],
  }
  
  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {}
    
  }
  public open(param: any) {
    this.show = true
    this.data = param
    // key
    
  }

  
  private async save(){
    // 整理保存的数据
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      const param = {
        id: this.data.id,
        shipperName: this.data.shipperName,
        logisticsNo: this.data.logisticsNo,
      }
      await UploadShipperApi(param)
      Message.success('上传成功')
      this.close()
    })
  }
}
