










import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { SupplierList,ExportSupplier,DeleteSupplierById } from '@/api'
import { DateFormat, DateFormat2 } from '@/utils/date/DateUtil'
import EditSupplierDialog from  './EditSupplierDialog.vue'
import PlatformManagerDialog from '@/views/platform/PlatformManagerDialog.vue'
import { MessageBox } from 'element-ui'

@Component({
  components: {
    VQuery,
    VTable,
    EditSupplierDialog,
    PlatformManagerDialog
  },
})
export default class PlatformManager extends Vue {
  private queryParam = {limit: 10}
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '供应商', param: 'name' },
    // { title: '状态', param: 'status', type:'select',selectData: constant.PlatformStatus },
  ]
  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    { title: '新增供应商', type: 'primary', click: () => this.openDialog('EditSupplierDialog') },
    { title: '导出供应商', type: 'primary', click: () => {
      ExportSupplier(this.queryParam)
    } },
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: 'ID', param: 'id'},
    { title: '供应商', param: 'name' },
    { title: '联系人', param: 'contact' },
    { title: '联系方式', param: 'contactUs' },
    { title: '地址', param: 'addres' },
    // { title: '状态', param: 'status', formatter: PlatformStatusFormat },
    { title: '创建时间', param: 'createTime', formatter: DateFormat },
    {
      title: '操作',
      operation: [
        {
          title: '编辑',
          click: (data) => this.openDialog('EditSupplierDialog',data),
        },
        {
          title: '管理账号',
          click: (data) => this.openDialog('PlatformManagerDialog',data),
        },
        {
          title: '删除',
          click: this.delte,
        },
        
      ],
    },
  ]

  public async created() {
    this.loadData()
  }

  public async loadData() {
    const param: any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await SupplierList(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private delte(data: any){
    MessageBox.confirm('确认将供应商（'+data.name+'）删除吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      await DeleteSupplierById({
          id: data.id
        })
      this.loadData()
    })
  }

  
}
