




































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {  AuditDrug, QueryDrugBatch, PutOrOutStorage, QueryDrugBatchLog} from '@/api'
import constant from '@/utils/constant'
import { DateFormat, DateFormat2, timeFormate } from '@/utils/date/DateUtil'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { getRole } from '@/store/userStore'
import { VImageUpload } from '@/components/upload'

@Component({
  components: {
    VTable,
    VQuery,
    VImageUpload,
  },
})
export default class DrugStoreDetails extends Vue {
  // 是否是审核
  @Prop({type: Boolean,default: false}) private audit!: boolean
  private constant = constant
  private show = false
  private data: any = {}
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private queryParam = {
    stockGt:0
  }
  private get role() {
    return getRole();
  }

  private drugBarchLogData: Array<any> = []

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '批次状态', type:"select", span:5, param: 'stockGt', selectData: constant.BatchType},
  ]

  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '收件人', width: 100,param: 'batchNum' },
    { title: '收件人电话', width: 100, param: 'batchNum1'},
    { title: '配送地址', param: 'batchNum2' ,overflow:true},
    { title: '首次使用时间', param: 'createTime' , formatter: DateFormat},
    
  ]

  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
    
  }

  public async loadData() {
    const param: any = { ...this.queryParam,drugCode:this.data.drugCode}
    param.startRow = (this.tableCurrntPage - 1) * 10
    const result = await QueryDrugBatch(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  @Emit('close')
  private close() {
    this.show = false
    this.data = {}
  }
  public open(param: any) {
    this.show = true
    this.data = param
    this.loadData()
   
  }

}
