





















import { Component, Vue, Prop } from 'vue-property-decorator'
import constan from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import router from '@/router'

@Component({
  components: {
   
  },
})
export default class DrugBatchItem extends Vue {
  @Prop(Object) private data: any
  private StatusFormat = StatusFormat
  private constan = constan

  private openBatch(){
    router.push("/h5/manageDrugBatch?drugCode="+this.data.drugCode)
  }

  private updateBatch(){
    router.push("/h5/updateDrugBatch?id="+this.data.id)
  }
  private openPut(){
    router.push("/h5/putDrugBatch?id="+this.data.id)
  }
  private openOut(){
    router.push("/h5/outDrugBatch?id="+this.data.id)
  }
}
