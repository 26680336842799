









































import { Component, Vue, Emit } from 'vue-property-decorator'
import { AddDrug,UpdateDrug,SupplierListForSelect, SubmitAuditDrug, QueryDrugById, QuerySystemConfigByKey} from '@/api'
import {  Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import { Form, Dialog } from 'vant';
import constant from '@/utils/constant'
import { timeFormate } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'
import router from '@/router';
import {VVantImageUpload} from '@/components/upload'

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
    VVantImageUpload
  },
})
export default class CreatePlatformDialog extends Vue {
  private constant = constant
  private show = false
  private data: any = {stock:0,step:1}
  private requiredRule = { required: true, message: '不能为空', trigger: 'onBlur' }
  private rules = {
    supplierId: [this.requiredRule],
    drugCode: [this.requiredRule],
    drugName: [this.requiredRule],
    pack: [this.requiredRule],
    factory: [this.requiredRule],
    unit: [this.requiredRule],
    approval: [this.requiredRule],
    stock: [this.requiredRule],
    price: [this.requiredRule],
    busiType: [this.requiredRule],
  }
  private showBusiTypePicker = false
  private showSupplierPicker = false
  private supplierSelect = []
  private get role() {
    return getRole();
  }
  private salesPriceRatio = 1;

  public async created() {
    this.loadSystemConfig()
    const id = router.currentRoute.query.id
    if(id){
      const d = await QueryDrugById({id})
      this.data = d;
    } else {
      // 初始化
      this.data = {stock:0,step:1}
      this.data.drugCode = "D"+timeFormate(new Date(),"YYMMDDHHmmss")
      if(this.role == 1){
        this.supplierRmote("")
      }
    }
  }
  private async loadSystemConfig(){
    const res = await QuerySystemConfigByKey({configKey:'salesPriceRatio'})
    this.salesPriceRatio = Number(res.configValue)
  }
  
  public beforeDestroy() {
    console.log('离开页面前')
    window.onbeforeunload = null
  }
  public destroyed() {
    console.log('离开页面了')

    window.onbeforeunload = null
  }

  private hintMessage = "页面中有正在编辑的内容，继续将不会保存"
  private showLeaveHint = false

  public mounted() {
    // window.onbeforeunload = (e:any)=>{
    //     e.returnValue = this.hintMessage;
    //     return this.hintMessage;
    // }
  }

  // * ---- 生命周期 ---- */
  // 改变url时，给出提示
  public beforeRouteLeave(to:any, from:any, next: any) {
    if(this.showLeaveHint){
      this.showLeaveHintFun(next);
    } else {
      next()
    }
    
  }

  public async showLeaveHintFun(next:any) {
    const a =await Dialog.confirm({
      title: "提醒",
      message: this.hintMessage
    })
    // const a = window.confirm(this.hintMessage)
    if (a) {
        next();
    } else {
        next(false);
    }
  }

  public async open(param: any) {
    this.show = true
    if(param){
      await this.supplierRmote("",param.supplierId)
      this.data = param
    } else {
      this.supplierRmote("")
      const d = new Date()
      this.data.drugCode = "D"+timeFormate(new Date(),"MMDDHHmmss")
    }
  }

  private async supplierRmote(query: any,id?: number) {
    if(this.role != 1){
      return
    }
    const list = await SupplierListForSelect({name:query,id:id})
    this.supplierSelect = list.list
  }

  private async changeSupplier(e: any){
    const list = await SupplierListForSelect({id:e})
    this.data.supplierName = list.list[0].label
  }

  private busiTypeSelect(value: any){
    this.data.busiType = value.label
    this.showBusiTypePicker = false
  }
  private supplierSelectFunc(value: any){
    this.data.supplierId = value.value
    this.data.supplierName = value.label
    this.showSupplierPicker = false
  }

  public async save() {
    await this.save2()
    this.showLeaveHint = false
    Message.success('保存成功')
  }

  public async save2(){
    const f = this.$refs.form as Form
    await f.validate()
    
    
    if(!this.data.id ){
      const id = await AddDrug(this.data)
      this.data.id = id;
    } else {
      await UpdateDrug(this.data)
    }
  }

  public async submitAudit(){
    if(!this.data.id){  
      await this.save2()
    }
    await SubmitAuditDrug({ids:[this.data.id]})
    this.showLeaveHint = false
    Message.success('提交成功')
    // 跳转页面
    router.push("/h5/drug")
  }

  private suppliyPriceBlur(){
    console.log('suppliyPriceBlur')
    this.data.price = this.data.suppliyPrice * this.salesPriceRatio
    console.log(this.data.price)
  }
}
