enum API {
  // 后台用户相关
  loginByEmail = '/manage/platform/loginByEmail', // 平台账号登录
  loginByAccount = '/manage/platform/loginByAccount', // 平台账号登录(通过账号)
  updatePlatformAccountPassword = '/manage/platform/updatePlatformAccountPassword', // 修改密码（平台登录账号）
  queryPlatformAccountList = '/manage/platform/queryPlatformAccountList', // 查询平台账号列表
  createPlatformAccount = '/manage/platform/createPlatformAccount', // 创建平台登录账号
  updatePlatformAccount = '/manage/platform/updatePlatformAccount', // 修改平台登录账号
  resetPasswordsForPlatformAccount = '/manage/platform/resetPasswordsForPlatformAccount', // 重置密码（平台端）
  changePlatformAccountStatus = '/manage/platform/changePlatformAccountStatus', // 变更账号状态
  loginByWxCode = '/manage/platform/loginByWxCode', // 平台账号登录（wxcode）
  loginOutByWxCode = '/manage/platform/loginOutByWxCode', // 退出登录（wxcode）
  deletePlatformAccountStatus = '/manage/platform/deletePlatformAccountStatus', // 删除平台账号

  fileUpload = '/fileupload/fileUpload', // 文件上传
  
  // banneer
  queryBannerList = '/manage/banner/queryBannerList', // 查询bannerlist
  addBanner = '/manage/banner/addBanner', // 添加banner
  changeBannersStatus = '/manage/banner/changeBannersStatus', // 修改顺序
  updateBanner = '/manage/banner/updateBanner', // 修改顺序
  deleteBanner = '/manage/banner/deleteBanner', // 修改顺序
  
  // 宣传页
  queryBrochureList = '/manage/brochure/queryBrochureList', // 查询宣传页
  queryBrochureById = '/manage/brochure/queryBrochureById', // 通过id查询宣传页
  createBrochure = '/manage/brochure/createBrochure', // 创建宣传页
  updateBrochure = '/manage/brochure/updateBrochure', // 更新宣传页
  deleteBrochure = '/manage/brochure/deleteBrochure', // 删除宣传页

  // 系统日志
  queryLog = '/manage/log/queryLog', // 查询日志

  // 供应商
  supplierList = '/manage/supplier/supplierList', // 供应商列表
  supplierListForSelect = '/manage/supplier/supplierListForSelect', // 供应商列表
  createSupplier = '/manage/supplier/createSupplier', // 创建供应商
  updateSupplier = '/manage/supplier/updateSupplier', // 修改供应商
  deleteSupplierById = '/manage/supplier/deleteSupplierById', // 删除供应商

  // 药品
  drugStatistics = '/manage/drug/drugStatistics', // 药品统计
  queryDrug = '/manage/drug/queryDrug', // 查询药品
  queryDrugStock = '/manage/drug/queryDrugStock', // 查询药品库存
  queryDrugById = '/manage/drug/queryDrugById', // 查询药品
  queryDrugByCode = '/manage/drug/queryDrugByCode', // 查询药品
  addDrug = '/manage/drug/addDrug', // 添加药品
  updateDrug = '/manage/drug/updateDrug', // 更新药品
  submitAuditDrug = '/manage/drug/submitAuditDrug', // 提交审核
  auditDrug = '/manage/drug/auditDrug', // 审核药品
  queryDrugBatch = '/manage/drugBatch/queryDrugBatch', // 查询药品批次
  queryDrugBatchById = '/manage/drugBatch/queryDrugBatchById', // 查询药品批次
  queryDrugBatchLog = '/manage/drugBatch/queryDrugBatchLog', // 查询药品库存变更记录
  addDrugBatch = '/manage/drugBatch/addDrugBatch', // 新增药品批次
  updateDrugBatch = '/manage/drugBatch/updateDrugBatch', // 编辑药品批次信息
  putOrOutStorage = '/manage/drugBatch/putOrOutStorage', // 入/出库
  importDrug = '/manage/drug/importDrug', // 导入药品
  delistDrug = '/manage/drug/delistDrug', // 下架药品
  listingDrug = '/manage/drug/listingDrug', // 上架药品
  batchUpdateOrSaveDrug = '/manage/drug/batchUpdateOrSaveDrug', // 批量更新药品
  submitSuppliyPriceAudit = '/manage/drug/submitSuppliyPriceAudit', // 提交供货价审核
  auditSuppliyPrice = '/manage/drug/auditSuppliyPrice', // 审核供货价
  querySuppliyPriceAudit = '/manage/drug/querySuppliyPriceAudit', // 获取供货价审核列表

  // 药店
  queryDrugStore = '/manage/drugStore/queryDrugStore', // 查询药店
  addDrugStore = '/manage/drugStore/addDrugStore', // 添加药店
  updateDrugStore = '/manage/drugStore/updateDrugStore', // 更新药店
  
  // 订单
  orderStatistics = '/manage/order/orderStatistics', // 查询订单
  queryOrder = '/manage/order/queryOrder', // 查询订单
  queryOrderById = '/manage/order/queryOrderById', // 查询订单
  queryOrderDetails = '/manage/order/queryOrderDetails', // 查询订单明细
  queryOrderDetailsById = '/manage/order/queryOrderDetailsById', // 查询订单明细
  uploadBatchInfo = '/manage/order/uploadBatchInfo', // 上传订单药品批次
  uploadInvoice = '/manage/order/uploadInvoice', // 上传发票
  uploadShipper = '/manage/order/uploadShipper', // 上传快递信息
  batchSorting = '/manage/order/batchSorting', // 分拣中（状态变更）
  makeOrder = '/manage/order/makeOrder', // 开单完成
  confirmOut = '/manage/order/confirmOut', // 开单完成
  deliveryDoing = '/manage/order/deliveryDoing', // 配送中
  deliverySuccess = '/manage/order/deliverySuccess', // 配送完成
  finish = '/manage/order/finish', // 订单完成
  updateOrerDetailsBatchInfo = '/manage/order/updateOrerDetailsBatchInfo', // 订单完成
  createSalesOrder = '/manage/order/createSalesOrder', // 创建销售单

  // 导出
  exportSupplier = '/manage/export/exportSupplier',// 导出-供应商
  exportDrugstore = '/manage/export/exportDrugstore',// 导出-药店
  exportDrug = '/manage/export/exportDrug',// 导出-产品
  exportSalesOrder = '/manage/export/exportSalesOrder',// 导出-销售单
  exportSupplierSalesOrder = '/manage/export/exportSupplierSalesOrder',// 导出-供应商销售单
  exportOrder = '/manage/export/exportOrder',// 导出-订单
  exportSupplierOrder = '/manage/export/exportSupplierOrder',// 导出-供应商订单
  exportOrderForDistribution = '/manage/export/exportOrderForDistribution',// 导出-清单
  exportAllOrderList = '/manage/export/exportAllOrderList',// 导出-清单
  exportOrderShipperInfo = '/manage/export/exportOrderShipperInfo',// 导出-快递信息

  // 微信
  // 供应商订单
  queryOrderSupplierInfo = '/manage/supplierOrder/queryOrderSupplierInfo',// 查询有订单的供应商
  querySupplierOrder = '/manage/supplierOrder/querySupplierOrder',// 查询供应商订单
  querySupplierOrderForOperator = '/manage/supplierOrder/querySupplierOrderForOperator',// 查询供应商订单-操作员
  querySupplierOrderDetails = '/manage/supplierOrder/querySupplierOrderDetails',// 查询供应商订单明细
  querySalesOrder = '/manage/supplierOrder/querySalesOrder',// 查询销售单
  querySalesOrder2 = '/manage/supplierOrder/querySalesOrder2',// 查询销售单
  supplierConfirmOut = '/manage/supplierOrder/confirmOut',// 供应商确认出库
  confirmSorting = '/manage/supplierOrder/confirmSorting', // 分拣中

  // 数据统计
  querySupplierIndexData = '/manage/dataStatistics/querySupplierIndexData',// 供应商首页数据统计
  queryIndexData = '/manage/dataStatistics/queryIndexData',// 首页数据统计
  
  // 微信相关
  queryWxAccount = '/manage/wxaccount/queryWxAccount',// 首页数据统计
  changeWxAccountStatus = '/manage/wxaccount/changeWxAccountStatus',// 首页数据统计
  queryMsgTemplate = '/manage/wxaccount/queryMsgTemplate',// 首页数据统计
  changeTemplateMsgStatus = '/manage/wxaccount/changeTemplateMsgStatus',// 首页数据统计
  sendTestMsg = '/manage/wxaccount/sendTestMsg',// 首页数据统计
  

  // 系统配置
  querySystemConfig = '/manage/systemconfig/querySystemConfig',// 首页数据统计
  querySystemConfigByKey = '/manage/systemconfig/querySystemConfigByKey',// 首页数据统计
  updateSystemConfigValue = '/manage/systemconfig/updateSystemConfigValue',// 首页数据统计
  
  // 合作店铺 
  queryPartnerShop = '/manage/partnerShop/queryPartnerShop',// 查询合作方店铺列表
  queryPartnerShopForSelect = '/manage/partnerShop/queryPartnerShopForSelect',// 查询店铺-为下拉框
  addPartnerShop = '/manage/partnerShop/addPartnerShop',// 新增店铺
  updatePartnerShop = '/manage/partnerShop/updatePartnerShop',// 更新店铺
  changePartnerShopStatus = '/manage/partnerShop/changePartnerShopStatus',// 变更店铺状态
  syncShop = '/manage/partnerShop/syncShop',// 同步店铺
  syncProductToShop = '/manage/partnerShop/syncProductToShop',// 同步商品到店铺（erp到目标店铺）（手动同步全量，只有部分类型的店铺需要）

}
export default API; 
