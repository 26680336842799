
















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import constan from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import router from '@/router'
import { SupplierConfirmOut } from '@/api'

@Component({
  components: {
  },
})
export default class DrugItem extends Vue {
  @Prop(Object) private data: any
  private StatusFormat = StatusFormat
  private constan = constan
  private isAll = false

  private get totalCost(){
    if(!this.data.orderDetails || this.data.orderDetails.length == 0){
      return 0;
    }
    let result = 0
    this.data.orderDetails.forEach(e=>{
        result += e.suppliyTotalPrice
    })
    return result;
  }

  private disIsAll(){
    if(!this.data.orderDetails || this.data.orderDetails.length == 0){
      this.isAll =  false
    }
    let result = true
    this.data.orderDetails.forEach(e=>{
     if(!e.select){
       result = false
     }
    })
    this.isAll = result
  }

  private itemSelect(v,item){
    console.log('itemSelect')
    console.log(item.drugName)
    item.select = v
    this.disIsAll()
  }
  private itemIsSelect(item){
    // console.log('o')
    return item.select
  }

  private selectAll(){
    this.data.orderDetails.forEach(e => {
      e.select = !this.isAll
    });
    this.disIsAll()
  }

  private async confirmOut(){
    console.log('confirmOut')
    const sl = this.data.orderDetails.filter(e=>e.select)
    if(sl.length == 0){
      return
    }
    console.log(sl)
    const orderDetailsIds = sl.map(e=>e.id);
    await SupplierConfirmOut({orderDetailsIds})
    this.refresh()
  }

  @Emit('refresh')
  private refresh(){
    return
  }

  private openBatch(){
    router.push("/h5/manageDrugBatch?drugCode="+this.data.drugCode+"&name="+this.data.drugName)
  }

  private jump(){
    console.log('dadsss')
    router.push("/h5/order?supplierId="+this.data.supplierId+"&pageTitle=订单-"+this.data.supplierName)
  }
}
