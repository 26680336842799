import { render, staticRenderFns } from "./H5TabBarLayout.vue?vue&type=template&id=472a1076&scoped=true&lang=pug&"
import script from "./H5TabBarLayout.vue?vue&type=script&lang=ts&"
export * from "./H5TabBarLayout.vue?vue&type=script&lang=ts&"
import style0 from "./H5TabBarLayout.vue?vue&type=style&index=0&id=472a1076&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "472a1076",
  null
  
)

export default component.exports