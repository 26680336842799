import router from "@/router"

// 用户缓存
export const saveUser = (userInfo: any) =>{
  // 随机输入1/2
  localStorage.setItem("userInfo",JSON.stringify(userInfo))
}

export const getUser = () => {
  const userInfo = localStorage.getItem("userInfo")
  if(userInfo == null){
    return {user:{}}
  }
  return JSON.parse(userInfo)
}

export const getRole = () => {
  const userInfo: any = localStorage.getItem("userInfo")
  if(userInfo == null){
    router.push('login')
  }
  const u = JSON.parse(userInfo);
  return u.user.role
}
