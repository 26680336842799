
import dev from './config-dev'
import test from './config-test'
import prod from './config-prod'

let Conf: any
console.log('CONF:', process.env.NODE_ENV)
switch (process.env.NODE_ENV) {
    case 'development':
        Conf = dev
        break
    case 'test':
        Conf = test
        break
    case 'production':
        Conf = prod
        break
    default:
        Conf = prod
        break
}
export default Conf
