














import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {  QueryMsgTemplate,MakeOrder, ConfirmOut, ChangeTemplateMsgStatus, SendTestMsg} from '@/api'
import constant from '@/utils/constant'
import { DateFormat, DateFormat2, timeFormate } from '@/utils/date/DateUtil'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { getRole,getUser } from '@/store/userStore'
import { VImageUpload } from '@/components/upload'
import { StatusFormat } from '@/utils/tyepconvert'
import copy from '@/utils/copy'
import { Message, MessageBox } from 'element-ui'
import {digitUppercase} from '@/utils/NumberToChinese'

@Component({
  components: {
    VTable,
    VQuery,
  },
})
export default class OrderDetails extends Vue {
  // 是否是审核
  @Prop({type: Boolean,default: false}) private audit!: boolean
  private constant = constant
  private show = false
  private data: any = {}
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private selectTab = 'info'
  private queryParam = {
  }
  private get role() {
    return getRole();
  }
  private get user(){
    return getUser().user
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
  ]

  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '模版Id', width: 200,param: 'templateId' },
    { title: '模版名称', width: 120,param: 'msgDesc' },
    { title: '模版内容',param: 'msgCentent' },
    { title: '状态', width: 100,param: 'powerStatus', formatter: (value)=> value == 0?'启用':'禁用' },
    {
      title: '操作',
      // fixed: 'right',
      width: 120,
      operation: [
        {
          title: '禁用',
          click: this.msgdesable,
          show:(data)=>data.powerStatus == 0
        },
        {
          title: '启用',
          click: this.msgundesable,
          show:(data)=>data.powerStatus == 1
        },
        {
          title: '测试消息',
          click: this.sendTestMsg,
          show:(data)=>this.role == 1
        },
      ],
    },
  ]

  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
    
  }

  public async loadData() {
    const param: any = { ...this.queryParam,openId: this.data.openId}
    param.startRow = (this.tableCurrntPage - 1) * 10
    const result = await QueryMsgTemplate(param)
    this.tableTotal = result.length
    this.tableData = result
  }

  @Emit('close')
  private close() {
    this.show = false
    this.data = {}
  }
  @Emit('refreshData')
  private refreshData() {
    // this.show = false
    // this.data = {} 
  }
  
  public open(param: any) {
    this.show = true
    this.data = {...param}
    this.loadData()
  }

  private showBatch(){
    return this.data.status == 1
  }

  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private async msgdesable(data){
    // 禁用
    MessageBox.confirm('确定要禁用吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      const ruslt = await ChangeTemplateMsgStatus({openId:data.openId,templateId:data.templateId,status: 1})
      if (ruslt) {
        Message.success('禁用成功')
        this.loadData()
      }
    })
    
  }
  private async msgundesable(data){
    // 启用
    MessageBox.confirm('确定要启用吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      const ruslt = await ChangeTemplateMsgStatus({openId:data.openId,templateId:data.templateId,status: 1})
      if (ruslt) {
        Message.success('启用成功')
        this.loadData()
      }
    })
  }

  private async sendTestMsg(data){
    // 
    const res = await SendTestMsg({openId:data.openId,templateId:data.templateId})
    if(res){
      Message.success('发送成功')
    } else {
      Message.error('发送失败')
    }
  }
}
