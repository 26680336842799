






import { Component, Vue } from 'vue-property-decorator'
import { LoginOutByWxCode } from '@/api'
import { getWxMpCode, removeWxMpCode } from '@/store/wxMpCode'
import router from '@/router'
import Conf from '@/config/config'

@Component({
  components: {
  },
})
export default class Order extends Vue {
  
  private async  loginOut(){
    // const code = getWxMpCode()
    // if(code){
      // removeWxMpCode();
      // const param: any = {}
      // param.wxMpCode = code;
      // param.wxAppid = Conf.WX_APP_ID;
      // await LoginOutByWxCode(param)
    // }
    localStorage.removeItem('userInfo')
    router.push('/login')
  }
  
}
