








































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import constan from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import router from '@/router'
import { getRole } from '@/store/userStore'
import H5Image from '@/components/h5/image/H5Image.vue'

@Component({
  components: {
   H5Image
  },
})
export default class OrderDetailsItem extends Vue {
  @Prop(Object) private data: any
  @Prop(Boolean) private isSelect?: boolean
  private StatusFormat = StatusFormat
  private constan = constan
  private get role() {
    return getRole();
  }
  // private isSelect = false

  // private get isSelect(){
  //   return this.data.select
  // }

  private get batchList(){
    const {batchNum,validity,batchCount} = this.data
    if(!batchNum || !validity || !batchCount){
      return [{
        batchNum,
        validity,
        batchCount,
      }]
    }
    const bns = batchNum.split(',')
    const vs = validity.split(',')
    const bcs = batchCount.split(',')
    const result:any = []
    for(let i = 0;i < bns.length;i++){
      result.push({
        batchNum: bns[i],
        validity: vs[i],
        batchCount: bcs[i],
      })
    }
    return result 
  }

  
  @Emit('select')
  private select(){
    this.isSelect = !this.isSelect
    return this.isSelect
  }

  private openBatch(){
    router.push("/h5/manageDrugBatch?drugCode="+this.data.drugCode+"&name="+this.data.drugName)
  }
  private statusStyle(status: number){
    console.log(status)
    switch(status){
      case 0:
        console.log(21)
        return {
          color: "#F56C6C"
        }
      case 1:
        return {
          color: "#67C23A"
        }
      default:
        return {}
    }
  }
}
