// 平台操作员角色路由
import { RouteConfig } from 'vue-router'
import ManageLayout from '@/layout/ManageLayout.vue'
import H5TabBarLayout from '@/layout/H5TabBarLayout.vue'
import H5PageLayout from '@/layout/H5PageLayout.vue'

import Login from '@/views/Login.vue'

import H5OrderForOperator from '@/views/h5/order/OrderForOperator.vue'
import H5OperatorSupplierList from '@/views/h5/operator/order/OperatorSupplierList.vue'
import H5Seting from '@/views/h5/seting/index.vue'

const number = 3

/**
 * 路由
 */
const routeConfig: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/h5/operatorSupplierList',
  },
  {
    path: '/h5/operatorSupplierList',
    name: 'h5operatorSupplierList',
    meta: {
      title: '供应商订单信息',
    },
    component: H5OperatorSupplierList,
  },
  {
    // H5的常规页面
    path: '/',
    name: 'H5PageLayout',
    component: H5PageLayout,
    meta: {
      title: '首页',
    },children: [
      {
        path: '/h5/order',
        name: 'H5Ordr',
        meta: {
          title: '订单',
        },
        component: H5OrderForOperator,
      },
      {
        path: '/h5/seting',
        name: 'H5Seting',
        meta: {
          title: '设置',
        },
        component: H5Seting,
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: Login,
  },
]

/**
 * 菜单
 */
const menu = [
  
]

const r = {
  routeConfig,
  menu
}
export default r