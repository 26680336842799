





































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { TableOperation } from './Table.vue'

// 描述支持哪些组件
export type QueryType = 'input' | 'date' | 'select' | 'daterange'

export interface SelectData {
  label: string // 标签
  value: any // 选择后返回的值
}

export interface QueryModel {
  title: string
  param: string
  type?: QueryType
  span?: number // 占位，占多少长度
  selectData?: Array<any> // select 时需要
  remoteMethod?(query: string): void
  multiple?: boolean
  show?(): boolean // 是否显示
}

@Component({
  components: {},
})
export default class Query extends Vue {
  @Prop(Object) private params!: any // 参数
  @Prop(Array) private modules!: Array<QueryModel> // 组件
  @Prop(Array) private operation!: Array<TableOperation>
  @Prop({type: String,default: "mini"}) private size!: 'medium'|'small'|'mini'
  private tempParam: any = {} // 换成的参数，用于重置请求参数
  @Emit('search')
  private search() {
    return
  }

  @Emit('reset')
  private reset() {
    const keys = Object.keys(this.params)
    keys.forEach((key) => {
      this.params[key] = this.tempParam[key]
    })
  }

  private created() {
    this.tempParam = { ...this.params }
  }
  private isDisableOption(to: TableOperation, row?: any) {
    if (to.disable) {
      return to.disable(row)
    } else {
      return false
    }
  }

  private isShowOption(to: TableOperation, row?: any) {
    if (to.show) {
      return to.show(row)
    } else {
      return true
    }
  }

  private isShowItem(to: QueryModel) {
    if (to.show) {
      return to.show()
    } else {
      return true
    }
  }
  private isRemote(model: QueryModel){
    
    if(model.remoteMethod){
      return true
    }
    return false
  }
}
