




























import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {  QueryDrugBatch, UploadBatchInfo} from '@/api'
import constant from '@/utils/constant'
import { DateFormat, DateFormat2, timeFormate } from '@/utils/date/DateUtil'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { getRole } from '@/store/userStore'
import { VImageUpload } from '@/components/upload'
import { StatusFormat } from '@/utils/tyepconvert'
import copy from '@/utils/copy'
import AddDrugBatch from  '@/views/drug/AddDrugBatch.vue'

@Component({
  components: {
    VTable,
    VQuery,
    VImageUpload,
    AddDrugBatch
  },
})
export default class SelectBatch extends Vue {
  // 是否是审核
  @Prop({type: Boolean,default: false}) private audit!: boolean
  private constant = constant
  private show = false
  private data: any = {}
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private selectTab = 'info'
  private totalStork = 0
  private queryParam = {
    stockGt:0
  }
  private get role() {
    return getRole();
  }
  private get oerderStatus(){
    return StatusFormat(constant.OrderStatus,this.data.status)
  }
  private get invoiceType(){
    return StatusFormat(constant.InvoiceType,this.data.invoiceType)
  }
  private get isEInvoice(){
    return StatusFormat(constant.IsEInvoice,this.data.isEInvoice)
  }
  private get maxSelect(){
    return this.data.newQty-this.totalSelectNum
  }

  private get totalSelectNum(){
    let n = 0
    this.tableData.forEach((e:any)=>{
      n += e.selectNum
    })
    return n
  }

  private drugBarchLogData: Array<any> = []

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '批次状态', type:"select", span:5, param: 'stockGt', selectData: constant.BatchType},
  ]

  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '批号', param: 'batchNum' },
    { title: '生产日期', width: 100, param: 'prodDate' , formatter: DateFormat2},
    { title: '有效期', width: 100, param: 'validity' , formatter: DateFormat2 },
    // { title: '入库日期', param: 'createTime', formatter: DateFormat },
    // { title: '入库数量', param: 'putStock' },
    { title: '当前库存', param: 'stock' },
    { title: '已选择数量', param: 'selectNum', slotName:'selectNum' },  
    // { title: '自定义', param: 'a1', slotName: 'a1' },  
    { title: '操作',
      fixed: 'right',
      width: 150,
      operation: [
        {
          title: '选择',
          click: this.select
        },
      ],}
    
  ]

  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
    
  }

  public async loadData() {
    const param: any = { ...this.queryParam,drugCode:this.data.drugCode}
    param.startRow = (this.tableCurrntPage - 1) * 10
    param.limit = 100
    const result = await QueryDrugBatch(param)

    // 查看当前数据是否有已经选择的数据-初始化选择数据
    const {batchNum ,batchCount} = this.data
    const bn = batchNum.split(',')
    const bc = batchCount.split(',')

    this.totalStork = 0
    result.list.forEach(e => {
      this.totalStork += e.stock
       e.selectNum = 0
       if(bn.indexOf(e.batchNum) >= 0){
        e.selectNum = Number(bc[bn.indexOf(e.batchNum)])
       }
    });

    this.tableTotal = result.total
    this.tableData = result.list
   
  }

  private select(data: any){
    console.log(data)
  }

  @Emit('close')
  private close() {
    this.show = false
    this.data = {}
    
  }
  public open(param: any) {
    this.show = true
    this.data = {...param}
    // key
    
    this.loadData()
  }

  private showBatch(){
    return this.data.status == 1
  }

  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private openBatch(){
    this.openDialog("AddDrugBatch",{
      supplierId: this.data.supplierId,
      drugCode: this.data.drugCode,
      drugName: this.data.drugName,
    })
  }

  private copyRecv(){
    const t = this.data.recvName+' '
    +this.data.recvPhone+' '
    +this.data.provinceName+this.data.cityName+this.data.districtName
    +this.data.address

    console.log(t)
    copy(t)
  }

  private async save(){
    // 整理保存的数据
    const sds = this.tableData.filter((e:any)=>e.selectNum > 0)
    const batchNum = sds.map((e:any)=>e.batchNum).join(',')
    const validity = sds.map((e:any)=>e.validity).join(',')
    const prodDate = sds.map((e:any)=>e.prodDate).join(',')
    const batchCount = sds.map((e:any)=>e.selectNum).join(',')

    const param = {id:this.data.id,batchNum,validity,prodDate,batchCount}
    console.log(param);
    await UploadBatchInfo(param)
    this.close()
  }
}
