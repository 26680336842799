












import { Component, Vue } from 'vue-property-decorator'
import { QuerySupplierOrderForOperator } from '@/api'
import { getRole } from '@/store/userStore'
import OrderItem from './componets/OrderItem.vue'
import router from '@/router'

@Component({
  components: {
    OrderItem
  },
})
export default class OrderForOperator extends Vue {
  private queryParam: any = {
    orderCode: ""
  }
  private selectValue = ""
  private tableTotal = 0
  private tableData: Array<any> = []
  private tableCurrntPage = 0
  private tableOver = false
  private supplierSelect: Array<any>= []
  private activityItems: Array<any> = []
  private get role() {
    return getRole();
  }

  public async created() {
   
    const supplierId = router.currentRoute.query.supplierId
    this.queryParam.supplierId = supplierId
  }

  public refresh(){
    this.tableCurrntPage = 1
    this.tableOver = false
    this.activityItems = []
    const sc = this.$refs.scroller as any
    sc.finishInfinite(true)
    this.loadData()
  }
  public infinite(){
    this.tableCurrntPage = this.tableCurrntPage+1
    this.loadData()
  }

  public async loadData() {
    const sc = this.$refs.scroller as any
    if(this.tableOver){
      sc.finishPullToRefresh(true)
      sc.finishInfinite(true)
      return
    }
    const param: any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * 10
    const result = await QuerySupplierOrderForOperator(param)
    this.tableTotal = result.total
    this.tableOver = !result.hasNextPage
    if(this.tableCurrntPage == 1){
      this.tableData = result.list
    } else {
      this.tableData.push(...result.list)
    }
    if(this.activityItems.length > 0){
      this.activityItems.push(this.tableData[0].orderCode)
      console.log(this.activityItems)
    }

    sc.finishPullToRefresh()
    sc.finishInfinite()
  }


  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  
}
