


















import { Component, Vue } from 'vue-property-decorator'
import 'mavon-editor/dist/css/index.css'
import { QueryBrochureById, CreateBrochure,UpdateBrochure } from '@/api'
import {getUrlKey} from '@/utils/request'
import { Message } from 'element-ui'
import mavonEditor from 'mavon-editor'

const me = mavonEditor.mavonEditor

@Component({
  components: {
   me,
  },
})
export default class EditBrochure extends Vue {
  private data: any = {content:'', html:''}

  private created(param: any){
    this.data.id =  Number(getUrlKey('id'))
    this.loadData()
  }

  private async loadData(){
    if(this.data.id <= 0){
      return
    }
    const result = await QueryBrochureById({id: this.data.id})
    this.data = result
  }

  private change(value: any, render: any) {
    this.data.html = render
  }
  private  async click() {
    if(this.data.id <= 0){
      const id = await CreateBrochure(this.data)
      this.data.id = id
      Message.success("提交成功")
    }else{
      await UpdateBrochure(this.data)
      Message.success("提交成功")
    }
  }
}
