




















import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { DelistDrug, DrugStatistics, ExportDrug, ListingDrug, QueryDrug, QueryPartnerShopForSelect, SupplierListForSelect } from '@/api'
import { DateFormat, DateFormat2 } from '@/utils/date/DateUtil'
import EditDrug from  './EditDrug.vue'
import AddDrugBatch from  './AddDrugBatch.vue'
import DrugDetails from  './DrugDetails.vue'
import constant from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import { getRole } from '@/store/userStore'
import ImportDrugDialog from './componets/ImportDrugDialog.vue'
import UpdateSuppliyPrice from './componets/UpdateSuppliyPrice.vue'

@Component({
  components: {
    VQuery,
    VTable,
    EditDrug,
    AddDrugBatch,
    DrugDetails,
    ImportDrugDialog,
    UpdateSuppliyPrice
  },
})
export default class PlatformManager extends Vue {
  private queryParam: any = {
    status: 2,
    limit: 10
  }
  private activityTab = "success"
  private tableTotal = 0
  private tableData = []
  private tableSelectData = []
  private tableCurrntPage = 1
  private supplierSelect= []
  private shopSelect = []
  private drugStatistics = {drugAuditSuccessCount:0,drugAduitRefuseCount:0,drugDelistCount:0,stockDeficiencyCount:0,validityCount:0}
  private get role() {
    return getRole();
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '供应商', type:"select", param: 'supplierId', selectData: this.supplierSelect,remoteMethod: this.supplierRmote , show: ()=> this.role == 1 || this.role == 4},
    { title: '店铺', type:"select", param: 'partnerShopIds', selectData: this.shopSelect , show: ()=> this.role == 1 || this.role == 4},
    { title:'产品编号', param: "drugCode"},
    { title:'产品名称', param: "drugName"},
    { title:'规格', param: "pack"},
    { title:'生产厂家', param: "factory"},
  ]
  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    { title: '新增产品', type: 'primary', click: () => this.openDialog('EditDrug') },
    { title: '导出产品', type: 'primary', click: this.export, show: ()=>this.role == 1 || this.role == 4},
    { title: '导入产品', type: 'primary', show: ()=>this.role == 1 || this.role == 4,click: () => this.openDialog('ImportDrugDialog') },
    { title: '批量下架', type: 'primary', show: this.showDelistDrug,disable: this.batchDisable,click: this.delistDrug},
    { title: '批量上架', type: 'primary', show: this.showListingDrug, disable: this.batchDisable,click: this.listingDrug},
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: 'ID', param: 'id', type: "selection", width: 50},
    { title: '序号', param: 'id', type: "index", width: 50},
    // { title: '店铺', param: 'partnerShopIds', width: 80,show: ()=> this.role == 1, formatter: this.formShop, overflow: true },
    // { title: '商品图', param: 'image1', slotName: 'img' , width: 84 },
    { title: '供应商', param: 'supplierName', width: 80,overflow: true,show: ()=> this.role != 2 },
    { title: '产品编号', param: 'drugCode', width: 120},
    { title: '产品名称', param: 'drugName'},
    { title: '供货价', param: 'suppliyPrice',width: 60 },
    { title: '售价', param: 'price',width: 60, show: ()=> this.role != 2 },
    { title: '单位', param: 'unit' ,width: 60},
    { title: '规格', param: 'pack' ,width: 120},
    { title: '生产厂家', param: 'factory' },
    { title: '库存', param: 'stock' ,width: 60},
    { title: '状态', param: 'status',width: 80, formatter: (value: any)=>{
      return StatusFormat(constant.DrugApptType,value)
    } },
    { title: '创建时间', param: 'createTime', width: 110 ,overflow: true, formatter: DateFormat },
    {
      title: '操作',
      fixed: 'right',
      width: 240,
      operation: [
        {
          title: '批次详情',
          click: (data) => this.openDialog('DrugDetails',data),
        },
        // {
        //   title: '新增批次',
        //   click: (data) => this.openDialog('AddDrugBatch',data),
        // },
        {
          title: '修改供货价',
          click: (data) => this.openDialog('UpdateSuppliyPrice',data),
          show: (data)=>{
            if(this.role == 2 && data.status == 2){
              return true
            }
            return false
          }
        },
        {
          title: '编辑',
          click: (data) => this.openDialog('EditDrug',data),
          show: (data)=>{
            if(this.role == 1 || this.role == 4){
              return true
            }
            return data.status == 0 || data.status == 3
          }
        },
      ],
    },
  ]

  private batchDisable(){
    return this.tableSelectData.length == 0
  }
  private selectionChange(val:any){
    this.tableSelectData = val
  }

  public async created() {
    // 平台角色，则加载供应商
    if(this.role == 1 || this.role == 4){
      this.supplierRmote("")
      this.shopRmote("")
    }
    this.loadData()
    this.loadDrugStatistics()
  }

  private async loadDrugStatistics(){
    const param: any = { ...this.queryParam }
    const res = await DrugStatistics(param)
    this.drugStatistics = res
  }

  public async loadData() {
    this.tabClickParam()
    const param: any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QueryDrug(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  private async supplierRmote(query: any,id?: number) {
    const list = await SupplierListForSelect({name:query,id:id})
    this.supplierSelect = list.list
    this.queryModels[0].selectData = this.supplierSelect
  }

  private async shopRmote(query: any,id?: number) {
    const list = await QueryPartnerShopForSelect({name:query,id:id})
    this.shopSelect = list.list
    this.queryModels[1].selectData = this.shopSelect
  }
  private formShop(value: string){
    if(value == null){
      return ""
    }
    const vs = value.split(',')
    const result: Array<string> = []
    vs.forEach(v => {
      this.shopSelect.forEach((s:any)=>{
        if(s.value == v){
          result.push(s.label) 
        }
      })
    });
    return result.join(',')
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
    this.loadDrugStatistics()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }
  private export(){
    this.tabClickParam()
    const param: any = { ...this.queryParam }
    console.log(param)
    console.log(this.queryParam)
    // if(this.role == 1){
    //   // 平台角色，只加载有效产品
    //   param.statusGt = 1
    // } else {
    //   param.statusGt = 0
    // }
    ExportDrug(param) 
  }
  
  private tabClick(){
    // this.tabClickParam()
    this.tableCurrntPage = 1
    this.loadData()
  }
  private tabClickParam(){
    this.queryParam.queryValidity = false
    this.queryParam.stockGe = ""
    this.queryParam.stockLe = ""
    if(this.activityTab == "success"){
      this.queryParam.status = 2
    } else if(this.activityTab == "delist"){
       this.queryParam.status = 4
    } else if(this.activityTab == "refuse") {
      this.queryParam.status = 3
    }else if(this.activityTab == "stockDeficiency") {
      this.queryParam.status = 2
      this.queryParam.stockGe = 0
      this.queryParam.stockLe = 50
    }else if(this.activityTab == "validity") {
      this.queryParam.status = 2
      this.queryParam.queryValidity = true
    }
  }

  private delistDrug(){
    // 批量下架
    if(this.tableSelectData.length == 0){
      return
    }
    const ids = this.tableSelectData.map((e :any)=>e.id)
    this.$alert('是否确认批量下架','提示',{
      confirmButtonText: "确定",
      cancelButtonText:"取消",
      showCancelButton: true,
      callback:async (action) => {
        if(action == 'confirm'){
          const result = await DelistDrug({ids})
          if(result){
            this.$message({
              type: 'success',
              message: '下架完成'
            });
            this.loadData()
          } else {
            this.$message({
              type: 'error',
              message: '下架异常'
            });
          }
        }
      }
    })
  }

  private listingDrug(){
    // 批量上架
    if(this.tableSelectData.length == 0){
      return
    }
    const ids = this.tableSelectData.map((e :any)=>e.id)
    this.$alert('是否确认批量上架','提示',{
      confirmButtonText: "确定",
      cancelButtonText:"取消",
      showCancelButton: true,
      callback:async (action) => {
        if(action == 'confirm'){
          const result = await ListingDrug({ids})
          if(result){
            this.$message({
              type: 'success',
              message: '下架完成'
            });
            this.loadData()
          } else {
            this.$message({
              type: 'error',
              message: '下架异常'
            });
          }
        }
      }
    })
  }

  private showListingDrug(){
    return this.activityTab == 'delist' && (this.role == 1 || this.role == 4)
  }
   private showDelistDrug(){
    return this.activityTab != 'delist' && (this.role == 1 || this.role == 4)
  }
  
}
