export function dateFormat(time: string|Date, type: string) {
  let formatTime
  const date = new Date(time)
  const Year = date.getFullYear()
  const month = date.getMonth() + 1
  const Month = month >= 10 ? month : '0' + month
  const day = date.getDate()
  const Day = day >= 10 ? day : '0' + day
  const Hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const Minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const Second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  if (type === 'yyyy-MM-dd') {
    formatTime = Year + '-' + month + '-' + day
    return formatTime
  } else if (type === 'yyyy-MM-dd hh:mm:ss') {
    formatTime = Year + '-' + month + '-' + day + ' ' + Hour + ':' + Minute + ':' + Second
    return formatTime
  } else if (type === 'hh:mm:ss') {
    formatTime = Hour + ':' + Minute + ':' + Second
    return formatTime
  } else if (type === 'yyyyMMdd') {
    formatTime = Year + '' + month + '' + day
    return formatTime
  } else if (type === 'yyyy-MM-dd.hh') {
    formatTime = Year + '-' + month + '-' + day+'.'+Hour
    return formatTime
  } else if (type === 'MM.dd') {
    formatTime = month + '.' + day
    return formatTime
  } else {
    return 'error type!'
  }
}

export const format = {
  dateFormat,
}

export default format
