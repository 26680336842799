











import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { ExportDrugstore, QueryDrugStore, QueryPartnerShopForSelect } from '@/api'
import { DateFormat, DateFormat2 } from '@/utils/date/DateUtil'
import constant from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import { getRole } from '@/store/userStore'
import DrugStoreDetails from './componets/DrugStoreDetails.vue'
import EditDrugStore from './componets/EditDrugStore.vue'

@Component({
  components: {
    VQuery,
    VTable,
    DrugStoreDetails,
    EditDrugStore
  },
})
export default class DrugStore extends Vue {
  private queryParam = {
    limit: 10
  }
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private supplierSelect= []
  private shopSelect= []
  private get role() {
    return getRole();
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '店铺', type:"select", param: 'partnerShopId', selectData: this.shopSelect , show: ()=> this.role == 1},
    { title:'药店名称', param: "drugstoreName"},
    { title:'药店编号', param: "customerId"},
    { title:'药师帮药店编号', param: "drugstoreId"},
  ]
  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    { title: '创建药店', type: 'primary', click: () => this.openDialog('EditDrugStore') },
    { title: '导出药店', type: 'primary', click: () => ExportDrugstore(this.queryParam) },
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '店铺', param: 'partnerShopId', width: 120, formatter: this.formShop, overflow: true },
    { title: '药店编号', param: 'customerId', width: 130},
    { title: '药师帮药店编号', param: 'drugstoreId', width: 130},
    { title: '药店名称', param: 'drugstoreName', width: 150, overflow: true},
    { title: '省市区', param: 'provinceName', width: 150, formatter: this.formatterAddress},
    { title: '详细地址', param: 'regAdress' , width: 240,overflow: true},
    { title: '创建时间', param: 'createTime', width: 160 , formatter: DateFormat },
    {
      title: '操作',
      fixed: 'right',
      width: 200,
      operation: [
        {
          title: '查看详情',
          click: (data) => this.openDialog('DrugStoreDetails',data),
        },
        {
          title: '编辑',
          click: (data) => this.openDialog('EditDrugStore',data),
        },
        
      ],
    },
  ]

  private formatterAddress(cv: any , row: any){
    return row.provinceName+row.cityName+row.districtName || ""
  }

  public async created() {
    this.shopRmote("")
    this.loadData()
  }

  public async loadData() {
    const param: any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QueryDrugStore(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private async shopRmote(query: any,id?: number) {
    const list = await QueryPartnerShopForSelect({name:query,id:id})
    this.shopSelect = list.list
    this.queryModels[0].selectData = this.shopSelect
  }

  private formShop(value: string){
    let result = ""
    this.shopSelect.forEach((s:any)=>{
        if(s.value == value){
          result = s.label
          return
        }
      })
    return result
  }
  
}
