


















import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { BatchSorting, ExportAllOrderList, ExportOrder, ExportOrderForDistribution, ExportOrderShipperInfo, OrderFinish, OrderStatistics, QueryOrder, QueryPartnerShopForSelect, SupplierListForSelect } from '@/api'
import { timeFormate } from '@/utils/date/DateUtil'
import constant from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import { getRole } from '@/store/userStore'
import OrderDetails from './componets/OrderDetails.vue'
import { MessageBox } from 'element-ui'

@Component({
  components: {
    VQuery,
    VTable,
    OrderDetails
  },
})
export default class Order extends Vue {
  private queryParam: any = {
    startRow: 0,
    status: 1,
    limit: 10
  }
  private activityTab = 1
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private supplierSelect= []
  private shopSelect= []
  private tableSelectData = []
  private orderStatistics: any = {
    status0:0,
    status1:0,
    status2:0,
    status3:0,
    status4:0,
    status5:0,
    status6:0,
    status7:0,
  }
  private get role() {
    return getRole();
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '供应商', type:"select", param: 'supplierId', selectData: this.supplierSelect,remoteMethod: this.supplierRmote , show: ()=> this.role == 1},
    { title: '店铺', type:"select", param: 'partnerShopId', selectData: this.shopSelect , show: ()=> this.role == 1},
    { title:'订单编号', param: "orderCode"},
    { title:'药师帮订单编号', param: "orderId"},
    { title:'药店名称', param: "drugstoreName"},
    // { title: '状态', param: 'status', type:'select',selectData: constant.OrderStatus },
    {title:'下单时间', param: 'orderTimeScope', type:'daterange'},
  ]
  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    // { title: '配送中', type: 'primary',disable: this.batchDisable,show:(data)=>{
    //   return this.queryParam.status == 2
    // } },
    // { title: '配送完成', type: 'primary',disable: this.batchDisable,show:(data)=>{
    //   return this.queryParam.status == 3
    // } },
    // { title: '订单完成', type: 'primary',disable: this.batchDisable,show:(data)=>{
    //   return this.queryParam.status == 4
    // } },
    
    { title: '导出所有清单', type: 'primary',disable: this.allList, show:()=>{return this.queryParam.status == 1}, click: () => {
      const orderCodes = this.tableSelectData.map((e :any)=>e.orderCode)
      const supplierId = this.queryParam.supplierId || null
      const param: any = {}
      param.orderCodes = orderCodes
      if(supplierId){
        param.supplierId = supplierId
      }
      ExportAllOrderList(param)
    } },
    { title: '导出清单', type: 'primary',disable: this.batchDisable, click: this.exportOrderForDistribution },
    { title: '导出快递信息', type: 'primary',disable: this.batchDisable, click: this.exportOrderShipperInfo },
    { title: '分拣中', type: 'primary',disable: this.batchDisable, show:()=>{return this.queryParam.status == 1},click: this.batchSorting },
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: 'ID', param: 'id',type:"selection"},
    { title: '店铺', param: 'partnerShopId', width: 80, formatter: this.formShop, overflow: true },
    { title: '订单编号', param: 'orderCode', width: 120, 
      click: (value,data) =>  {
        data.tabKey = 'details'
        this.openDialog('OrderDetails',data)
      },
    },
    { title: '药师帮订单编号', param: 'orderId', width: 120},
    { title: '药店名称', param: 'drugstoreName'},
    { title: '总金额', param: 'totalCost', width: 80 },
    { title: '备注', param: 'remark', width: 200,overflow: true },
    { title: '状态', param: 'status', width: 100, formatter: (value: any)=>{
      return StatusFormat(constant.OrderStatus,value)
    } },
    { title: '下单时间', param: 'orderTime', width: 160 },
    
    // { title: '创建时间', param: 'createTime', width: 160 , formatter: DateFormat },
    {
      title: '操作',
      fixed: 'right',
      width: 200,
      operation: [
        {
          title: '开单',
          click: (data) => {
            data.tabKey = 'details'
            this.openDialog('OrderDetails',data)},
          show: (data)=> data.status == 1 || data.status == 7
        },
        {
          title: '上传快递单号',
          click: (data) => {
            data.tabKey = 'info'
            this.openDialog('OrderDetails',data)},
          show: (data)=> data.status > 0 && data.status < 5 
        },
        {
          title: '上传发票',
          click: (data) => {
            data.tabKey = 'invoice'
            this.openDialog('OrderDetails',data)},
          show: (data)=> data.status > 0 && data.status < 5 
        },
        {
          title: '订单详情',
          click: (data) =>  {
            data.tabKey = 'details'
            this.openDialog('OrderDetails',data)},
        },
        {
          title: '订单完成',
          click: (data) =>  {
            this.finishOrder(data)
            },
          show: (data)=> this.shopChannel(data.partnerShopId) == "YBM" && data.status >=2 && data.status < 5
        },
      ],
    },
  ]
  private batchDisable(){
    return this.tableSelectData.length == 0
  }
  private allList(){
    // return !this.queryParam.supplierId
    return false
  }
  private selectionChange(val:any){
    this.tableSelectData = val
    console.log('this.tableSelectData')
    console.log(this.tableSelectData)
  }

  public async created() {
    this.shopRmote("")
    this.supplierRmote("")
    this.loadData()
    this.loadOrderStatistics()
  }

  private async loadOrderStatistics(){
    const param: any = { ...this.queryParam }
    if(param.orderTimeScope && param.orderTimeScope.length==2){
      param.orderTime = timeFormate(param.orderTimeScope[0])+" 00:00:00,"+timeFormate(param.orderTimeScope[1])+" 23:59:59"
    }
    
    const orderStatistics = await OrderStatistics(param)
    this.orderStatistics = orderStatistics
  }

  private tabClick(tab,e){
    this.tableCurrntPage = 1
    this.loadData()
  }

  public async loadData() {
    const param: any = { ...this.queryParam }
    param.orderBy = "order_time"
    if(param.orderTimeScope && param.orderTimeScope.length==2){
      param.orderTime = timeFormate(param.orderTimeScope[0])+" 00:00:00,"+timeFormate(param.orderTimeScope[1])+" 23:59:59"
    }
    
    if(param.status == 2){
      param.status = null
      param.statusLe = 4
      param.statusGe = 2
    }

    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QueryOrder(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
    this.loadOrderStatistics()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private async shopRmote(query: any,id?: number) {
    const list = await QueryPartnerShopForSelect({name:query,id:id})
    this.shopSelect = list.list
    this.queryModels[1].selectData = this.shopSelect
  }

  private formShop(value: string){
    let result = ""
    this.shopSelect.forEach((s:any)=>{
        if(s.value == value){
          result = s.label
          return
        }
      })
    return result
  }

  private shopChannel(value: string){
    let result = ""
    this.shopSelect.forEach((s:any)=>{
        if(s.value == value){
          result = s.partnerChannel
          return
        }
      })
    return result
  }

  private async supplierRmote(query: any,id?: number) {
    const list = await SupplierListForSelect({name:query,id:id})
    this.supplierSelect = list.list
    this.queryModels[0].selectData = this.supplierSelect
  }

  private exportOrderForDistribution() {
      const orderCodes = this.tableSelectData.map((e :any)=>e.orderCode)
      ExportOrderForDistribution({orderCodes})
    }

  private exportOrderShipperInfo() {
      const orderCodes = this.tableSelectData.map((e :any)=>e.orderCode)
      ExportOrderShipperInfo({orderCodes})
    }
  
  private async batchSorting(){
    const ids = this.tableSelectData.map((e :any)=>e.id)
    const result = await BatchSorting({ids});
    if(result){
      this.loadData()
    }
  }

  private async finishOrder(data){
    // 订单完成
    MessageBox.confirm('确认将订单（'+data.orderCode+'）变更为已完成吗？\n ps:订单完成，只会改变erp的订单状态，不会变更平台方', '提示', {
      type: 'warning',
    }).then(async () => {
      await OrderFinish({ids:[data.id]})
      this.loadData()
    })

  }
}
