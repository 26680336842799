















import { Component, Vue, Emit } from 'vue-property-decorator'
import { UpdatePlatformAccountPassword } from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: {  },
})
export default class UpdatePwdDialog extends Vue {
  private show = false

  private data = {
    oldPassword: '',
    newPassword: '',
  }

  //必填项
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    oldPassword: [this.requiredRule],
    newPassword: [this.requiredRule],
  }

  //数据导入
  public async open() {
    this.show = true
  }

  //取消
  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
  }

  //保存
  public async save() {
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      const result = await UpdatePlatformAccountPassword(this.data) //数据源
      if (result) {
        Message.success('保存')
        this.close()
      }
    })
  }
}
