// 平台角色路由
import { RouteConfig } from 'vue-router'
import ManageLayout from '@/layout/ManageLayout.vue'
import H5TabBarLayout from '@/layout/H5TabBarLayout.vue'
import H5PageLayout from '@/layout/H5PageLayout.vue'

import Login from '@/views/Login.vue'
import PlatformManager from '@/views/platform/PlatformManager.vue'
import EditBrochure from '@/views/brochure/EditBrochure.vue'
import BrochureList from '@/views/brochure/BrochureList.vue'
import Banner from '@/views/banner/Banner.vue'
import Error404 from '@/views/error/404.vue'
import Supplier from '@/views/supplier/supplier.vue'
import Drug from '@/views/drug/Drug.vue'
import DrugDraft from '@/views/drug/DrugDraft.vue'
import DrugAudit from '@/views/drug/DrugAudit.vue'
import DrugStore from '@/views/drugStore/DrugStore.vue'
import Order from '@/views/order/Order.vue'
import Work from '@/views/work/Work.vue'
import Sales from '@/views/sales/Sales.vue'
import AccountCenter from '@/views/accountCenter/AccountCenter.vue'
import PartnerShop from '@/views/partnerShop/PartnerShop.vue'
import DrugPriceAudit from '@/views/drugPriceAudit/drugPriceAudit.vue'

import H5Drug from '@/views/h5/drug/Drug.vue'
import H5EditDrug from '@/views/h5/drug/EditDrug.vue'
import H5ManageDrugBatch from '@/views/h5/drug/ManageDrugBatch.vue'
import H5AddDrugBatch from '@/views/h5/drug/AddDrugBatch.vue'
import H5UpdateDrugBatch from '@/views/h5/drug/UpdateDrugBatch.vue'
import H5PutDrugBatch from '@/views/h5/drug/PutDrugBatch.vue'
import H5OutDrugBatch from '@/views/h5/drug/OutDrugBatch.vue'
import H5Order from '@/views/h5/order/Order.vue'
import H5Seting from '@/views/h5/seting/index.vue'
import H5Sales from '@/views/h5/sales/Sales.vue'
import H5OperatorSupplierList from '@/views/h5/operator/order/OperatorSupplierList.vue'
import H5SuppliyPriceAudit from '@/views/h5/drug/SuppliyPriceAudit.vue'


const number = 3

/**
 * 路由
 */
const routeConfig: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/work',
  },
  {
    path: '/h5',
    redirect: '/h5/drug',
  },
  {
    // H5的底部导航
    path: '/',
    name: 'H5TabBar',
    component: H5TabBarLayout,
    meta: {
      title: '首页',
    },children: [
      {
        path: '/h5/drug',
        name: 'h5drug',
        meta: {
          title: '产品管理-康恩诺ERP',
        },
        component: H5Drug,
      },
      {
        path: '/h5/addDrug',
        name: 'h5EditDrug',
        meta: {
          title: '编辑产品-康恩诺ERP',
        },
        component: H5EditDrug,
      },
      {
        path: '/h5/order',
        name: 'h5Order',
        meta: {
          title: '订单出库-康恩诺ERP',
        },
        component: H5Order,
      },
      {
        path: '/h5/sales',
        name: 'H5Sales',
        meta: {
          title: '销售清单-康恩诺ERP',
        },
        component: H5Sales,
      },
      
    ]
  },
  {
    // H5的常规页面
    path: '/',
    name: 'H5PageLayout',
    component: H5PageLayout,
    meta: {
      title: '首页',
    },children: [
      {
        path: '/h5/operatorSupplierList',
        name: 'h5OperatorSupplierList',
        meta: {
          title: '供应商订单信息',
        },
        component: H5OperatorSupplierList,
      },
      {
        path: '/h5/editDrug',
        name: 'h5EditDrug',
        meta: {
          title: '编辑产品',
        },
        component: H5EditDrug,
      },
      {
        path: '/h5/seting',
        name: 'H5Seting',
        meta: {
          title: '设置',
        },
        component: H5Seting,
      },
      {
        path: '/h5/manageDrugBatch',
        name: 'manageDrugBatch',
        meta: {
          title: '管理批次',
        },
        component: H5ManageDrugBatch,
      },
      {
        path: '/h5/addDrugBatch',
        name: 'addDrugBatch',
        meta: {
          title: '新增批次',
        },
        component: H5AddDrugBatch,
      },
      {
        path: '/h5/updateDrugBatch',
        name: 'updateDrugBatch',
        meta: {
          title: '修改批次',
        },
        component: H5UpdateDrugBatch,
      },
      {
        path: '/h5/putDrugBatch',
        name: 'putDrugBatch',
        meta: {
          title: '入库',
        },
        component: H5PutDrugBatch,
      },
      {
        path: '/h5/outDrugBatch',
        name: 'outDrugBatch',
        meta: {
          title: '出库',
        },
        component: H5OutDrugBatch,
      },
      {
        path: '/h5/suppliyPriceAudit',
        name: 'SuppliyPriceAudit',
        meta: {
          title: '修改供货价',
        },
        component: H5SuppliyPriceAudit,
      },
    ]
  },
  {
    path: '/',
    name: 'Home',
    component: ManageLayout,
    meta: {
      title: '首页',
    },
    children: [
      {
        path: '/sales',
        name: 'Sales',
        meta: {
          title: '销售单',
        },
        component: Sales,
      },
      {
        path: '/partnerShop',
        name: 'PartnerShop',
        meta: {
          title: '店铺管理',
        },
        component: PartnerShop,
      },
      {
        path: '/accountCenter',
        name: 'AccountCenter',
        meta: {
          title: '账户中心',
        },
        component: AccountCenter,
      },
      {
        path: '/order',
        name: 'Order',
        meta: {
          title: '订单管理',
        },
        component: Order,
      },
      {
        path: '/work',
        name: 'Work',
        meta: {
          title: '工作台-康恩诺',
        },
        component: Work,
      },
      {
        path: '/drugStore',
        name: 'DrugStore',
        meta: {
          title: '药店',
        },
        component: DrugStore,
      },
      {
        path: '/drug',
        name: 'Drug',
        meta: {
          title: '产品管理',
        },
        component: Drug,
      },
      {
        path: '/drugAudit',
        name: 'DrugAudit',
        meta: {
          title: '产品审核',
        },
        component: DrugAudit,
      },
      {
        path: '/drugDraft',
        name: 'DrugDraft',
        meta: {
          title: '草稿箱',
        },
        component: DrugDraft,
      },
      {
        path: '/error404',
        name: 'error404',
        meta: {
          title: '页面不存在',
        },
        component: Error404,
      },
      {
        path: '/supplier',
        name: 'Supplier',
        meta: {
          title: '供应商账号管理',
        },
        component: Supplier,
      },
      {
        path: '/platformManager',
        name: 'platformManager',
        meta: {
          title: '员工账号管理',
        },
        component: PlatformManager,
      },
      {
        path: '/editBrochure',
        name: 'editBrochure',
        meta: {
          title: '编辑宣传页',
        },
        component: EditBrochure,
      },
      {
        path: '/brochureList',
        name: 'brochureList',
        meta: {
          title: '编辑宣传页',
        },
        component: BrochureList,
      },
      {
        path: '/banner',
        name: 'banner',
        meta: {
          title: 'banner',
        },
        component: Banner,
      },
      {
        path: '/drugPriceAudit',
        name: 'drugPriceAudit',
        meta: {
          title: '供货价审核',
        },
        component: DrugPriceAudit,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: Login,
  },
]

/**
 * 菜单
 */
const menu = [
  {
    icon: require('@/assets/img/index-w.png'),
    index: '/work',
    title: '工作台',
    hiddenClose: true
  },
  
  {
    icon: require('@/assets/img/drugstore-w.png'),
    index: '/drugStore',
    title: '药店',
  },
  {
    icon: require('@/assets/img/drug-w.png'),
    index: '1',
    title: '产品管理',
    subs: [
      {
        index: '/drugDraft',
        title: '草稿箱',
      },
      {
        index: '/drug',
        title: '产品管理',
      },
      {
        index: '/drugAudit',
        title: '产品审核',
      },
      {
        index: '/drugPriceAudit',
        title: '供货价审核',
      },
    ]
  },
  {
    icon: require('@/assets/img/order-w.png'),
    index: '/order',
    title: '订单管理',
  },
  {
    icon: require('@/assets/img/sales-w.png'),
    index: '/sales',
    title: '销售单',
  },
  {
    icon: require('@/assets/img/system-setting-w.png'),
    index: '2',
    title: '系统设置',
    subs: [
      {
        icon: require('@/assets/img/account-w.png'),
        index: '/platformManager',
        title: '员工账号管理',
      },
      {
        icon: require('@/assets/img/supplier-w.png'),
        index: '/supplier',
        title: '供应商账号管理',
      },
      {
        icon: require('@/assets/img/supplier-w.png'),
        index: '/partnerShop',
        title: '店铺管理',
      },
    ]
  },
]

const r = {
  routeConfig,
  menu
}
export default r