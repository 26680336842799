import { render, staticRenderFns } from "./DrugBatchItem.vue?vue&type=template&id=ad5d1b96&scoped=true&lang=pug&"
import script from "./DrugBatchItem.vue?vue&type=script&lang=ts&"
export * from "./DrugBatchItem.vue?vue&type=script&lang=ts&"
import style0 from "./DrugBatchItem.vue?vue&type=style&index=0&id=ad5d1b96&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad5d1b96",
  null
  
)

export default component.exports