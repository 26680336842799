
























































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {  QueryOrderDetails,MakeOrder, ConfirmOut, QuerySystemConfigByKey, QueryPartnerShop} from '@/api'
import constant from '@/utils/constant'
import { DateFormat, DateFormat2, timeFormate } from '@/utils/date/DateUtil'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { getRole,getUser } from '@/store/userStore'
import { VImageUpload } from '@/components/upload'
import { StatusFormat } from '@/utils/tyepconvert'
import copy from '@/utils/copy'
import SelectBatch from './SelectBatch.vue'
import UploadInvoice from './UploadInvoice.vue'
import UploadShipper from './UploadShipper.vue'
import { Message } from 'element-ui'
import {digitUppercase} from '@/utils/NumberToChinese'
import html2canvas from 'html2canvas'
import PrintBill from './PrintBill.vue'
import EditOrderBatch from './EditOrderBatch.vue'

@Component({
  components: {
    VTable,
    VQuery,
    VImageUpload,
    SelectBatch,
    UploadInvoice,
    UploadShipper,
    PrintBill,
    EditOrderBatch
  },
})
export default class OrderDetails extends Vue {
  // 是否是审核
  @Prop({type: Boolean,default: false}) private audit!: boolean
  private constant = constant
  private show = false
  private data: any = {}
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private selectTab = 'info'
  private printImgUrl = ""
  private disablePrint = false
  private printImgWidth = 300
  private shopName = ""
  private queryParam = {
  }
  private get role() {
    return getRole();
  }
  private get user(){
    return getUser().user
  }
  private get oerderStatus(){
    return StatusFormat(constant.OrderStatus,this.data.status)
  }
  private get invoiceType(){
    return StatusFormat(constant.InvoiceType,this.data.invoiceType)
  }
  private get isEInvoice(){
    return StatusFormat(constant.IsEInvoice,this.data.isEInvoice)
  }
  private get refundType(){
    return StatusFormat(constant.RefundType,this.data.refundType)
  }
  private get refundStatus(){
    return StatusFormat(constant.RefundStatus,this.data.refundStatus)
  }
  private get refundReason(){
    return StatusFormat(constant.RefundReason,this.data.refundReason)
  }
  private get makeDisable(){
    // 开单按钮是否禁用
    const s = this.tableData.filter((e:any)=>{
      if(e.batchNum && e.supplierConfirm != 0){
        return false
      }
      return true
    })
    return s.length>0
  }
  private get toChinese(){
    return digitUppercase(Number(this.data.totalCost))
  }
  public created(){
    this.loadSystemConfig()
  }
  private async loadSystemConfig(){
    const res = await QuerySystemConfigByKey({configKey:'billWidth'})
    this.printImgWidth = Number(res.configValue)
  }
  public beforeUpdate(){
    // this.disablePrint = true
  }
  public updated() {
    // this.loadImage()
  }
  private loadImage(){
    const ref: any = this.$refs.printArea
    html2canvas(ref,{
      backgroundColor: '#fff',
      useCORS: true
    }).then((canvas)=>{
      const ratio = 200 / canvas.width
      const dataURL = canvas.toDataURL('image/png')
      this.printImgUrl = dataURL

      // 打印
      const ref: any = this.$refs.printImg
      // 图片价值完成后
      ref.onload = ()=>{
        console.log("dsadas")
        this.disablePrint = false
      }
    })
  }

  private drugBarchLogData: Array<any> = []

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '批次状态', type:"select", span:5, param: 'stockGt', selectData: constant.BatchType},
  ]

  // 数据表头
  private tableHander: Array<TableHander> = [
    // { title: '供应商ID', width: 80,param: 'supplierId' },
    { title: '供应商名称', width: 100,param: 'supplierName' },
    { title: '药品图片',width: 80, param: 'image1',slotName:'img'},
    { title: '药品编号', width: 100, param: 'drugCode'},
    { title: '药品名称', param: 'drugName'},
    { title: '包装/规格', width: 120, param: 'pack'},
    { title: '厂家', width: 220, param: 'factory'},
    { title: '数量', width: 60, param: 'newQty'},
    { title: '单价', width: 80, param: 'price'},
    { title: '金额', width: 80, param: 'newAmount'},
    { title: '批号', width: 100, param: 'batchNum',overflow:true},
    { title: '生产日期', width: 100, param: 'prodDate',overflow:true},
    { title: '有效期', width: 100, param: 'validity',overflow:true},
    // { title: '批次数量', width: 80, param: 'batchCount',overflow:true},
    { title: '确认出库', width: 100,param: 'supplierConfirm', formatter: (value)=> {
      return StatusFormat(constant.SupplierConfirm,value)
    } },
    { title: '退货数量', width: 80,param: 'refundAmount'},
    {
      title: '操作',
      // fixed: 'right',
      width: 200,
      operation: [
        {
          title: '编辑批次',
          click: (data)=>this.openDialog('EditOrderBatch',data),
          // show: (data)=>{
          //   return data.supplierConfirm == 0
          // }
        },
        // {
        //   title: '选择批号',
        //   click: (data) => this.openDialog('SelectBatch',data),
        //   show: (data)=>{
        //     return this.showBatch() && !data.batchNum
        //   },
        // },
        // {
        //   title: '重新选择',
        //   click: (data) => this.openDialog('SelectBatch',data),
        //   show: (data)=>{
        //     return this.showBatch() && data.batchNum
        //   }
        // },
        {
          title: '确认出库',
          click: this.confirmOut,
          show: (data)=>{
            return data.supplierConfirm == 0
          }
        },
      ],
    },
  ]

  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
    
  }

  public async loadData() {
    const param: any = { ...this.queryParam,orderCode: this.data.orderCode}
    // param.startRow = (this.tableCurrntPage - 1) * 10
    const result = await QueryOrderDetails(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  @Emit('close')
  private close() {
    this.show = false
    this.data = {}
  }
  @Emit('refreshData')
  private refreshData() {
    // this.show = false
    // this.data = {} 
  }
  
  public open(param: any) {
    this.show = true
    this.data = {...param}
    if(param.tabKey){
      this.selectTab = param.tabKey
    } else {
      this.selectTab = 'details'
    }
    this.loadShop()
    this.loadData()
  }

  private async loadShop(){
    const res = await QueryPartnerShop({id:this.data.partnerShopId})
    const shop = res.list[0]
    this.shopName = shop.billTitle
  }

  private showBatch(){
    return this.data.status == 1 || this.data.status == 7
  }

  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private printBill(){
    const f = this.$refs['PrintBill'] as PrintBill
    f.open(this.data,this.tableData)
  }

  private openInvoice(){
    this.openDialog('UploadInvoice',this.data)
  }
  private openShipper(){
    this.openDialog('UploadShipper',this.data)
  }

  private copyRecv(){
    const t = this.data.recvName+' '
    +this.data.recvPhone+' '
    +this.data.provinceName+this.data.cityName+this.data.districtName
    +this.data.address
    copy(t)
  }

  //复制发票信息
  private copyInvoice(){
    const t = '药店名称：'+this.data.drugstoreName+'\n'
    +'税号：'+this.data.taxNo+'\n'
    +'开户行：'+this.data.bankName+'\n'
    +'开户行账号：'+this.data.bankCardNo
    copy(t)
  }

  // 开单
  private async makeOrder(){
    const res = await MakeOrder({id:this.data.id})
    if(res){
      Message.success('分拣完成')
      this.refreshData()
      this.close()
    }
  }

  private async confirmOut(data:any){
    //  确认出库
    const res = await ConfirmOut({orderDetailsIds: [data.id]})
    this.loadData()
  }

}
