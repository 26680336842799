



















import { Component, Vue, Emit } from 'vue-property-decorator'
import { AddDrugBatch as AddDrugBatchApi,SupplierListForSelect, QueryDrugByCode} from '@/api'
import {  Message } from 'element-ui'
import { Form, Dialog } from 'vant';
import constant from '@/utils/constant'
import { timeFormate,DateFormat2 } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'
import router from '@/router';

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
  },
})
export default class AddDrugBatch extends Vue {
  private constant = constant
  private show = false
  private data: any = {price:0}
  private price = 0
  private requiredRule = { required: true, message: '不能为空', trigger: 'onBlur' }
  
  private showSelectProdDate = false
  private showSelectValidity = false
  private currentProdDate = new Date()
  private currentValidity = new Date()
  private supplierSelect = []
  private get role() {
    return getRole();
  }
  private drugInfo = {}

  public async created() {
    const drugCode = router.currentRoute.query.drugCode
    
    // this.data = {}
    this.data.drugCode = drugCode
    // this.data.batchNum = "B"+timeFormate(new Date(),"YYMMDDHHmmss")

    const d = await QueryDrugByCode({drugCode: drugCode})
    this.drugInfo = d
    this.data.supplierId = d.supplierId
    this.data.price = d.suppliyPrice
    this.price = d.suppliyPrice
    console.log(this.data)
  }

  private DateFormat2(value:Date){
    if(!value){
      return ""
    }
    return timeFormate(new Date(value))
  }

  
  public beforeDestroy() {
    console.log('离开页面前')
    window.onbeforeunload = null
  }
  public destroyed() {
    console.log('离开页面了')

    window.onbeforeunload = null
  }

  private hintMessage = "页面中有正在编辑的内容，继续将不会保存"
  private showLeaveHint = false

  public mounted() {
    // window.onbeforeunload = (e:any)=>{
    //     e.returnValue = this.hintMessage;
    //     return this.hintMessage;
    // }
  }

  // * ---- 生命周期 ---- */
  // 改变url时，给出提示
  public beforeRouteLeave(to:any, from:any, next: any) {
    if(this.showLeaveHint){
      this.showLeaveHintFun(next);
    } else {
      next()
    }
    
  }

  public async showLeaveHintFun(next:any) {
    const a =await Dialog.confirm({
      title: "提醒",
      message: this.hintMessage
    })
    // const a = window.confirm(this.hintMessage)
    if (a) {
        next();
    } else {
        next(false);
    }
  }

  private selectProdDate(data:any){
    this.data.prodDate = timeFormate(new Date(data))
    this.showSelectProdDate = false
  }
  private selectValidity(data:any){
    this.data.validity = timeFormate(new Date(data))
    this.showSelectValidity = false
  }

  public async save() {
    await this.save2()
    this.showLeaveHint = false
    Message.success('保存成功')
  }

  public async save2(){
    console.log(this.data)
    const f = this.$refs.form as Form
    await f.validate()
    const param = {...this.data}
    param.prodDate = param.prodDate+" 00:00:00"
    param.validity = param.validity+" 23:59:59"
    const id = await AddDrugBatchApi(param)
    this.data.id = id;
    router.back()
  }
  
}
