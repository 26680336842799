import axios from 'axios';
import Conf from '@/config/config';
import { Message } from "element-ui";
import {getUser} from '@/store/userStore'
import router from '@/router';
import DeviceInfoStorage from '@/store/DeviceInfoStorage';


axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    baseURL: Conf.API_HOST,
    timeout: 600000
});
axios.defaults.baseURL = Conf.API_HOST
axios.defaults.timeout = 600000
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/json'


axios.interceptors.request.use(
    (config: any) => {
        // 添加token
        const  userInfo = getUser()
        if(userInfo){
            config.headers.common['Authorization'] = userInfo.token || ''
        }
        // 添加设备信息
        config.headers.common[DeviceInfoStorage.handerKey] = DeviceInfoStorage.getDeviceInfoHeader()
        return config;
    },
    (error: any) => {
        console.log(error);
        return Promise.reject();
    }
);

// 导出
export async function CommonExportGet(url: string , param: any = {}) {
    let paramStr = ''
    if (param != null) {
      paramStr = '?'
      Object.keys(param).forEach((key) => {
        paramStr += key + '=' + param[key] + '&'
      })
      paramStr.substring(paramStr.length - 1)
    }
    const jumpUrl = url + paramStr
    const res = await axios.get(jumpUrl,{responseType:'blob'})

    const fileName = decodeURI(res.headers['filename'])
    const blob = new Blob([res.data])
    const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
    const a = document.createElement('a') //创建a标签
    a.style.display = 'none'
    a.href = href // 指定下载链接
    a.download = fileName //指定下载文件名
    a.click() //触发下载
    URL.revokeObjectURL(a.href) //释放URL对象
}


export async function CommonFetchPost(url: string , param = {}): Promise<any> {
    return new Promise((res,rej)=>{
        axios.post(url, param).then(result=>{
            const {data,status} = result
            if(status !== 200){
                Message.error("服务器繁忙，请稍后再试");
                return
            }
            if(data.code == 1300004){
                // 未登录 
                Message.error(data.msg);
                router.push('/login')
                return
            } else if(data.code != 10000){
                // 异常
                Message.error(data.msg);
                return
            }
            res(data.data)
        })
    })
    
  }
