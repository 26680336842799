












import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { QueryDrug, SubmitAuditDrug, SupplierListForSelect } from '@/api'
import { DateFormat, DateFormat2 } from '@/utils/date/DateUtil'
import EditDrug from  './EditDrug.vue'
import AddDrugBatch from  './AddDrugBatch.vue'
import DrugDetails from  './DrugDetails.vue'
import constan from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import { getRole } from '@/store/userStore'
import { Message } from 'element-ui'

@Component({
  components: {
    VQuery,
    VTable,
    EditDrug,
    AddDrugBatch,
    DrugDetails
  },
})
export default class PlatformManager extends Vue {
  private queryParam = {
    startRow: 1,
    limit: 10
  }
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private supplierSelect= []
  private tableSelectData = []
  private get role() {
    return getRole();
  }
  private batchDisable(){
    return this.tableSelectData.length == 0
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '供应商', type:"select", param: 'supplierId', selectData: this.supplierSelect,remoteMethod: this.supplierRmote , show: ()=> this.role == 1},
    { title:'产品编号', param: "drugCode"},
    { title:'产品名称', param: "drugName"},
    // { title: '状态', param: 'status', type:'select',selectData: constant.PlatformStatus },
  ]
  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    { title: '新增产品', type: 'primary', click: () => this.openDialog('EditDrug') },
    { title: '批量提交审核', type: 'primary',disable: this.batchDisable, click: this.batchSubmit },
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: 'ID', param: 'id', type:"selection"},
    { title: '供应商', param: 'supplierName', width: 120,show: ()=> this.role == 1 },
    { title: '产品编号', param: 'drugCode', width: 120},
    { title: '产品名称', param: 'drugName', width: 120},
    { title: '供货价', param: 'suppliyPrice' },
    { title: '售价', param: 'price', show: ()=> this.role == 1 },
    { title: '单位', param: 'unit' },
    { title: '规格', param: 'pack' },
    { title: '生产厂家', param: 'factory' },
    { title: '库存', param: 'stock' },
    { title: '状态', param: 'status', formatter: (value: any)=>{
      return StatusFormat(constan.DrugApptType,value)
    } },
    { title: '创建时间', param: 'createTime', width: 160 , formatter: DateFormat },
    {
      title: '操作',
      fixed: 'right',
      width: 200,
      operation: [
        {
          title: '查看详情',
          click: (data) => this.openDialog('DrugDetails',data),
        },
        {
          title: '新增批次',
          click: (data) => this.openDialog('AddDrugBatch',data),
        },
        {
          title: '编辑',
          click: (data) => this.openDialog('EditDrug',data),
        },
      ],
    },
  ]

  public async created() {
    this.loadData()
    // 平台角色，则加载供应商
    if(this.role == 1){
      this.supplierRmote("")
    }
  }

  private selectionChange(val:any){
    this.tableSelectData = val
  }

  public async loadData() {
    const param: any = { ...this.queryParam,status: 0}
    
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QueryDrug(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  private async batchSubmit(){
    // 批量提交审核
    const ids = this.tableSelectData.map((e:any)=>{return e.id})
    await SubmitAuditDrug({ids})
    Message.success('提交成功')
    this.loadData()
  }

  private async supplierRmote(query: any,id?: number) {
    const list = await SupplierListForSelect({name:query,id:id})
    this.supplierSelect = list.list
    this.queryModels[0].selectData = this.supplierSelect
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  
}
