




























import { Component, Vue } from 'vue-property-decorator'
import { getRole, getUser } from '@/store/userStore'
import {  QueryWxAccount, ChangeWxAccountStatus, QuerySystemConfig, UpdateSystemConfigValue } from '@/api'
import { QueryModel, TableHander, TableOperation,VTable } from '@/components/table'
import { Message, MessageBox } from 'element-ui'
import WxMsgManage from './components/WxMsgManage.vue'

@Component({
  components: {
    VTable,
    WxMsgManage
  },
})
export default class AccountCenter extends Vue {
  private get role() {
    return getRole();
  }
  private get user(){
    console.log(getUser())
    return getUser()
  }
  private queryParam = {
    startRow: 0,
    limit: 10
  }
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private supplierSelect= []
  private systemConfig = []
  // 查询条件
  private queryModels: Array<QueryModel> = []
  // 全局操作 
  private queryOperation: Array<TableOperation> = []
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: 'id', param: 'id', width: 60 },
    { title: '微信头像', param: 'headImgUrl', width: 100, slotName: 'img' },
    { title: '微信昵称', width: 100,param: 'nickname' },
    { title: '状态', width: 100,param: 'status', formatter: (value)=> value == 0?'启用':'禁用' },
    {
      title: '操作',
      width: 180,
      operation: [
        {
          title: '禁用',
          click: this.wxdesable,
          show:(data)=>data.status == 0
        },
        {
          title: '启用',
          click: this.wxundesable,
          show:(data)=>data.status == 1
        },
        {
          title: '管理微信消息',
          click: (data)=>this.openDialog('WxMsgManage',data)
        }
      ],
    },
  ]

  public async created() {
    this.loadData()
    this.loadSystemConfig()
  }

  private async loadSystemConfig(){
    const result = await QuerySystemConfig({})
    this.systemConfig = result
  }

  public async loadData() {
    const param: any = { ...this.queryParam }
    
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QueryWxAccount(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private async wxdesable(data){
    // 禁用
    MessageBox.confirm('确定要禁用吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      const ruslt = await ChangeWxAccountStatus({id:data.id,status: 1})
      if (ruslt) {
        Message.success('禁用成功')
        this.loadData()
      }
    })
    
  }
  private async wxundesable(data){
    // 启用
    MessageBox.confirm('确定要启用吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      const ruslt = await ChangeWxAccountStatus({id:data.id,status: 0})
      if (ruslt) {
        Message.success('启用成功')
        this.loadData()
      }
    })
  }

  private async changeSystemConfig(item){
    await UpdateSystemConfigValue({id:item.id,configValue:item.configValue})
  }

}
