
















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import Conf from '@/config/config'
import API from '@/api/api'
import { getUser } from '@/store/userStore'
import { Message } from 'element-ui'
import draggable from 'vuedraggable'
import OSS from 'ali-oss'
import { DateFormat2 } from '@/utils/date/DateUtil'
import pdf from 'vue-pdf'

@Component({
  components: { draggable,pdf },
})
export default class VantImageUpload extends Vue {
  @Prop({ type: String, default: '' }) value!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Number, default: 3 }) private maxFileSize!: number
  @Prop({ type: String, default: 'image/*' }) accept!: string // 可上传的文件后缀，多个用','隔开

  private changeData() {
    const coverImg = this.fileListTemp.map((e: any, index) => {
      return e.url
    }).join(',')
    this.$emit('input',coverImg)
    return coverImg
  }

  private fileListTemp: Array<any> = []

  @Watch('value')
  private wfileListTemp(){
    // 初始化,后续变化不需要
    if(this.fileListTemp && this.fileListTemp.length != 0){
      return
    }
    if(this.value){
      const f = this.value.split(',').map((e: string) => {
        return { url: e }
      })
      this.fileListTemp.push(...f)
    }
  }

  // TODO 完善文件上传，支持sts
  private ossClient = new OSS({
    region: 'oss-cn-hangzhou',
    bucket: 'kangennuuo',
    accessKeyId: 'LTAI5tEc6mhu1LSkuYJcH9Ni',
    accessKeySecret: 'wxy6rNIt0UZOIQOHYZcc5KeIISYoDa',
    secure: true,
    timeout: 3600000,
  })

  private fileUploadUrl = Conf.API_HOST + API.fileUpload
  private headers = { Authorization: '' }
  private dialogVisible = false
  private showLoding = false
  private percentage = 0
  private dialogImageUrl = ''
  private videoType = ['mp4', 'avi', 'flv', 'mkv', '3gp', 'mov', 'asf', 'f4v', 'mpeg', 'mpg', 'wmv', 'rm', 'rmvb', 'ts']
  private pdfType = ['pdf']
  private dragOptions = {
    animation: 200,
    group: 'description',
    disabled: false,
    ghostClass: 'ghost',
    draggable: '.upload-images-img',
  }

  private dialogImageType(url: string) {
    if (!url) {
      return ''
    }
    const lastIndex = url.lastIndexOf('.')
    const suffix = url.substring(lastIndex + 1)
    const isVideo = this.videoType.findIndex((e) => e == suffix)
    const isPdf = this.pdfType.findIndex((e) => e == suffix)
    if (isVideo >= 0) {
      return 'video'
    } else if(isPdf >= 0){
      return 'pdf'
    }
    return 'img'
  }

  private datadragEnd(evt: any) {
    this.changeData()
  }

  private created() {
    this.headers.Authorization = getUser().token || ''
  }
  public close() {
    this.dialogVisible = false
    const video = this.$refs.video as any
    if (video) {
      video.pause()
    }
  }
  public handleRemove(file: any) {
    let fileUrl = file.url
    if (!fileUrl) {
      fileUrl = file.response.data.fileUrl
    }
    let index = -1
    for (let i = 0; i < this.fileListTemp.length; i++) {
      const f = this.fileListTemp[i]
      if (f === fileUrl || f.url === fileUrl) {
        index = i
      }
    }
    this.fileListTemp.splice(index, 1)
    this.changeData()
  }
  public handlePictureCardPreview(file: any) {
    this.dialogImageUrl = file.url
    this.dialogVisible = true
  }
  private dwonload(file:any){
    const url = file.url
    window.location.href = url
  }
  public beforeUpload(file: any) {
    // console.log('beforeUpload')
    if (this.fileListTemp.length >= this.maxFileSize) {
      Message.error('最多上传' + this.maxFileSize + '张图片')
      return false
    }
    this.showLoding = true
    this.percentage = 0
    return true
  }
  public uploadSuccess(response: any) {
    this.showLoding = false
    if (!response) {
      Message.error('上传失败')
      return
    }
    const { res, url } = response
    if (res.status != 200) {
      // 异常
      Message.error('上传失败')
      return
    }
    // this.fileListTemp.push({ url: url })
    this.changeData()
  }

  private async fileUpload(param: any,detail: any) {
    console.log('file')
    console.log(detail)
    console.log(param)
    const size = param.file.size
    if (size > 1024 * 1024) {
      console.log('分片上传')
      const result = await this.multipartUpload(param) 
      param.url = result.url
      detail.url = result.url
      this.uploadSuccess(result)
      return result.url
    } else {
      console.log('简单上传')
      const result = await this.simpleUpload(param)
      param.url = result.url
      detail.url = result.url
      this.uploadSuccess(result)
      return result.url
    }
  }

  // 简单的文件上传
  private async simpleUpload(param: any) {
    const file = param.file

    // 随机生成文件名
    const fileName = this.createFileName(file.name)

    const result = await this.ossClient.put(fileName, file)
    return result
  }

  // 生成文件名
  private createFileName(fileName: string) {
    // 获取文件后缀
    const lastIndex = fileName.lastIndexOf('.')
    const suffix = fileName.substring(lastIndex)

    // 随机生成文件名
    const fn = 'file/' + DateFormat2(new Date()) + '/' + this.randomStr(10) + suffix
    return fn
  }
  private randomStr(len: number) {
    len = len || 8
    const chars = '12345678ABCDEFGHIJKLMOPKRSTUVWXYZ'
    const maxPos = chars.length
    var str = ''
    for (let i = 0; i < len; i++) {
      str += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return str
  }

  // 分片上传
  private async multipartUpload(param: any) {
    const file = param.file
    // 随机生成文件名
    const fileName = this.createFileName(file.name)

    const options = {
      // 获取分片上传进度、断点和返回值。
      progress: (p: number, cpt: any, res: any) => {
        this.percentage = Number((p * 100).toFixed(0))
      },
      // 设置并发上传的分片数量。
      parallel: 4,
      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024,
    }
    // this.ossClient.multipartUpload(fileName, file, options).then((result: any)=>{
    //   console.log(result)
    // })
    const result = await this.ossClient.multipartUpload(fileName, file, options)
    console.log('分片上传完成')
    console.log(result)
    const baseUrl = 'https://t9zq.oss-cn-hangzhou.aliyuncs.com/'
    return { res: { status: 200 }, url: baseUrl + fileName }
  }
}
