
























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { CreatePlatformAccount, SupplierListForSelect, UpdatePlatformAccount } from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import constant from '@/utils/constant'

@Component({
  components: {
  },
})
export default class CreatePlatformDialog extends Vue {
  @Prop({type: Number,default: 1}) private role?: number
  private constant = constant
  private show = false
  private data: any = {}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    name: [this.requiredRule],
    account: [this.requiredRule],
    // role: [this.requiredRule],
    password: [this.requiredRule],
    supplierId: [this.requiredRule],
  }
  private supplierSelect= []

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data ={}
  }
  public open(param: any) {
    this.show = true
    this.supplierRmote("")
    if(param){
      this.data = {...param}
    } 
  }

  private async changeSupplier(e: any){
    const list = await SupplierListForSelect({id:e})
    this.data.supplierName = list.list[0].label
  }

  private async supplierRmote(query: any,id?: number) {
    const list = await SupplierListForSelect({name:query,id:id})
    list.list.forEach(e => {
      e.value = Number(e.value)
    });
    this.supplierSelect = list.list
  }

  public async save() {
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      const param: any = {...this.data}
      if(!param.id){
        
        if(this.role == 1){
          param.supplierId = null
          param.supplierName = null
        }else {
          param.role = this.role
        }
        await CreatePlatformAccount(param)
      } else {
        await UpdatePlatformAccount(param)
      }
      Message.success('保存成功')
      this.close()
    })
  }
}
