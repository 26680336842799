










































import { Component, Vue } from 'vue-property-decorator'
import { getUser } from '@/store/userStore'
import router from '@/router'
import store from '@/store'
import UpdatePwdDialog from './UpdatePwdDialog.vue'

@Component({ components: { UpdatePwdDialog } })
export default class Header extends Vue {
  private username = getUser().userName

  // private storeName = ''

  private get storeName() {
    const u = getUser()
    if (u.user.role == 1) {
      return '平台' + '-' + this.username
    } else {
      return u.user.supplierName + '-' + u.user.name
    }
  }
  private get isAdmin() {
    const u = getUser()
    return u.user.role == 1
  }
  private message = 0
  private get collapse() {
    return store.state.collapse
  }

  private toWork(){
    router.push('/work')
  }
  public created() {
    //   创建
    console.log('created')
    // this.getStoreName()
  }
  public activated() {
    console.log('activated')
  }
  public mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage()
    }
  }

  public collapseChage() {
    store.commit('handleCollapse', !store.state.collapse)
  }
  public handleCommand(command: string) {
    if (command == 'loginout') {
      localStorage.removeItem('userInfo')
      router.push('/login')
    } else if (command == 'updatePwd') {
      const d = this.$refs.UpdatePwdDialog as UpdatePwdDialog
      d.open()
    } else if (command == 'account') {
      router.push('/accountCenter')
    }
  }
}
