



















import { Component, Vue, Emit } from 'vue-property-decorator'
import { SubmitSuppliyPriceAudit,QuerySuppliyPriceAudit} from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import constant from '@/utils/constant'
import { timeFormate } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'

@Component({
  components: {
  },
})
export default class UpdateSuppliyPrice extends Vue {
  private constant = constant
  private show = false
  private data: any = {batchNum:"",suppliyPrice:0}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    suppliyPrice: [this.requiredRule]
  }
  private currentAudit:any =  {}

  private get role() {
    return getRole();
  }

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {batchNum:"",suppliyPrice:0}
    this.currentAudit = {}
  }
  public open(param: any) {
    this.show = true
    this.data.drugId = param.id
    this.data.drugCode = param.drugCode
    this.data.drugName = param.drugName
    this.data.suppliyPrice = param.suppliyPrice
    const d = new Date()
    this.loadSuppliyPriceAudit()
    // this.data.batchNum = "B"+timeFormate(new Date(),"YYMMDDHHmmss")
    
  }

  private async loadSuppliyPriceAudit(){
    const a = await QuerySuppliyPriceAudit({drugId:this.data.drugId,status: 0})
    if(a.list.length > 0){
      this.currentAudit = a.list[0]
    } else {
      this.currentAudit = {}
    }

    console.log(a)
    console.log(a.list[0])
    console.log(this.currentAudit)
    
  }

  public async save() {
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      const param  = {
        drugId: this.data.drugId,
        suppliyPrice: this.data.suppliyPrice
      }
      await SubmitSuppliyPriceAudit(this.data)
      Message.success('保存成功')
      this.close()
    })
  }
}
