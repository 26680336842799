












import { Component, Vue } from 'vue-property-decorator'
import store from '@/store'
import router from '@/router'
// import { Tabbar, TabbarItem } from 'vant';
import {saveWxMpCode} from '@/store/wxMpCode'

@Component({
  components: {
  },
})
export default class H5Layout extends Vue {
  private active = 0  
  private get tagsList(){
    return store.state.tagsList.map((item) => item.name)
  }
  private get collapse(){
    return store.state.collapse
  }

  public created() {
   // 获取微信token
    const code: any = router.currentRoute.query.code
    if(code){
      // 保存到缓存
      saveWxMpCode(code)
    }
  }

}
