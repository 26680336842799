



































































import { Component, Vue } from 'vue-property-decorator'
import { getRole } from '@/store/userStore'
import Icon from './components/Icon.vue'
import NumberItem from './components/NumberItem.vue'
import router from '@/router'
import { QueryDrugBatchLog, QueryIndexData,QueryLog,QueryDrugStock, QuerySystemConfigByKey } from '@/api'
import * as echarts from 'echarts'
import { dateFormat } from '@/plugins/format'
import { DateFormat, timeFormate } from '@/utils/date/DateUtil'
import DrugBatchLogItem from '@/views/drug/componets/DrugBatchLogItem.vue'

@Component({
  components: {
    Icon,
    NumberItem,
    DrugBatchLogItem
  },
})
export default class Work extends Vue {
  private get role() {
    return getRole();
  }

  private indexData:any = {}

  public async created() {
    // this.loadData()
    // await this.loadIndexData()
    this.loadStockOffline()
  }
  private async loadStockOffline(){
    const sc = await QuerySystemConfigByKey({configKey: 'stockOffline'})
    this.stockLe = Number(sc.configValue)
  }
  // public mounted(){

  // }
  public activated(){
    // 激活页面时触发
    this.loadIndexData()
  }

  private async loadIndexData(){
    this.indexData = await QueryIndexData({})

    this.loadSalesCarts()
  }
  
  private salesEchart
  private loadSalesCarts(){
    // 销售单统计图表
    const doc = document.getElementById('salesCarts')
    if (doc == null) {
      return
    }
    let echart = this.salesEchart
    if(!echart){
      echart = echarts.init(doc)
    }

    const {salesChartData} = this.indexData

    const xAxisData = this.initXAxisData()
    // 处理数据，如果是
    
    const data = xAxisData.map(e=>{
      const ss = salesChartData.filter(s=>s.date == e)
      if(ss.length > 0){
        return ss[0]
      }
      return {date:e,salesOrderCount:0,salesDrugCoung:0,salesAmount:0}
      
    })

    const option = {
        tooltip: { trigger: 'axis' },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
              interale: 0,
              rotate: 0, //设置日期显示样式（倾斜度）
              formatter: function (value) {//在这里写你需要的时间格式
                var t_date = new Date(value);
                return [ t_date.getMonth() + 1, t_date.getDate()].join('-')
                // + " " + [t_date.getHours(), t_date.getMinutes()].join(':'); 时分
              }
            }
        },
        yAxis: {},
        legend:{show:true},
        dataZoom: {
          // 缩放
          start: data.length > 30? 80:0,
          type: 'inside'
        },
        series: [
          {
            type: 'line',
            name: '销售单',
            encode: {
              // 将 "amount" 列映射到 X 轴。
              x: 'date',
              // 将 "product" 列映射到 Y 轴。
              y: 'salesOrderCount'
            }
          },
          {
            type: 'line',
            name: '药品数量',
            encode: {
              // 将 "amount" 列映射到 X 轴。
              x: 'date',
              // 将 "product" 列映射到 Y 轴。
              y: 'salesDrugCoung'
            }
          },
          {
            type: 'line',
            name: '销售金额',
            encode: {
              // 将 "amount" 列映射到 X 轴。
              x: 'date',
              // 将 "product" 列映射到 Y 轴。
              y: 'salesAmount'
            }
          }
        ],
        dataset: {
          source: data
        }
      }


    echart.setOption(option)
    this.salesEchart = echart
    
    window.addEventListener('resize', this.salesEchartResize)
  }

  private salesEchartResize(){
    console.log('res')
    if(this.salesEchart){
      this.salesEchart.resize()
    }
  }

  public beforeDestroy(){
    console.log('beforeDestroy ')
    window.removeEventListener('resize',this.salesEchartResize)
  }

  private initXAxisData(){
    const data: Array<string> = []

    for(let i = 9 ; i >=0 ; i--){
      const nd = new Date()
      nd.setDate(nd.getDate()-i); 
      data.push(timeFormate(nd,"YYYY-MM-DD"))
    }

    return data;
  }

  public jumpToUrl(path: string){
    console.log(path)
    router.push(path)
  }

  public openUrl(url: string){
    window.open(url,"_blank")
  }

  private barchLogPage = 1
  private drugBarchLogData:Array<any> = []
  private async loadBatchLog(){
    if(this.barchLogPage == -1){
      return
    }
    const param: any = {}
    param.startRow = (this.barchLogPage - 1) * 10
    const d = await QueryDrugBatchLog(param)
    const list: Array<any> = d.list
    list.forEach((e:any) => {
      e.createTime = DateFormat(e.createTime)
    });

    if(param.startRow == 0){
      this.drugBarchLogData = []
    }
    
    this.drugBarchLogData.push(...list)
    if(d.hasNextPage){
      this.barchLogPage = this.barchLogPage+1
    } else {
      this.barchLogPage = -1
    }
  }

  private systemLogPage = 1
  private systemLogData:Array<any> = []
  private async loadSystemLog(){
    if(this.systemLogPage == -1){
      return
    }
    const param: any = {logType:'option',supplierId:0}
    param.startRow = (this.systemLogPage - 1) * 10
    const d = await QueryLog(param)
    const list: Array<any> = d.list
    list.forEach((e:any) => {
      e.createTime = DateFormat(e.createTime)
    });

    if(param.startRow == 0){
      this.systemLogData = []
    }
    
    this.systemLogData.push(...list)
    if(d.hasNextPage){
      this.systemLogPage = this.systemLogPage+1
    } else {
      this.systemLogPage = -1
    }
  }

  
  private stockPage = 1
  private stockData:Array<any> = []
  private stockLe = 100; // 预警阀值
  private async loadStock(){
    if(this.stockPage == -1){
      return
    }
    const param: any = {stockLe: this.stockLe,status: 2}
    param.startRow = (this.stockPage - 1) * 10
    const d = await QueryDrugStock(param)
    const list: Array<any> = d.list
    

    if(param.startRow == 0){
      this.stockData = []
    }
    
    this.stockData.push(...list)
    if(d.hasNextPage){
      this.stockPage = this.stockPage+1
    } else {
      this.stockPage = -1
    }
  }

  
}
