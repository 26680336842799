












import { Component, Vue, Prop } from 'vue-property-decorator'
import constant from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import { DateFormat } from '@/utils/date/DateUtil'

@Component({
  components: {
   
  },
})
export default class DrugBatchLogItem extends Vue {
  @Prop(Object) private data: any
  private DateFormat = DateFormat
  private formatterType(type:number ){
    return StatusFormat(constant.DrugBatchLogType,type)
  }
}
