















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

export interface TableOperation {
  title: string
  icon?: string
  type?: string
  click?(data: any): void
  class?: string // class名称
  style?: any
  disable?(data: any): boolean
  show?(data: any): boolean // 是否显示
}

export interface TableHander {
  title: string
  param?: string
  type?: string
  width?: number
  overflow?: boolean // 内容过长是否隐藏 默认： true
  formatter?(cellValue: any, row: any ,index: number): any //用来格式化内容 Function(row, column, cellValue, index)
  operation?: Array<TableOperation>
  click?(cellValue: any, row: any , index: number): any // 点击事件，入参：当前值，当前行，index
  fixed?: boolean|'left'|'right' // 固定列
  show?(data: any): boolean // 是否显示
  slotName?: string
}

@Component({
  components: {},
})
export default class Table extends Vue {
  @Prop(Number) private total!: number
  @Prop({type: Number,default: 10}) private pageSize!: number
  @Prop(Array) private tableHander!: TableHander
  @Prop(Array) private data!: Record<string, any>
  @Prop({ type: Boolean, default: true }) private pagination!: boolean // 是否展示分页
  @Prop({type: String,default: "mini"}) private size!: 'medium'|'small'|'mini'
  @Prop({type: String,default: ""}) private height!: string
  @Prop({type: Boolean,default: false}) private showSummary!: boolean
  @Prop(Function) private summaryMethod!: () => void
  
  @Emit('pageChange')
  private pageChange(index: number) {
    return index
  }
  @Emit('pageSizeChange')
  private sizeChange(index: number){
    this.$emit('update:pageSize',index)
    return index
  }

  @Emit('selectionChange')
  private selectionChange(val:any) {
    return val
  }

  private imgShow = false;
  private dialogImageUrl = "";
  private showImg(url){
    if(url){
      this.dialogImageUrl = url;
      this.imgShow = true
    }
  }
  // /**
  //  * 求和函数
  //  */
  // @Emit('summaryMethod')
  // private summaryMethod(param: any){
  //   return param
  // }

  private isShowOption(to: TableOperation, row: any) {
    if (to.show) {
      return to.show(row)
    } else {
      return true
    }
  }
  private isShowItem(to: TableHander, row: any) {
    if (to.show) {
      return to.show(row)
    } else {
      return true
    }
  }
}
