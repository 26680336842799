














import { Component, Vue } from 'vue-property-decorator'
import { QueryDrugBatch } from '@/api'
import { getRole } from '@/store/userStore'
import router from '@/router'
import DrugBatchItem from './componets/DrugBatchItem.vue'

@Component({
  components: {
   DrugBatchItem
  },
})
export default class ManageDrugBatch extends Vue {
  private queryParam: any = {
    batchNum: "",
    stockGt: 0,
  }
  private selectValue = ""
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private supplierSelect= []
  private drugName:any = ""
  private get role() {
    return getRole();
  }
  private drugCode = ""

  public async created() {

    const drugCode = router.currentRoute.query.drugCode
    this.drugName = router.currentRoute.query.name

    this.queryParam.drugCode = drugCode
    this.loadData()
  }

  public refresh(){
    this.tableCurrntPage = 1
    const sc = this.$refs.scroller as any
    sc.finishInfinite(true)
    this.loadData()
  }
  public infinite(){
    this.tableCurrntPage = this.tableCurrntPage+1
    this.loadData()
  }

  public async loadData() {
    const param:any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * 10
    const result = await QueryDrugBatch(param)
    this.tableTotal = result.total
    this.tableData = result.list

    const sc = this.$refs.scroller as any
    sc.finishPullToRefresh(true)
    if(!result.hasNextPage){
      sc.finishInfinite(true)
    }
  }


  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private addBatch(){
    router.push("/h5/addDrugBatch?drugCode="+ this.queryParam.drugCode)
  }

  
}
