












import { Component, Vue, Emit } from 'vue-property-decorator'
import { UpdateDrugBatch as UpdateDrugBatchApi, QueryDrugBatchById, PutOrOutStorage} from '@/api'
import {  Message } from 'element-ui'
import { Form, Dialog } from 'vant';
import constant from '@/utils/constant'
import { timeFormate,DateFormat2 } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'
import router from '@/router';

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
  },
})
export default class PutDrugBatch extends Vue {
  private constant = constant
  private show = false
  private data: any = {}
  private requiredRule = { required: true, message: '不能为空', trigger: 'onBlur' }
  
  private showSelectProdDate = false
  private showSelectValidity = false
  private currentProdDate = new Date()
  private currentValidity = new Date()
  private supplierSelect = []
  private get role() {
    return getRole();
  }
  private drugInfo = {}

  public async created() {
    const id = router.currentRoute.query.id
    const d = await QueryDrugBatchById({id: id})
    this.data = {}
    this.data.batchNum = d.batchNum
    this.data.batchId = d.id
    this.data.type = 0
    this.data.price = d.price
  }

  public async save(){
    const f = this.$refs.form as Form
    await f.validate()
    
    const id = await PutOrOutStorage(this.data)
    this.data.id = id;
    Message.success('入库完成')
    router.back()
  }
  
}
