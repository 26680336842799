











import { Component, Vue,Prop, Emit } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class Icon extends Vue {
  @Prop(String)private icon!:string
  @Prop(String)private text!:string
  private i = require('@/assets/img/img.jpg')

  @Emit('click')
  private click(){
    return
  }

  private async imgSrc(src: string){
    if(src.includes('http')){
      return src
    } else {
      const c = '@/assets/img/img.jpg'
      const ii = await import(c)
      return ii
    }
  }
  
}
