


































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { BatchUpdateOrSaveDrug } from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import Rules from '@/utils/rules'
import Conf from '@/config/config'
import API from '@/api/api'
import { getUser } from '@/store/userStore'
import router from '@/router'
import { VTable, TableHander } from '@/components/table'
import { StatusFormat } from '@/utils/tyepconvert'
import constan from '@/utils/constant'

@Component({
  components: {
    VTable,
  },
})
export default class ImportDrugDialog extends Vue {
  @Emit('close')
  private close() {
    this.show = false
  }
  private loading;
  private show = false
  private data = {
    importData: [],
    importErrorMsgs: [],
  }
  private fileUploadUrl = Conf.API_HOST + API.importDrug
  private headers = { Authorization: '' }
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '序号', param: 'id', type: 'index' },
    { title: '产品编号', param: 'drugCode' },
    { title: '供应商', param: 'supplierName' },
    { title: '产品名称', param: 'drugName' },
    { title: '供货价', param: 'suppliyPrice',width: 80 },
    { title: '售价', param: 'price',width: 80 },
    { title: '单位', param: 'unit' ,width: 60},
    { title: '规格', param: 'pack' },
    { title: '生产厂家', param: 'factory' },
    { title: '批准文号', param: 'approval' },
    { title: '经营类别', param: 'busiType' },
    { title: '库存', param: 'stock' ,width: 60},
    { title: '批号', param: 'batchNum' },
    { title: '生产日期', param: 'prodDate' },
    { title: '保质期', param: 'validity' },
    { title: '批次数量', param: 'batchCount' },
    { title: '状态', param: 'status', formatter: value=>StatusFormat(constan.DrugApptType,value) },
  ]

  private created() {
    this.headers.Authorization = getUser().token || ''
  }

  public uploadSuccess(response: any) {
    const { data, code, msg } = response
    if (code == 1300004) {
      // 未登录
      Message.error(msg)
      router.push('/login')
      return
    } else if (code != 10000) {
      // 异常
      console.log('sas')
      Message.error(msg)
      return
    }

    this.data = data
  }
  public open() {
    this.show = true
    this.data = { importData: [], importErrorMsgs: [] }
  }

  public async save() {
    console.log('保存')
    const { importData, importErrorMsgs } = this.data
    if (importErrorMsgs.length > 0) {
      Message.error('文档存在错误信息，请先调整')
      return
    }
    if (importData.length <= 0) {
      Message.error('请先上传文档，并确保存在数据')
      return
    }
    this.openLoading()
    const result = await BatchUpdateOrSaveDrug(this.data)
    this.closeLoading()
    if(result){
      this.close()
    }
    
  }
  /**
   * 打开加载效果
   */
  private openLoading() {
    this.closeLoading()
    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    setTimeout(() => {
      this.closeLoading();
    }, 600000);
  }
  private closeLoading(){
    if(this.loading){
      this.loading.close();
    }
    
  }
}
