// 默认路由
import { RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'

/**
 * 路由
 */
const routeConfig: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: Login,
  },
  {
    path: '*',
    redirect: '/login',
  },
]

/**
 * 菜单
 */
const menu = [{

}]

const r = {
  routeConfig,
  menu
}
export default r