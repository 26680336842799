













import { Component, Vue } from 'vue-property-decorator'
import { VHeader, VTags, VSidebar } from '@/components/home'
import store from '@/store'

@Component({
  components: {
    VHeader,
    VTags,
    VSidebar,
  },
})
export default class ManageLayout extends Vue {
  private get tagsList(){
    return store.state.tagsList.map((item) => item.name)
  }
  private get collapse(){
    return store.state.collapse
  }

}
