


























import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {  UploadInvoice as UploadInvoiceAPI} from '@/api'
import constant from '@/utils/constant'
import { getRole } from '@/store/userStore'
import { StatusFormat } from '@/utils/tyepconvert'
import copy from '@/utils/copy'
import { ElForm } from 'element-ui/types/form'
import { VImageUpload } from '@/components/upload'
import { Message } from 'element-ui'

@Component({
  components: {
    VImageUpload
  },
})
export default class UploadInvoice extends Vue {
  // 是否是审核
  @Prop({type: Boolean,default: false}) private audit!: boolean
  private constant = constant
  private show = false
  private data: any = {}
  
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    batchNum: [this.requiredRule],
    validity: [this.requiredRule],
    prodDate: [this.requiredRule],
    stock: [this.requiredRule],
  }
  
  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {}
    
  }
  public open(param: any) {
    this.show = true
    this.data = param
    // key
    
  }

  
  private async save(){
    // 整理保存的数据
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      const param = {
        id: this.data.id,
        invoiceCode: this.data.invoiceCode,
        invoiceNo: this.data.invoiceNo,
        securityCode: this.data.securityCode,
        invoiceUrl: this.data.invoiceUrl,
      }
      await UploadInvoiceAPI(param)
      Message.success('上传成功')
      this.close()
    })
  }
}
