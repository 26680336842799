










import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { ExportSalesOrder, ExportSupplierSalesOrder, QueryPartnerShopForSelect, QuerySalesOrder2, SupplierListForSelect } from '@/api'
import { timeFormate, DateFormat } from '@/utils/date/DateUtil'
import constant from '@/utils/constant'
import { PriceFormat, StatusFormat } from '@/utils/tyepconvert'
import { getRole } from '@/store/userStore'
import CreateSales from './componets/CreateSales.vue'

@Component({
  components: {
    VQuery,
    VTable,
    CreateSales
  },
})
export default class Sales extends Vue {
  private queryParam: any = {
    startRow: 1,
    limit:10
  }
  private tableTotal = 0
  private tableData = []
  private tableStatisticsData: any = {}
  private tableCurrntPage = 1
  private supplierSelect= []
  private shopSelect= []
  private get role() {
    return getRole();
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '供应商', type:"select", param: 'supplierId', selectData: this.supplierSelect,remoteMethod: this.supplierRmote , show: ()=> this.role != 2},
    { title: '店铺', type:"select", param: 'partnerShopId', selectData: this.shopSelect , show: ()=> this.role != 2},
    { title:'订单编号', param: "orderCode"},
    { title:'平台订单编号', param: "orderId"},
    { title:'产品名称', param: "drugName"},
    { title:'产品编号', param: "drugCode"},
    {title:'创建时间', param: 'createTimeScope', type:'daterange'},
  ]
  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    { title: '导出销售单', type: 'primary', click: () => {
      const param: any = { ...this.queryParam }
      param.orderBy = "create_time desc"
      if(param.createTimeScope && param.createTimeScope.length==2){
        param.createTime = timeFormate(param.createTimeScope[0])+" 00:00:00,"+timeFormate(param.createTimeScope[1])+" 23:59:59"
      }
      if(this.role != 2){
        ExportSalesOrder(param)
      } else {
        ExportSupplierSalesOrder(param)
      }
    } },{ title: '创建销售单', type: 'primary',show: ()=>this.role != 2, click: ()=>this.openDialog("CreateSales")},
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '序号', param: 'id', type: "index", width: 50},
    { title: '订单编号', param: 'orderCode', width: 100 },
    { title: '平台订单编号', param: 'orderId', width: 120 },
    // { title: '供应商ID', width: 80,param: 'supplierId' },
    { title: '类型', width: 50,param: 'salesType', formatter: (value)=>value == 0? '销售':'退款' },
    {title: '店铺', param: 'partnerShopId', width: 80, formatter: this.formShop, overflow: true, show: ()=>this.role != 2 },
    { title: '供应商名称', width: 100,param: 'supplierName', show: ()=>this.role != 2 },
    { title: '产品图片',width: 80, param: 'image1',slotName:'img'},
    { title: '产品编号', param: 'drugCode', width: 100},
    { title: '产品名称', param: 'drugName'},
    { title: '规格', param: 'pack'},
    { title: '单位', param: 'unit', width: 60}, 
    { title: '供货价', param: 'suppliyPrice', width: 60},
    { title: '售价', param: 'price', width: 60, show: (data)=>this.role != 2},
    { title: '数量', param: 'newQty', width: 60,formatter: (value,row)=>{
      if(row.salesType == 1){
        return '-'+value
      }
      return value
    }},
    { title: '供货金额', param: 'suppliyTotalPrice', width: 80,formatter: (value,row)=>{
      if(row.salesType == 1){
        return '-'+value
      }
      return value
    } , show: (data)=>this.role!=2},
    { title: '销售额', param: 'newAmount', width: 80,formatter: (value,row)=>{
      if(row.salesType == 1){
        return '-'+value
      }
      return value
    }, show: (data)=>this.role != 2},
    { title: '总金额', param: 'suppliyTotalPrice', width: 80,formatter: (value,row)=>{
      if(row.salesType == 1){
        return '-'+value
      }
      return value
    } , show: (data)=>this.role==2},
    { title: '批次号', param: 'batchNum', width: 120 },
    // { title: '有效期', param: 'validity', width: 120 },
    // { title: '生产日期', param: 'prodDate', width: 120 },
    // { title: '批次数量', param: 'batchCount', width: 120 },
    { title: '下单时间', param: 'createTime', width: 160, formatter: DateFormat },
    { title: '备注', param: 'note', width: 160,overflow:true },
  ]

  public async created() {
    // 平台角色，则加载供应商
    if(this.role != 2){
      this.supplierRmote("")
      this.shopRmote("")
    }
    this.loadData()
  }

  public async loadData() {
    const param: any = { ...this.queryParam }
    param.orderBy = "create_time desc"
    if(param.createTimeScope && param.createTimeScope.length==2){
      param.createTime = timeFormate(param.createTimeScope[0])+" 00:00:00,"+timeFormate(param.createTimeScope[1])+" 23:59:59"
    }
    
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QuerySalesOrder2(param)
    const salesOrderData = result.salesOrderData
    this.tableTotal = salesOrderData.total
    this.tableData = salesOrderData.list

    // 统计
    this.tableStatisticsData = {
      totalNewQty: result.totalNewQty,
      totalNewAmount: result.totalNewAmount,
      suppliyTotalPrice: result.suppliyTotalPrice,
    }
  }

  private summaryMethod(param:any){
    const {columns} = param
    const sums: Array<any> = []
    columns.forEach((column, index) => {
      if(index == 1){
        sums[index] = "当前合计"
      } else if (column.property == "newQty"){
        sums[index] = this.tableStatisticsData.totalNewQty
      } else if (column.property == "newAmount"){
        sums[index] = this.tableStatisticsData.totalNewAmount
      } else if (column.property == "suppliyTotalPrice"){
        sums[index] = this.tableStatisticsData.suppliyTotalPrice
      } else {
        // sums[index] = 'N/A'
      }
    });
    return sums
  }

  private async supplierRmote(query: any,id?: number) {
    const list = await SupplierListForSelect({name:query,id:id})
    this.supplierSelect = list.list
    this.queryModels[0].selectData = this.supplierSelect
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private async shopRmote(query: any,id?: number) {
    const list = await QueryPartnerShopForSelect({name:query,id:id})
    this.shopSelect = list.list
    this.queryModels[1].selectData = this.shopSelect
  }

  private formShop(value: string){
    let result = ""
    this.shopSelect.forEach((s:any)=>{
        if(s.value == value){
          result = s.label
          return
        }
      })
    return result
  }

  private exportSalesOrderDisable(){
    return !this.queryParam.supplierId
  }
  
}
