









































import { Component, Vue, Emit } from 'vue-property-decorator'
import {  QueryOrderDetails, QueryDrug,CreateSalesOrder, QueryDrugByCode, QueryOrderDetailsById} from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import constant from '@/utils/constant'
import { timeFormate } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'
import InputRestrict from '@/utils/InputRestrict'

@Component({
  components: {
  },
})
export default class CreateSalesDialog extends Vue {
  private constant = constant
  private InputRestrict = InputRestrict
  private show = false
  private data: any = {drugCode:"",salesType: 0,drug:{},orderDetails:{}}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private dateRule = { validator: this.prodDateRule,trigger: 'blur' }
  private batchCountRule2 = { validator: this.batchCountRule,trigger: 'blur' }
  private batchCountAccordRulev = { validator: this.batchCountAccordRule,trigger: 'blur' }
  private rules = {
    orderDetailsId: [this.requiredRule],
    drugCode: [this.requiredRule],
    newQty: [this.requiredRule],
    salesType: [this.requiredRule],
    batchNum: [this.requiredRule,{ validator: this.batchNumRule,trigger: 'blur' }],
    prodDate: [this.requiredRule,this.dateRule,this.batchCountAccordRulev],
    validity: [this.requiredRule,this.dateRule,this.batchCountAccordRulev],
    batchCount: [this.requiredRule,this.batchCountRule2,this.batchCountAccordRulev],
    
    // partnerShopIds: [this.requiredRule],
  }
  private orderSelect: Array<any> = []
  private drugSelect: Array<any> = []

  private get role() {
    return getRole();
  }
  private salesPriceRatio = 1;

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {drugCode:"",salesType: 0,drug:{},orderDetails:{}}
  }
  public created() {
    console.log('')
  }
  public async open(param: any) {
    this.show = true
    
  }
  private dateReg = /^\d{4}-\d{2}-\d{2}(,\d{4}-\d{2}-\d{2})*$/
  private numReg = /^\d+(,\d+)*$/
  private prodDateRule(rule, value, callback){
    if (value === '') {
      callback();
    } else {
      if(!this.dateReg.test(value)){
        callback(new Error('日期格式正确，正确因为 2023-05-27 如有多个用,隔开'));
      }
      callback();
    }
  }
  private batchCountAccordRule(rule, value, callback){
    if (value === '') {
      callback();
    } else {
      const  bnc = this.data.batchNum ? this.data.batchNum.split(",").length : 0
      const vc = value.split(",").length
      if(bnc != vc){
        callback(new Error('数量与比批次个数不一致，当前批次个数为'+bnc));
      }
      callback();
    }
  }
  private batchNumRule(rule, value, callback){
    if (value === '') {
      callback(new Error('批次信息为空'));
    } else {
      //触发其他校验
      const f = this.$refs.form as ElForm
      f.validateField('prodDate')
      f.validateField('validity')
      f.validateField('batchCount')

      callback();
    }
  }
  private batchCountRule(rule, value, callback){
    if (value === '') {
      callback();
    } else {
      const vc = value.split(",")
      console.log(value)
      console.log(vc)
      let sum = 0;
      vc.forEach(e => {
        sum += Number(e)
      });
      if(this.data.newQty){
        if(sum > this.data.newQty){
          callback(new Error('批次总数量大于销售单的数量'));
        }
      }
      callback();
    }
  }
  

  private async orderRmote(query: any) {
    const list = await QueryOrderDetails({orderCode: query})
    const select: Array<any>  = []
    list.list.forEach(e=>{
      const s = {
        value: e.id,
        label: "【"+e.id+"-订单（"+e.orderCode+"）】"+e.drugName
      }
      select.push(s)
    })
    this.orderSelect = select
  }
  private async changeOrder(e: any){
    const od = await QueryOrderDetailsById({id: e})
    this.data.orderDetails = od
  }

  private async drugRmote(query: any,id?: number) {
    const list = await QueryDrug({drugName:query,status: 2})
    const select: Array<any>  = []
    list.list.forEach(e=>{
      const s = {
        value:  e.drugCode,
        label: "【"+e.drugCode+"-"+e.supplierName+"】"+e.drugName+"【供货价（"+e.suppliyPrice+"）】"
      }
      select.push(s)
    })
    this.drugSelect = select
  }
  private async changeDrug(e: any, e2:any){
    console.log(e)  
    const d = await QueryDrugByCode({drugCode: e})
    this.data.drug = d
    console.log(d)
  }

  public async save(){
    console.log(this.data)
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      const param = {...this.data}
      param.orderCode  = param.orderDetails.orderCode
      param.drug = null
      param.orderDetails = null
      console.log(param)

      const isSuccess = await CreateSalesOrder(param)
      if(isSuccess){
        Message.success('保存成功')
        this.close()
      }else {
        Message.error('保存失败')
      }
    })
  }
 
}
