import { CommonExportGet, CommonFetchPost } from './request'
import API from './api'

/***** 账号相关 ******/
/**
 * 平台账号登录
 * @param  data
 * @returns
 */
export const LoginByEmail = async (data: any) => {
  return await CommonFetchPost(API.loginByEmail, data)
}
/**
 * 平台账号登录
 * @param  data
 * @returns
 */
export const LoginByAccount = async (data: any) => {
  return await CommonFetchPost(API.loginByAccount, data)
}

/**
 * 修改密码（平台登录账号）
 * @param  data
 * @returns
 */
export const UpdatePlatformAccountPassword = async (data: any) => {
  return await CommonFetchPost(API.updatePlatformAccountPassword, data)
}

/**
 * 查询平台账号列表
 * @param  data
 * @returns
 */
export const QueryPlatformAccountList = async (data: any) => {
  return await CommonFetchPost(API.queryPlatformAccountList, data)
}
/**
 * 创建平台登录账号
 * @param  data
 * @returns
 */
export const CreatePlatformAccount = async (data: any) => {
  return await CommonFetchPost(API.createPlatformAccount, data)
}
/**
 * 创建平台登录账号
 * @param  data
 * @returns
 */
export const UpdatePlatformAccount = async (data: any) => {
  return await CommonFetchPost(API.updatePlatformAccount, data)
}
/**
 * 重置密码（平台端）
 * @param  data
 * @returns
 */
export const ResetPasswordsForPlatformAccount = async (data: any) => {
  return await CommonFetchPost(API.resetPasswordsForPlatformAccount, data)
}
/**
 * 变更账号状态
 * @param  data
 * @returns
 */
export const ChangePlatformAccountStatus = async (data: any) => {
  return await CommonFetchPost(API.changePlatformAccountStatus, data)
}
/**
 * 平台账号登录（wxcode）
 * @param  data
 * @returns
 */
export const LoginByWxCode = async (data: any) => {
  return await CommonFetchPost(API.loginByWxCode, data)
}
/**
 * 退出登录（wxcode）
 * @param  data
 * @returns
 */
export const LoginOutByWxCode = async (data: any) => {
  return await CommonFetchPost(API.loginOutByWxCode, data)
}
/**
 * 退出登录（wxcode）
 * @param  data
 * @returns
 */
export const DeletePlatformAccountStatus = async (data: any) => {
  return await CommonFetchPost(API.deletePlatformAccountStatus, data)
}

/***** Banner ******/
/**
 * 查询banner
 * @param  data
 * @returns
 */
 export const QueryBannerList = async (data: any) => {
  return await CommonFetchPost(API.queryBannerList, data)
}
/**
 * 添加banner
 * @param  data
 * @returns
 */
export const AddBanner = async (data: any) => {
  return await CommonFetchPost(API.addBanner, data)
}
/**
 * 修改banner顺序
 * @param  data
 * @returns
 */
export const ChangeBannersStatus = async (data: any) => {
  return await CommonFetchPost(API.changeBannersStatus, data)
}
/**
 * 删除banner
 * @param  data
 * @returns
 */
export const DeleteBanner = async (data: any) => {
  return await CommonFetchPost(API.deleteBanner, data)
}
/**
 * 修改banner
 * @param  data
 * @returns
 */
export const UpdateBanner = async (data: any) => {
  return await CommonFetchPost(API.updateBanner, data)
}

/***** 宣传页 ******/
/**
 * 查询宣传页列表
 * @param  data
 * @returns
 */
export const QueryBrochureList = async (data: any) => {
  return await CommonFetchPost(API.queryBrochureList, data)
}
/**
 * 通过id查询宣传页
 * @param  data
 * @returns
 */
export const QueryBrochureById = async (data: any) => {
  return await CommonFetchPost(API.queryBrochureById, data)
}
/**
 * 创建宣传页
 * @param  data
 * @returns
 */
export const CreateBrochure = async (data: any) => {
  return await CommonFetchPost(API.createBrochure, data)
}
/**
 * 修改宣传页
 * @param  data
 * @returns
 */
export const UpdateBrochure = async (data: any) => {
  return await CommonFetchPost(API.updateBrochure, data)
}
/**
 * 删除宣传页
 * @param  data
 * @returns
 */
export const DeleteBrochure = async (data: any) => {
  return await CommonFetchPost(API.deleteBrochure, data)
}

/***** 系统日志 ******/
/**
 * 查询日志
 * @param  data
 * @returns
 */
export const QueryLog = async (data: any) => {
  return await CommonFetchPost(API.queryLog, data)
}

/***** 供应商 ******/
/**
 * 供应商列表
 * @param  data
 * @returns
 */
export const SupplierList = async (data: any) => {
  return await CommonFetchPost(API.supplierList, data)
}
/**
 * 供应商列表
 * @param  data
 * @returns
 */
export const SupplierListForSelect = async (data: any) => {
  return await CommonFetchPost(API.supplierListForSelect, data)
}
/**
 * 供应商-新增
 * @param  data
 * @returns
 */
export const CreateSupplier = async (data: any) => {
  return await CommonFetchPost(API.createSupplier, data)
}
/**
 * 供应商-更新
 * @param  data
 * @returns
 */
export const UpdateSupplier = async (data: any) => {
  return await CommonFetchPost(API.updateSupplier, data)
}
/**
 * 供应商-删除
 * @param  data
 * @returns
 */
export const DeleteSupplierById = async (data: any) => {
  return await CommonFetchPost(API.deleteSupplierById, data)
}

/***** 药品 ******/
/**
 * 查询药品统计
 * @param  data
 * @returns
 */
export const DrugStatistics = async (data: any) => {
  return await CommonFetchPost(API.drugStatistics, data)
}
/**
 * 查询药品
 * @param  data
 * @returns
 */
export const QueryDrug = async (data: any) => {
  return await CommonFetchPost(API.queryDrug, data)
}
/**
 * 查询药品库存
 * @param  data
 * @returns
 */
export const QueryDrugStock = async (data: any) => {
  return CommonFetchPost(API.queryDrugStock, data)
}
/**
 * 查询药品
 * @param  data
 * @returns
 */
export const QueryDrugById = async (data: any) => {
  return await CommonFetchPost(API.queryDrugById, data)
}
/**
 * 查询药品
 * @param  data
 * @returns
 */
export const QueryDrugByCode = async (data: any) => {
  return await CommonFetchPost(API.queryDrugByCode, data)
}
/**
 * 添加药品
 * @param  data
 * @returns
 */
export const AddDrug = async (data: any) => {
  return await CommonFetchPost(API.addDrug, data)
}
/**
 * 更新药品
 * @param  data
 * @returns
 */
export const UpdateDrug = async (data: any) => {
  return await CommonFetchPost(API.updateDrug, data)
}
/**
 * 提交审核
 * @param  data
 * @returns
 */
export const SubmitAuditDrug = async (data: any) => {
  return await CommonFetchPost(API.submitAuditDrug, data)
}
/**
 * 审核药品
 * @param  data
 * @returns
 */
export const AuditDrug = async (data: any) => {
  return await CommonFetchPost(API.auditDrug, data)
}
/**
 * 查询药品批次
 * @param  data
 * @returns
 */
export const QueryDrugBatch = async (data: any) => {
  return await CommonFetchPost(API.queryDrugBatch, data)
}
/**
 * 查询药品批次
 * @param  data
 * @returns
 */
export const QueryDrugBatchById = async (data: any) => {
  return await CommonFetchPost(API.queryDrugBatchById, data)
}
/**
 * 查询药品库存变更记录
 * @param  data
 * @returns
 */
export const QueryDrugBatchLog = async (data: any) => {
  return await CommonFetchPost(API.queryDrugBatchLog, data)
}
/**
 * 新增药品批次
 * @param  data
 * @returns
 */
export const AddDrugBatch = async (data: any) => {
  return await CommonFetchPost(API.addDrugBatch, data)
}
/**
 * 编辑药品批次信息
 * @param  data
 * @returns
 */
export const UpdateDrugBatch = async (data: any) => {
  return await CommonFetchPost(API.updateDrugBatch, data)
}
/**
 * 入/出库
 * @param  data
 * @returns
 */
export const PutOrOutStorage = async (data: any) => {
  return await CommonFetchPost(API.putOrOutStorage, data)
}
/**
 * 下架药品
 * @param  data
 * @returns
 */
export const DelistDrug = async (data: any) => {
  return await CommonFetchPost(API.delistDrug, data)
}
/**
 * 上架药品
 * @param  data
 * @returns
 */
export const ListingDrug = async (data: any) => {
  return await CommonFetchPost(API.listingDrug, data)
}
/**
 * 批量更新药品信息
 * @param  data
 * @returns
 */
export const BatchUpdateOrSaveDrug = async (data: any) => {
  return await CommonFetchPost(API.batchUpdateOrSaveDrug, data)
}
/**
 * 提交供货价审核
 * @param  data
 * @returns
 */
export const SubmitSuppliyPriceAudit = async (data: any) => {
  return await CommonFetchPost(API.submitSuppliyPriceAudit, data)
}
/**
 * 审核供货价
 * @param  data
 * @returns
 */
export const AuditSuppliyPrice = async (data: any) => {
  return await CommonFetchPost(API.auditSuppliyPrice, data)
}
/**
 * 获取供货价审核列表
 * @param  data
 * @returns
 */
export const QuerySuppliyPriceAudit = async (data: any) => {
  return await CommonFetchPost(API.querySuppliyPriceAudit, data)
}

/*****药店相关****/
/**
 * 查询药店
 * @param  data
 * @returns
 */
export const QueryDrugStore = async (data: any) => {
  return await CommonFetchPost(API.queryDrugStore, data)
}
/**
 * 添加药店
 * @param  data
 * @returns
 */
export const AddDrugStore = async (data: any) => {
  return await CommonFetchPost(API.addDrugStore, data)
}
/**
 * 更新药店
 * @param  data
 * @returns
 */
export const UpdateDrugStore = async (data: any) => {
  return await CommonFetchPost(API.updateDrugStore, data)
}

/****订单*****/
/**
 * 订单统计
 * @param  data
 * @returns
 */
export const OrderStatistics = async (data: any) => {
  return await CommonFetchPost(API.orderStatistics, data)
}
/**
 * 查询订单
 * @param  data
 * @returns
 */
export const QueryOrder = async (data: any) => {
  return await CommonFetchPost(API.queryOrder, data)
}
/**
 * 查询订单
 * @param  data
 * @returns
 */
export const QueryOrderById = async (data: any) => {
  return await CommonFetchPost(API.queryOrderById, data)
}
/**
 * 查询订单明细
 * @param  data
 * @returns
 */
export const QueryOrderDetails = async (data: any) => {
  return await CommonFetchPost(API.queryOrderDetails, data)
}
/**
 * 查询订单明细
 * @param  data
 * @returns
 */
export const QueryOrderDetailsById = async (data: any) => {
  return await CommonFetchPost(API.queryOrderDetailsById, data)
}
/**
 * 上传订单药品批次
 * @param  data
 * @returns
 */
export const UploadBatchInfo = async (data: any) => {
  return await CommonFetchPost(API.uploadBatchInfo, data)
}
/**
 * 上传发票
 * @param  data
 * @returns
 */
export const UploadInvoice = async (data: any) => {
  return await CommonFetchPost(API.uploadInvoice, data)
}
/**
 * 上传快递信息
 * @param  data
 * @returns
 */
export const UploadShipper = async (data: any) => {
  return await CommonFetchPost(API.uploadShipper, data)
}
/**
 * 分拣中（状态变更）
 * @param  data
 * @returns
 */
export const BatchSorting = async (data: any) => {
  return await CommonFetchPost(API.batchSorting, data)
}
/**
 * 开单完成
 * @param  data
 * @returns
 */
export const MakeOrder = async (data: any) => {
  return await CommonFetchPost(API.makeOrder, data)
}
/**
 * 确认出库
 * @param  data
 * @returns
 */
export const ConfirmOut = async (data: any) => {
  return await CommonFetchPost(API.confirmOut, data)
}
/**
 * 确认分拣
 * @param  data
 * @returns
 */
export const ConfirmSorting = async (data: any) => {
  return await CommonFetchPost(API.confirmSorting, data)
}
/**
 * 配送中
 * @param  data
 * @returns
 */
export const DeliveryDoing = async (data: any) => {
  return await CommonFetchPost(API.deliveryDoing, data)
}
/**
 * 配送完成
 * @param  data
 * @returns
 */
export const DeliverySuccess = async (data: any) => {
  return await CommonFetchPost(API.deliverySuccess, data)
}
/**
 * 订单完成
 * @param  data
 * @returns
 */
export const OrderFinish = async (data: any) => {
  return await CommonFetchPost(API.finish, data)
}
/**
 * 订单完成
 * @param  data
 * @returns
 */
export const UpdateOrerDetailsBatchInfo = async (data: any) => {
  return await CommonFetchPost(API.updateOrerDetailsBatchInfo, data)
}
/**
 * 创建销售单
 * @param  data
 * @returns
 */
export const CreateSalesOrder = async (data: any) => {
  return await CommonFetchPost(API.createSalesOrder, data)
}

/******导出******/
/**
 * 导出-供应商
 * @param  data
 * @returns
 */
export const ExportSupplier = async (data: any) => {
  CommonExportGet(API.exportSupplier, data)
}
/**
 * 导出-药店
 * @param  data
 * @returns
 */
export const ExportDrugstore = async (data: any) => {
  CommonExportGet(API.exportDrugstore, data)
}
/**
 * 导出-产品
 * @param  data
 * @returns
 */
export const ExportDrug = async (data: any) => {
  CommonExportGet(API.exportDrug, data)
}
/**
 * 导出-销售单
 * @param  data
 * @returns
 */
export const ExportSalesOrder = async (data: any) => {
  CommonExportGet(API.exportSalesOrder, data)
}
/**
 * 导出-供应商销售单
 * @param  data
 * @returns
 */
export const ExportSupplierSalesOrder = async (data: any) => {
  CommonExportGet(API.exportSupplierSalesOrder, data)
}
/**
 * 导出-订单
 * @param  data
 * @returns
 */
export const ExportOrder = async (data: any) => {
  CommonExportGet(API.exportOrder, data)
}
/**
 * 导出-供应商销售单
 * @param  data
 * @returns
 */
export const ExportSupplierOrder = async (data: any) => {
  CommonExportGet(API.exportSupplierOrder, data)
}
/**
 * 导出-清单
 * @param  data
 * @returns
 */
export const ExportOrderForDistribution = async (data: any) => {
  CommonExportGet(API.exportOrderForDistribution, data)
}
/**
 * 导出-清单
 * @param  data
 * @returns
 */
export const ExportAllOrderList = async (data: any) => {
  CommonExportGet(API.exportAllOrderList, data)
}
/**
 * 导出-快递信息
 * @param  data
 * @returns
 */
export const ExportOrderShipperInfo = async (data: any) => {
  CommonExportGet(API.exportOrderShipperInfo, data)
}

/**
 * 查询有订单的供应商
 * @param  data
 * @returns
 */
export const QueryOrderSupplierInfo = async (data: any) => {
  return await CommonFetchPost(API.queryOrderSupplierInfo, data)
}
/**
 * 查询供应商订单
 * @param  data
 * @returns
 */
export const QuerySupplierOrder = async (data: any) => {
  return await CommonFetchPost(API.querySupplierOrder, data)
}
/**
 * 查询供应商订单
 * @param  data
 * @returns
 */
export const QuerySupplierOrderForOperator = async (data: any) => {
  return await CommonFetchPost(API.querySupplierOrderForOperator, data)
}
/**
 * 查询供应商订单明细
 * @param  data
 * @returns
 */
export const QuerySupplierOrderDetails = async (data: any) => {
  return await CommonFetchPost(API.querySupplierOrderDetails, data)
}
/**
 * 查询销售单
 * @param  data
 * @returns
 */
export const QuerySalesOrder = async (data: any) => {
  return await CommonFetchPost(API.querySalesOrder, data)
}
/**
 * 查询销售单
 * @param  data
 * @returns
 */
export const QuerySalesOrder2 = async (data: any) => {
  return await CommonFetchPost(API.querySalesOrder2, data)
}
/**
 * 供应商确认出库
 * @param  data
 * @returns
 */
export const SupplierConfirmOut = async (data: any) => {
  return await CommonFetchPost(API.supplierConfirmOut, data)
}


/**
 * 供应商首页数据
 * @param  data
 * @returns
 */
export const QuerySupplierIndexData = async (data: any) => {
  return await CommonFetchPost(API.querySupplierIndexData, data)
}
/**
 * 管理员首页数据
 * @param  data
 * @returns
 */
export const QueryIndexData = async (data: any) => {
  return await CommonFetchPost(API.queryIndexData, data)
}



/**
 * 查询微信账号
 * @param  data
 * @returns
 */
export const QueryWxAccount = async (data: any) => {
  return await CommonFetchPost(API.queryWxAccount, data)
}
/**
 * 修改状态微信账号
 * @param  data
 * @returns
 */
export const ChangeWxAccountStatus = async (data: any) => {
  return await CommonFetchPost(API.changeWxAccountStatus, data)
}
/**
 * 查询消息模版
 * @param  data
 * @returns
 */
export const QueryMsgTemplate = async (data: any) => {
  return await CommonFetchPost(API.queryMsgTemplate, data)
}
/**
 * 修改用户模版状态
 * @param  data
 * @returns
 */
export const ChangeTemplateMsgStatus = async (data: any) => {
  return await CommonFetchPost(API.changeTemplateMsgStatus, data)
}
/**
 * 发送测试消息
 * @param  data
 * @returns
 */
export const SendTestMsg = async (data: any) => {
  return await CommonFetchPost(API.sendTestMsg, data)
}


/**
 * 查询系统配置
 * @param  data
 * @returns
 */
export const QuerySystemConfig = async (data: any) => {
  return await CommonFetchPost(API.querySystemConfig, data)
}
/**
 * 查询系统配置
 * @param  data
 * @returns
 */
export const QuerySystemConfigByKey = async (data: any) => {
  return await CommonFetchPost(API.querySystemConfigByKey, data)
}
/**
 * 更新系统配置值
 * @param  data
 * @returns
 */
export const UpdateSystemConfigValue = async (data: any) => {
  return await CommonFetchPost(API.updateSystemConfigValue, data)
}

/********合作店铺*********/
/**
 * 查询合作方店铺列表
 * @param  data
 * @returns
 */
export const QueryPartnerShop = async (data: any) => {
  return await CommonFetchPost(API.queryPartnerShop, data)
}
/**
 * 查询合作方店铺列表-下拉框
 * @param  data
 * @returns
 */
export const QueryPartnerShopForSelect = async (data: any) => {
  return await CommonFetchPost(API.queryPartnerShopForSelect, data)
}
/**
 * 新增店铺
 * @param  data
 * @returns
 */
export const AddPartnerShop = async (data: any) => {
  return await CommonFetchPost(API.addPartnerShop, data)
}
/**
 * 更新店铺
 * @param  data
 * @returns
 */
export const UpdatePartnerShop = async (data: any) => {
  return await CommonFetchPost(API.updatePartnerShop, data)
}
/**
 * 变更店铺状态
 * @param  data
 * @returns
 */
export const ChangePartnerShopStatus = async (data: any) => {
  return await CommonFetchPost(API.changePartnerShopStatus, data)
}
/**
 * 同步店铺（从第三方平台同步到本系统）
 * @param  data
 * @returns
 */
export const SyncShop = async (data: any) => {
  return await CommonFetchPost(API.syncShop, data)
}

/**
 * 同步商品到店铺（erp到目标店铺）（手动同步全量，只有部分类型的店铺需要）
 * @param  data
 * @returns
 */
export const SyncProductToShop = async (data: any) => {
  return await CommonFetchPost(API.syncProductToShop, data)
}


 





