
// 保存微信公众号授权MpCode
export const saveWxMpCode = (code: string) =>{
    localStorage.setItem("WxMpCode",code)
  }
// 获取微信公众号授权code
export const getWxMpCode = () => {
  const code = localStorage.getItem("WxMpCode")
  if(code == null){
    return ''
  }
  return code
}

// 移除微信公众号授权code
export const removeWxMpCode = () => {
  localStorage.removeItem("WxMpCode")
}
  
  