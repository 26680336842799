














import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { ResetPasswordsForPlatformAccount } from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: {
  },
})
export default class RestPwdDialog extends Vue {
  private show = false
  private data: any = {newPassword:''}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    newPassword: [this.requiredRule],
  }

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {newPassword:''}
  }
  public open(param: any) {
    this.show = true
    this.data.id = param.id
  }

  public async save() {
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      await ResetPasswordsForPlatformAccount(this.data)
      Message.success('修改成功')
      this.close()
    })
  }
}
