














import { Component, Vue, Prop, Emit } from 'vue-property-decorator'


@Component({
  components: {},
})
export default class BannerItem extends Vue {
  @Prop(Object) private banner!: any
  @Prop({ type: Number, default: 335 }) private width!: number
  @Prop({ type: Number, default: 160 }) private height!: number

  @Emit('update')
  private update() {
    return this.banner
  }
  @Emit('deleteBanner')
  private deleteBanner() {
    return this.banner
  }

  private get bannerItemStyle() {
    return {
      width: this.width + 'px',
      height: this.height + 40 + 'px',
    }
  }
  private get emptyStyle() {
    return { lineHeight: this.height + 40 + 'px' }
  }

  private videoType = ['mp4', 'avi', 'flv', 'mkv', '3gp', 'mov', 'asf', 'f4v', 'mpeg', 'mpg', 'wmv', 'rm', 'rmvb', 'ts']
  private dialogImageType(url: string) {
    if (!url) {
      return ''
    }
    const lastIndex = url.lastIndexOf('.')
    const suffix = url.substring(lastIndex + 1)
    const isVideo = this.videoType.findIndex((e) => e == suffix)
    if (isVideo >= 0) {
      return 'video'
    }
    return 'img'
  }
}
