






















































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {  AuditDrug, QueryDrugBatch, PutOrOutStorage, QueryDrugBatchLog, QueryPartnerShopForSelect} from '@/api'
import constant from '@/utils/constant'
import { DateFormat, DateFormat2, timeFormate } from '@/utils/date/DateUtil'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { getRole } from '@/store/userStore'
import { VImageUpload } from '@/components/upload'
import UpdateDrugBatch from './UpdateDrugBatch.vue'
import DrugBatchLogItem from './componets/DrugBatchLogItem.vue'
import AddDrugBatch from  './AddDrugBatch.vue'

@Component({
  components: {
    VTable,
    VQuery,
    VImageUpload,
    UpdateDrugBatch,
    DrugBatchLogItem,
    AddDrugBatch
  },
})
export default class CreatePlatformDialog extends Vue {
  // 是否是审核
  @Prop({type: Boolean,default: false}) private audit!: boolean
  private constant = constant
  private show = false
  private data: any = {}
  private tableTotal = 0
  private tableData = []
  private shopSelect = []
  private tableCurrntPage = 1
  private queryParam = {
    stockGt:0
  }
  private selectTab = 'batch'
  private get role() {
    return getRole();
  }

  private drugBarchLogData: Array<any> = []

  public created() {
    if(this.role == 1){
      this.shopRmote("") 
    }
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '批次状态', type:"select", span:5, param: 'stockGt', selectData: constant.BatchType},
  ]

  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    { title: '新增批次', type: 'primary', click:this.openAddDrugBatchDialog},
  ]

  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '批号', param: 'batchNum' },
    { title: '生产日期', width: 100, param: 'prodDate' , formatter: DateFormat2},
    { title: '有效期', width: 100, param: 'validity' , formatter: DateFormat2 },
    // { title: '入库日期', param: 'createTime', formatter: DateFormat },
    // { title: '入库数量', param: 'putStock' },
    { title: '当前库存', param: 'stock' },
    // { title: '供货价', param: 'price' },  
    { title: '操作',
      fixed: 'right',
      width: 150,
      operation: [
        {
          title: '修改',  
          click: this.openUpdateBatch
        },
        {
          title: '入库',
          click: this.putStock
        },
        {
          title: '出库',
          click: this.outStock
        },
      ],}
    
  ]

  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private openAddDrugBatchDialog(){
    this.openDialog('AddDrugBatch',this.data)
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
    
  }

  public async loadData() {
    const param: any = { ...this.queryParam,drugCode:this.data.drugCode}
    param.startRow = (this.tableCurrntPage - 1) * 10
    const result = await QueryDrugBatch(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  private barchLogPage = 1
  private async loadBatchLog(){
    if(this.barchLogPage == -1){
      return
    }
    const param: any = {drugCode:this.data.drugCode,limit: 10}
    param.startRow = (this.barchLogPage - 1) * 10
    const d = await QueryDrugBatchLog(param)
    const list: Array<any> = d.list
    list.forEach((e:any) => {
      e.createTime = DateFormat(e.createTime)
    });

    if(param.startRow == 0){
      this.drugBarchLogData = []
    }
    
    this.drugBarchLogData.push(...list)
    if(d.hasNextPage){
      this.barchLogPage = this.barchLogPage+1
    } else {
      this.barchLogPage = -1
    }
  }


  @Emit('close')
  private close() {
    this.show = false
    this.data = {}
  }
  public open(param: any) {
    this.show = true
    this.data = param
    this.loadData()
    this.barchLogPage = 1
    this.drugBarchLogData = []
    this.loadBatchLog()
  }

  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }

  private async submitAudit(isAuditPass: boolean){
    const ids = [this.data.id]
    if(!isAuditPass){ 
      this.$prompt('请输入审核拒绝原因！','提示',{inputPattern:/./,inputErrorMessage:'请输入审核拒绝原因'}).then(async ({ value }:any) => {
          await AuditDrug({ids,isAuditPass,auditRefuseMsg:value})
          this.$message({
            type: 'success',
            message: '操作完成'
          });
          this.close()
        })  ;
    } else {
      await AuditDrug({ids,isAuditPass})
      this.close()
    }
  }

  private openUpdateBatch(data: any){
    const d = this.$refs.UpdateDrugBatch as UpdateDrugBatch
    d.open(data)
  }

  private async putStock(data: any){
    console.log(data)
    this.$prompt('入库数量','',{inputPattern:/./,inputErrorMessage:'请输入入库数量'}).then(async ({ value }:any) => {
      await PutOrOutStorage({
        stock:value,
        batchId: data.id,
        type: 0,
        price: data.price
      })
      this.loadData();
    })  ;
  }
  private async outStock(data: any){
    console.log(data)
    this.$prompt('出库数量','',{inputPattern:/./,inputErrorMessage:'请输入出库数量'}).then(async ({ value }:any) => {
      await PutOrOutStorage({
        stock:value,
        batchId: data.id,
        type: 1,
        price: data.price
      })
      this.loadData();
    })  ;
  }
  private async shopRmote(query: any,id?: number) {
    const list = await QueryPartnerShopForSelect({name:query,id:id})
    this.shopSelect = list.list
  }
  private formShop(value: string){
    if(!value){
      return ''
    }
    const vs = value.split(',')
    const result: Array<string> = []
    vs.forEach(v => {
      this.shopSelect.forEach((s:any)=>{
        if(s.value == v){
          result.push(s.label) 
        }
      })
    });
    return result.join(',')
  }
}
