













import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { QuerySuppliyPriceAudit,AuditSuppliyPrice,DelistDrug, ListingDrug, QuerySystemConfigByKey, QueryPartnerShopForSelect, SupplierListForSelect } from '@/api'
import { DateFormat } from '@/utils/date/DateUtil'
import constant from '@/utils/constant'
import { PriceFormat, StatusFormat } from '@/utils/tyepconvert'
import { getRole } from '@/store/userStore'

@Component({
  components: {
    VQuery,
    VTable,
  },
})
export default class PlatformManager extends Vue {
  private queryParam: any = {
    status: 2,
    limit: 10
  }
  private activityTab = "wait"
  private tableTotal = 0
  private tableData = []
  private tableSelectData = []
  private tableCurrntPage = 1
  private supplierSelect= []
  private shopSelect = []
  private salesPriceRatio = 1
  private get role() {
    return getRole();
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '供应商', type:"select", param: 'supplierId', selectData: this.supplierSelect,remoteMethod: this.supplierRmote , show: ()=> this.role == 1},
    { title:'产品编号', param: "drugCode"},
    { title:'产品名称', param: "drugName"},
  ]
  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    { title: '批量审核通过', type: 'primary', disable: this.batchDisable,click: ()=>this.auditSuccess(true)},
    { title: '批量审核失败', type: 'primary', disable: this.batchDisable,click: ()=>this.auditSuccess(false)},
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: 'ID', param: 'id', type: "selection", width: 50},
    { title: '序号', param: 'id', type: "index", width: 50},
    { title: '供应商', param: 'supplierName', width: 80,overflow: true },
    { title: '产品编号', param: 'drugCode', width: 100},
    { title: '产品名称', param: 'drugName'},
    { title: '单位', param: 'unit',width: 60 },
    { title: '规格', param: 'pack',width: 100,overflow:true },
    { title: '生产厂家', param: 'factory',width: 120 ,overflow:true},
    { title: '库存', param: 'stock',width: 80 },
    { title: '原供货价', param: 'origSuppliyPrice',width: 80 },
    { title: '新供货价', param: 'suppliyPrice',width: 80 },
    { title: '原售价 ', param: 'origPrice',width: 80 },
    { title: '新售价', param: 'price',width: 80},
    { title: '状态', param: 'status',width: 80, formatter: (value: any)=>{
      return StatusFormat(constant.SuppliyPriceAuditStatus,value)
    } },
    { title: '创建时间', param: 'createTime', width: 220 ,overflow: true, formatter: DateFormat },
  ]

  private batchDisable(){
    return this.tableSelectData.length == 0
  }
  private selectionChange(val:any){
    this.tableSelectData = val
  }

  private async loadSystemConfig(){
    const res = await QuerySystemConfigByKey({configKey:'salesPriceRatio'})
    this.salesPriceRatio = Number(res.configValue)
  }

  private suppliyPriceFormatter(value){
    
    return PriceFormat(this.salesPriceRatio * value)
  }

  public async created() {
    // 平台角色，则加载供应商
    if(this.role == 1){
      this.supplierRmote("")
      this.shopRmote("")
    }
    this.loadData()
    this.loadSystemConfig()
  }

  public async loadData() {
    this.tabClickParam()
    const param: any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QuerySuppliyPriceAudit(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  private async supplierRmote(query: any,id?: number) {
    const list = await SupplierListForSelect({name:query,id:id})
    this.supplierSelect = list.list
    this.queryModels[0].selectData = this.supplierSelect
  }

  private async shopRmote(query: any,id?: number) {
    const list = await QueryPartnerShopForSelect({name:query,id:id})
    this.shopSelect = list.list
    this.queryModels[1].selectData = this.shopSelect
  }
  private formShop(value: string){
    if(value == null){
      return ""
    }
    const vs = value.split(',')
    const result: Array<string> = []
    vs.forEach(v => {
      this.shopSelect.forEach((s:any)=>{
        if(s.value == v){
          result.push(s.label) 
        }
      })
    });
    return result.join(',')
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }
  
  
  private tabClick(){
    // this.tabClickParam()
    this.tableCurrntPage = 1
    this.loadData()
  }
  private tabClickParam(){
    if(this.activityTab == "wait"){
      this.queryParam.status = 0
    } else if(this.activityTab == "success"){
       this.queryParam.status = 1
    } else if(this.activityTab == "refuse") {
      this.queryParam.status = 2
    }
  }

  private auditSuccess(isAuditPass){
    // 审核通过
    if(this.tableSelectData.length == 0){
      return
    }
    const ids = this.tableSelectData.map((e :any)=>e.id)

    const param = {ids,isAuditPass}
    this.$alert(isAuditPass?'是否确认选择的数据都审核通过':'是否确认选择的数据都审核失败','提示',{
      confirmButtonText: "确定",
      cancelButtonText:"取消",
      showCancelButton: true,
      callback:async (action) => {
        if(action == 'confirm'){
          const result = await AuditSuppliyPrice(param)
          if(result){
            this.$message({
              type: 'success',
              message: isAuditPass?'审核通过操作完成':'审核失败操作完成'
            });
            this.loadData()
          } else {
            this.$message({
              type: 'error',
              message: isAuditPass?'审核通过操作异常':'审核失败操作异常'
            });
          }
        }
      }
    })
  }

  private listingDrug(){
    // 批量上架
    if(this.tableSelectData.length == 0){
      return
    }
    const ids = this.tableSelectData.map((e :any)=>e.id)
    this.$alert('是否确认批量上架','提示',{
      confirmButtonText: "确定",
      cancelButtonText:"取消",
      showCancelButton: true,
      callback:async (action) => {
        if(action == 'confirm'){
          const result = await ListingDrug({ids})
          if(result){
            this.$message({
              type: 'success',
              message: '下架完成'
            });
            this.loadData()
          } else {
            this.$message({
              type: 'error',
              message: '下架异常'
            });
          }
        }
      }
    })
  }
  
}
