























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { AddBanner } from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import { VImageUpload } from '@/components/upload'
import constant from '@/utils/constant'

@Component({
  components: {
    VImageUpload,
  },
})
export default class CreateBannerDialog extends Vue {
  private show = false
  private param: any = {}
  private data = {
    picUrl: '',
    jumpPage: 0,
  }
  private jumpPage = constant.BannerJumpPage
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    bannerName: [this.requiredRule],
  }

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data = {
      picUrl: '',
      jumpPage: 0,
    }
  }
  @Emit('refresh')
  private refresh() {
    return {}
  }

  public open(param: any) {
    this.show = true
    this.param = param
    console.log(param)
  }

  public async save() {
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      const banner = {
        ...this.data,
        platform: this.param.platform,
        channelId: this.param.channelId,
      }

      await AddBanner({
        banner,
      })
      this.refresh()
      Message.success('保存')
      this.close()
    })
  }
}
