









import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { QueryPartnerShop, SupplierListForSelect, ChangePartnerShopStatus, SyncProductToShop } from '@/api'
import { DateFormat, DateFormat2 } from '@/utils/date/DateUtil'
import { PlatformStatusFormat,StatusFormat } from '@/utils/tyepconvert'
import constant from '@/utils/constant'
import { Message, MessageBox } from 'element-ui'
import EditPartnerShopDialog from './components/EditPartnerShopDialog.vue'

@Component({
  components: {
    VQuery,
    VTable,
    EditPartnerShopDialog
  },
})
export default class PartnerShop extends Vue {
  private queryParam = {
    limit: 10
  }
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private supplierSelect= []

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '渠道', param: 'partnerChannel', type: 'select',selectData: constant.PartnerChannel },
    { title: '店铺名称', param: 'shopName' },
  ]
  // 全局操作
  private queryOperation: Array<TableOperation> = [
    { title: '新增店铺', type: 'primary', click: () => this.openDialog('EditPartnerShopDialog') },
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '店铺id', param: 'id'},
    { title: '合作渠道', param: 'partnerChannel', formatter: (value)=>StatusFormat(constant.PartnerChannel,value) },
    { title: '店铺名称', param: 'shopName' },
    { title: '票据抬头', param: 'billTitle' },
    { title: '服务电话', param: 'billPhone' },
    { title: 'appid', param: 'appid' },
    { title: '状态', param: 'status', formatter: (value)=>StatusFormat(constant.ShopStatus,value) },
    { title: '创建时间', param: 'createTime', formatter: DateFormat },
    {
      title: '操作',
      operation: [
        {
          title: '同步商品到店铺',
          show: (data)=>data.status == 0,
          click: (data) => this.syncProductToShop(data),
        },
        {
          title: '禁用',
          show: (data)=>data.status == 0,
          click: (data) => this.changeStats(data,1),
        },
        {
          title: '启用',
          show: (data)=>data.status == 1,
          click: (data) => this.changeStats(data,0),
        },
        {
          title: '编辑',
          icon: 'el-icon-edit',
          click: (data) => this.openDialog('EditPartnerShopDialog', data),
        },
        // {
        //   title: '同步信息到本系统',
        //   show: (data)=>data.status == 0 || data.status == 3,
        //   click: async (data) => {
        //     const res = await SyncShop({id:data.id})
        //     this.loadData()
        //     if(res){
        //       Message.success("已提交同步申请")
        //     }
        //   },
        // },
      ],
    },
  ]

  public async created() {
    this.loadData()
  }

  public async loadData() {
    const param:any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QueryPartnerShop(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }


  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private async syncProductToShop(data: any){
    MessageBox.confirm('确认将erp商品全部同步到店铺（'+data.shopName+'）吗？\r\nps:同步会比较耗时，请耐心等待！', '提示', {
      type: 'warning',
    }).then(async () => {
      await SyncProductToShop({
          id: data.id,
        })
      this.loadData()
    })
  }

  private async changeStats(data: any,value: number){
    console.log(data)
    const sn = data.status == 0 ? '禁用':"启用"
    MessageBox.confirm('确认将店铺（'+data.shopName+'）状态变为'+sn+'吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      await ChangePartnerShopStatus({
          id: data.id,
          status: value
        })
      this.loadData()
    })
    
  }
}
