





















import { Component, Vue } from 'vue-property-decorator'
import { QueryDrug, SupplierListForSelect } from '@/api'
import { getRole, getUser } from '@/store/userStore'
import DrugItem from './componets/DrugItem.vue'
import router from '@/router'

@Component({
  components: {
   DrugItem
  },
})
export default class Drug extends Vue {
  private queryParam = {
    drugName: "",
    supplierId: null,
    selectSupplierName: "全部供应商"
  }
  private selectValue = ""
  private tableTotal = 0
  private tableData: Array<any> = []
  private tableCurrntPage = 0
  private supplierPickerDatas: Array<any>= []
  private showSupplierPicker = false
  private tableOver = false
  private get role() {
    return getRole();
  }
  private get headImgUrl(){
    const userInfo = getUser()
    if(userInfo?.user?.wxAccount?.headImgUrl){
      return userInfo?.user?.wxAccount?.headImgUrl
    }
    return require('@/assets/default_head_img.png')
  }
  public async created() {
    console.log('dreg-created')
    // this.loadData()
    this.loadSupplierData()
  }

  private async loadSupplierData(){
    if(this.role == 2){
      return
    }
    const list = await SupplierListForSelect({})
    this.supplierPickerDatas = [{label:"全部供应商",value:null},...list.list]
  }
  public supplierChange(v:any){
    console.log(v)
    this.queryParam.supplierId = v.value
    this.queryParam.selectSupplierName = v.label
    this.loadData()

    this.showSupplierPicker = false
  }

  public refresh(){
    console.log('refresh')
    this.tableCurrntPage = 1
    this.tableOver = false
    const sc = this.$refs.scroller as any
    sc.finishInfinite(false)
    this.loadData()
  }
  public infinite(){
    this.tableCurrntPage = this.tableCurrntPage+1
    this.loadData()
  }

  public async loadData() {
    const sc = this.$refs.scroller as any
    if(this.tableOver){
      sc.finishPullToRefresh(true)
      sc.finishInfinite(true)
      return
    }
    
    const param: any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * 10
    param.status=2
    const result = await QueryDrug(param)
    this.tableTotal = result.total
    this.tableOver = !result.hasNextPage
    if(this.tableCurrntPage == 1){
      this.tableData = result.list
    } else {
      this.tableData.push(...result.list)
    }
    
    sc.finishPullToRefresh(true)
    sc.finishInfinite(true)
    
  }


  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private jumpToSet(){
    router.push("/h5/seting")
  }
  
}
