// 供应商角色路由
import { RouteConfig } from 'vue-router'
import ManageLayout from '@/layout/ManageLayout.vue'
import H5TabBarLayout from '@/layout/H5TabBarLayout.vue'
import H5PageLayout from '@/layout/H5PageLayout.vue'

import Login from '@/views/Login.vue'
import Error404 from '@/views/error/404.vue'
import Drug from '@/views/drug/Drug.vue'
import DrugDraft from '@/views/drug/DrugDraft.vue'
import SupplierOrder from '@/views/supplierOrder/SupplierOrder.vue'
// TODO 设计供应商的工作台
import SupplierWork from '@/views/work/SupplierWork.vue'
import Sales from '@/views/supplierSales/SupplierSales.vue'
import AccountCenter from '@/views/accountCenter/AccountCenter.vue'

import H5Drug from '@/views/h5/drug/Drug.vue'
import H5EditDrug from '@/views/h5/drug/EditDrug.vue'
import H5ManageDrugBatch from '@/views/h5/drug/ManageDrugBatch.vue'
import H5AddDrugBatch from '@/views/h5/drug/AddDrugBatch.vue'
import H5UpdateDrugBatch from '@/views/h5/drug/UpdateDrugBatch.vue'
import H5PutDrugBatch from '@/views/h5/drug/PutDrugBatch.vue'
import H5OutDrugBatch from '@/views/h5/drug/OutDrugBatch.vue'
import H5Order from '@/views/h5/order/Order.vue'
import H5Seting from '@/views/h5/seting/index.vue'
import H5Sales from '@/views/h5/sales/Sales.vue'
import H5SuppliyPriceAudit from '@/views/h5/drug/SuppliyPriceAudit.vue'


const number = 3

/**
 * 路由
 */
const routeConfig: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/work',
  },
  {
    path: '/h5',
    redirect: '/h5/drug',
  },
  {
    // H5的底部导航
    path: '/',
    name: 'H5TabBar',
    component: H5TabBarLayout,
    meta: {
      title: '首页',
    },children: [
      {
        path: '/h5/drug',
        name: 'h5drug',
        meta: {
          title: '产品管理-康恩诺ERP',
        },
        component: H5Drug,
      },
      {
        path: '/h5/addDrug',
        name: 'h5EditDrug',
        meta: {
          title: '编辑产品-康恩诺ERP',
        },
        component: H5EditDrug,
      },
      {
        path: '/h5/order',
        name: 'h5Order',
        meta: {
          title: '订单出库-康恩诺ERP',
        },
        component: H5Order,
      },
      {
        path: '/h5/sales',
        name: 'H5Sales',
        meta: {
          title: '销售清单-康恩诺ERP',
        },
        component: H5Sales,
      },
      
    ]
  },
  {
    // H5的常规页面
    path: '/',
    name: 'H5PageLayout',
    component: H5PageLayout,
    meta: {
      title: '首页',
    },children: [
      
      {
        path: '/h5/editDrug',
        name: 'h5EditDrug',
        meta: {
          title: '编辑产品',
        },
        component: H5EditDrug,
      },
      {
        path: '/h5/seting',
        name: 'H5Seting',
        meta: {
          title: '设置',
        },
        component: H5Seting,
      },
      {
        path: '/h5/manageDrugBatch',
        name: 'manageDrugBatch',
        meta: {
          title: '管理批次',
        },
        component: H5ManageDrugBatch,
      },
      {
        path: '/h5/addDrugBatch',
        name: 'addDrugBatch',
        meta: {
          title: '新增批次',
        },
        component: H5AddDrugBatch,
      },
      {
        path: '/h5/updateDrugBatch',
        name: 'updateDrugBatch',
        meta: {
          title: '修改批次',
        },
        component: H5UpdateDrugBatch,
      },
      {
        path: '/h5/putDrugBatch',
        name: 'putDrugBatch',
        meta: {
          title: '入库',
        },
        component: H5PutDrugBatch,
      },
      {
        path: '/h5/outDrugBatch',
        name: 'outDrugBatch',
        meta: {
          title: '出库',
        },
        component: H5OutDrugBatch,
      },
      {
        path: '/h5/suppliyPriceAudit',
        name: 'SuppliyPriceAudit',
        meta: {
          title: '修改供货价',
        },
        component: H5SuppliyPriceAudit,
      },
    ]
  },
  {
    path: '/',
    name: 'Home',
    component: ManageLayout,
    meta: {
      title: '首页',
    },
    children: [
      {
        path: '/sales',
        name: 'Sales',
        meta: {
          title: '销售单',
        },
        component: Sales,
      },
      {
        path: '/accountCenter',
        name: 'AccountCenter',
        meta: {
          title: '账户中心',
        },
        component: AccountCenter,
      },
      {
        path: '/order',
        name: 'Order',
        meta: {
          title: '订单管理',
        },
        component: SupplierOrder,
      },
      {
        path: '/work',
        name: 'Work',
        meta: {
          title: '工作台-康恩诺',
        },
        component: SupplierWork,
      },
      {
        path: '/drug',
        name: 'Drug',
        meta: {
          title: '产品管理',
        },
        component: Drug,
      },
      {
        path: '/drugDraft',
        name: 'DrugDraft',
        meta: {
          title: '草稿箱',
        },
        component: DrugDraft,
      },
      {
        path: '/error404',
        name: 'error404',
        meta: {
          title: '页面不存在',
        },
        component: Error404,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: Login,
  },
]

/**
 * 菜单
 */
const menu = [
  {
    icon: require('@/assets/img/index-w.png'),
    index: '/work',
    title: '工作台',
    hiddenClose: true
  },
  {
    icon: require('@/assets/img/drug-w.png'),
    index: '1',
    title: '产品管理',
    subs: [
      {
        index: '/drugDraft',
        title: '草稿箱',
      },
      {
        index: '/drug',
        title: '产品管理',
      },
    ]
  },
  {
    icon: require('@/assets/img/order-w.png'),
    index: '/order',
    title: '订单管理',
  },
  {
    icon: require('@/assets/img/sales-w.png'),
    index: '/sales',
    title: '销售单',
  },
]

const r = {
  routeConfig,
  menu
}
export default r