<template lang="pug">
  .app
    router-view
</template>
// 引入腾讯地图js
<script charset="utf-8" src="https://map.qq.com/api/js?v=2.exp&key=HTBBZ-VL7C3-LZU3G-Y5P4Z-BKWHQ-NGFSN"></script>
<script>

export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";

.app {  
  height: 100%;
}

/* 配置统一的弹窗高度 */
.el-dialog__body{
  max-height: 450px;
  overflow:auto;
}
.el-dialog{
  max-width: 650px;
  min-width: 500px;
}

</style>
