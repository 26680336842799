











import { Component, Vue,Prop, Emit } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class NumberItem extends Vue {
  @Prop(String)private text!:string
  @Prop({type:Number,default: 0})private number!:number

  @Emit('click')
  private click(){
    return
  }

}
