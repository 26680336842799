import Conf from '@/config/config'

/**
 * 设备信息存储
 *
 *
 */
export default class DeviceInfoStorage {
  public static storagekey: string = 'xfyd.deviceInfo.' + Conf.ENV
  public static handerKey: string = 'x-user-agent'
  
  // 保存缓存
  private static save(data: any) {
    localStorage.setItem(this.storagekey, JSON.stringify(data))
  }

  //从缓存获取
  private static get(): any {
    const data = localStorage.getItem(this.storagekey)
    if (data == null) {
      return {}
    }
    return JSON.parse(data)
  }
  
  //
  public static defultDeviceInfo(): any {
    const di = {
      channel: 'platform',
      appName: 'ken-back-view',
      appVersion: '1.0.0',
      deviceType: 'back-view',
      deviceNumber: 'back-view',
      sysVersion: 'back-view',
      storeId: 0,
      time: new Date().getTime(),
    }
    // 设置默认
    this.save(di)
    return di
  }

  

  public static setChannel(channel: string) {
    const di = this.getDeviceInfo()
    if (channel) {
      di.channel = channel
    }
    this.save(di)
  }

  // 设置门店id
  public static setStoreId(storeId: number) {
    const di = this.getDeviceInfo()
    if (storeId) {
      di.storeId = storeId
    } else {
      di.storeId = 0
    }
    this.save(di)
  }

  /**
   * 获取设备信息
   */
  public static getDeviceInfo(): any {
    let deviceInfo = this.get()
    if (!deviceInfo || deviceInfo == null || deviceInfo.channel == null) {
      deviceInfo = this.defultDeviceInfo()
    }
    return deviceInfo
  }

  // 请求头格式 channel | appName | appVersion | deviceType | deviceNumber(MAC/IMEI) | sysVersion | 时间戳
  public static getDeviceInfoHeader(): any {
    const di = this.getDeviceInfo()
    if (di.channel == 'undefined') {
      di.channel = 'platform'
    }
    // 门店->可以变为供应商
    let storeId = 0
    
    const xUserAgent =
      di.channel +
      '|' +
      di.appName +
      '|' +
      di.appVersion +
      '|' +
      di.deviceType +
      '|' +
      di.deviceNumber +
      '|' +
      di.sysVersion +
      '|' +
      storeId +
      '|' +
      di.time
    return xUserAgent
  }
}
