
























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { CreateSupplier,UpdateSupplier } from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import { VImageUpload } from '@/components/upload'
import constant from '@/utils/constant'

@Component({
  components: {
    VImageUpload,
  },
})
export default class CreatePlatformDialog extends Vue {
  private constant = constant
  private show = false
  private data: any = {}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    name: [this.requiredRule],
    account: [this.requiredRule],
    role: [this.requiredRule],
    password: [this.requiredRule],
    supplierId: [this.requiredRule],
  }

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data ={}
  }
  public open(param: any) {
    this.show = true
    if(param){
      this.data = param
    } else {
      // this.data.fileListTemp = []
    }
  }

  public async save() {
    console.log(this.data)
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      if(!this.data.id ){
        await CreateSupplier(this.data)
      } else {
        await UpdateSupplier(this.data)
      }
      Message.success('保存成功')
      this.close()
    })
  }
}
