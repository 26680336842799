// input 输入内容限制.

// 限制只能输入日期格式 2023-05-23
export const date = (v: string)=>{
  return v.replace(/[^\d.]/g,'')
}

// 限制只能输入日期格式，可多选 2023-05-23,2023-05-24
export const dateMultiple = (v: string)=>{
  // 中文逗号转英文逗号
  v = v.replace("，",',')
  v = v.replace(/[^\d-,]/g,'')
  return v
}

export const  regReplace = (reg ,v) => {
  v = v.replace("，",',')
  v = v.replace(reg,'')
  return  v
}

export default {
  date,
  dateMultiple,
  regReplace
}