import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.ts'
import './assets/css/icon.css'
import '@/utils/filter'
import './plugins/vant.ts'
import './plugins/printNb.ts'
import './plugins/echarts.ts'
// eslint-disable-next-line
import VueScroller from "vue-scroller"
import './plugins/LodopFuncs'

Vue.config.productionTip = false

Vue.use(VueScroller);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

