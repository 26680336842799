import Vue from 'vue'
import { DateFormat, DateFormat2, DateFormat4, DateFormat5 } from '@/utils/date/DateUtil'
import * as tyepconvert from '@/utils/tyepconvert'
// 将tyepconvert 都加入过滤器
for (const [key, val] of Object.entries(tyepconvert)) {
  Vue.filter(key,val)
}

Vue.filter('DateFormat', DateFormat)
Vue.filter('DateFormat2', DateFormat2)
Vue.filter('DateFormat4', DateFormat4)
Vue.filter('DateFormat5', DateFormat5)


