



















import { Component, Vue } from 'vue-property-decorator'
import { VQuery, QueryModel, TableOperation } from '@/components/table'
import { QueryBannerList, ChangeBannersStatus, DeleteBanner } from '@/api'
import constant from '@/utils/constant'
import BannerItem from '@/components/banner/BannerItem.vue'
import draggable from 'vuedraggable'
import CreateBannerDialog from './CreateBannerDialog.vue'
import UpdateBannerDialog from './UpdateBannerDialog.vue'
import { Message, MessageBox } from 'element-ui'

@Component({
  components: {
    VQuery,
    BannerItem,
    draggable,
    CreateBannerDialog,
    UpdateBannerDialog,
  },
})
export default class Banner extends Vue {
  private queryParam = {
    platform: 0,
    channelId: 0,
  }
  private idleList: Array<any> = [] // 闲置的banner
  private enableList: Array<any> = [] // 启用的banner

  private emptyBanner = {
    id: -1,
    picUrl: '',
    bannerName: '',
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '客户端', param: 'platform', type: 'select', selectData: constant.BannerPlatform },
    { title: '展示位置', param: 'channelId', type: 'select', selectData: constant.BannerChannelId },
    { title: 'banner名称', param: 'bannerName' },
  ]
  // 全局操作
  private queryOperation: Array<TableOperation> = [{ title: '创建banner', type: 'primary', click: this.openCreate }]

  public async created() {
    this.loadData()
  }

  // 新建banner
  private openCreate() {
    const d = this.$refs.CreateBannerDialog as CreateBannerDialog
    d.open(this.queryParam)
  }

  public async loadData() {
    const idleList = await QueryBannerList({
      banner: {
        ...this.queryParam,
        status: 0,
      },
    })

    const enableList = await QueryBannerList({
      banner: {
        ...this.queryParam,
        status: 1,
      },
    })
    this.idleList = idleList.list
    this.enableList = enableList.list
  }

  public search() {
    this.loadData()
  }

  private async bannerChange(evn: any) {
    const idleIds = this.idleList.map((e: any) => e.id)
    const enableIds = this.enableList.map((e: any) => e.id)
    const param = { idleIds, enableIds }
    await ChangeBannersStatus(param)
  }

  private updateBanner(banner: any) {
    const d = this.$refs.UpdateBannerDialog as UpdateBannerDialog
    d.open(banner)
  }

  private deleteBanner(banner: any) {
    MessageBox.confirm('确定要删除吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      const ruslt = await DeleteBanner({ id: banner.id })
      if (ruslt) {
        Message.success('删除成功')
        this.loadData()
      }
    })
  }
}
