



























import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { Route } from 'vue-router'
import store from '@/store'

@Component
export default class Tags extends Vue {
  private router = router
  public created() {
    this.setTags(router.currentRoute)
    router.beforeEach((to, from, next) => {
      this.beforeRouteUpdate(to)
      next()
    })
  }

  public beforeRouteUpdate(to: Route) {
    if(to.path === '/login'){
      return
    }
    this.setTags(to)
  }

  private get route() {
    return router.currentRoute
  }

  private get tagsList() {
    return store.state.tagsList
  }
  private get showTags() {
    return this.tagsList.length > 0
  }

  public isActive(path: string) {
    return router.currentRoute.fullPath === path
  }

  // 关闭单个标签
  public closeTags(index: number) {
    const delItem = this.tagsList[index]
    store.commit('delTagsItem', { index })
    const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1]
    if (item) {
      delItem.path === router.currentRoute.fullPath && router.push(item.path)
    } else {
      router.push('/')
    }
  }
  // 设置标签
  public setTags(route: Route) {
    const isExist = this.tagsList.some((item) => {
      return item.path === route.fullPath
    })
    if (!isExist) {
      if (this.tagsList.length >= 8) {
        store.commit('delTagsItem', { index: 0 })
      }
      store.commit('setTagsItem', {
        name: route.name,
        title: route.meta ? route.meta.title : '',
        path: route.fullPath,
      })
    }
  }
  // 关闭全部标签
  public closeAll() {
    store.commit('clearTags')
    router.push('/')
  }
  // 关闭其他标签
  public closeOther() {
    const curItem = this.tagsList.filter((item) => {
      return item.path === router.currentRoute.fullPath
    })
    store.commit('closeTagsOther', curItem)
  }
  public handleTags(command: string) {
    command === 'other' ? this.closeOther() : this.closeAll()
  }
}
