









import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { ExportSupplierOrder, QuerySupplierOrderDetails, SupplierConfirmOut } from '@/api'
import { DateFormat, timeFormate } from '@/utils/date/DateUtil'
import constant from '@/utils/constant'
import { StatusFormat } from '@/utils/tyepconvert'
import { getRole } from '@/store/userStore'
import { Message } from 'element-ui'

@Component({
  components: {
    VQuery,
    VTable,
  },
})
export default class SupplierOrder extends Vue {
  private queryParam = {
    startRow: 0,
    limit: 10
  }
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1
  private supplierSelect= []
  private tableSelectData = []
  private get role() {
    return getRole();
  }
  private batchDisable(){
    return this.tableSelectData.length == 0
  }
  private selectionChange(val:any){
    this.tableSelectData = val
  }

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title:'订单编号', param: "orderCode"},
    { title:'药店编号', param: "drugCode"},
    { title:'药店名称', param: "drugName"},
    {title:'下单时间', param: 'createTimeScope', type:'daterange'},
  ]
  // 全局操作 
  private queryOperation: Array<TableOperation> = [
    // { title: '批量上传快递单号', type: 'primary', click: () => this.openDialog('EditDrug') },
    // { title: '导出收件人信息', type: 'primary', click: () => this.openDialog('EditDrug') },
    { title: '批量出库完成', type: 'primary', click: this.batchSubmit, disable:this.batchDisable },
    // { title: '导出订单', type: 'primary', click: () => {
    //   const param: any = { ...this.queryParam }
    //   // param.orderBy = "order_time desc"
    //   if(param.createTimeScope && param.createTimeScope.length==2){
    //     param.createTime = timeFormate(param.createTimeScope[0])+" 00:00:00,"+timeFormate(param.createTimeScope[1])+" 23:59:59"
    //   }
    //   ExportSupplierOrder(param)
    // } },
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: 'ID', param: 'id', type:"selection"},
    { title: '订单编号', param: 'orderCode', width: 100, },
    { title: '药品图片',width: 80, param: 'image1',slotName:'img'},
    { title: '药品编号', param: 'drugCode', width: 100},
    { title: '药品名称', param: 'drugName'},
    // { title: '批准文号', param: 'approval', width: 100},
    { title: '规格', param: 'pack'},
    { title: '单位', param: 'unit', width: 60},
    { title: '数量', width: 60, param: 'newQty'},
    { title: '供货价', width: 70, param: 'suppliyPrice'},
    { title: '总金额', width: 70, param: 'suppliyTotalPrice'},
    { title: '批号', width: 100, param: 'batchNum',overflow:true},
    { title: '生产日期', width: 100, param: 'prodDate',overflow:true},
    { title: '有效期', width: 100, param: 'validity',overflow:true},
    { title: '批次数量', width: 80, param: 'batchCount',overflow:true},
    { title: '下单时间', param: 'createTime', width: 160,formatter: DateFormat },
    
    // { title: '创建时间', param: 'createTime', width: 160 , formatter: DateFormat },
    {
      title: '操作',
      fixed: 'right',
      width: 120,
      operation: [
        {
          title: '出库完成',
          click: this.confirmOut,
        }
      ],
    },
  ]

  public async created() {
    this.loadData()
  }

  public async loadData() {
    const param: any = { ...this.queryParam }
    // param.orderBy = "order_time desc"
    if(param.createTimeScope && param.createTimeScope.length==2){
      param.createTime = timeFormate(param.createTimeScope[0])+" 00:00:00,"+timeFormate(param.createTimeScope[1])+" 23:59:59"
    }
    
    param.startRow = (this.tableCurrntPage - 1) * param.limit
    const result = await QuerySupplierOrderDetails(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }

  private async batchSubmit(){
    // 批量出库完成
    const orderDetailsIds = this.tableSelectData.map((e:any)=>{return e.id})
    await SupplierConfirmOut({orderDetailsIds})
    Message.success('操作成功')
    this.loadData()
  }

  private async confirmOut(data){
    await SupplierConfirmOut({orderDetailsIds:[data.id]})
    Message.success('操作成功')
    this.loadData()
  }
  
}
