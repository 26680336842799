// 角色编号为5的功能 （开单员）
import { RouteConfig } from 'vue-router'
import ManageLayout from '@/layout/ManageLayout.vue'

import Login from '@/views/Login.vue'
import Error404 from '@/views/error/404.vue'
import Drug from '@/views/drug/Drug.vue'
import DrugDraft from '@/views/drug/DrugDraft.vue'
import DrugAudit from '@/views/drug/DrugAudit.vue'
import DrugStore from '@/views/drugStore/DrugStore.vue'
import Order from '@/views/order/Order.vue'
import Work from '@/views/work/Work.vue'
import Sales from '@/views/sales/Sales.vue'
import AccountCenter from '@/views/accountCenter/AccountCenter.vue'


const number = 3

/**
 * 路由
 */
const routeConfig: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/work',
  },
  {
    path: '/h5',
    redirect: '/h5/drug',
  },
  {
    path: '/',
    name: 'Home',
    component: ManageLayout,
    meta: {
      title: '首页',
    },
    children: [
      {
        path: '/sales',
        name: 'Sales',
        meta: {
          title: '销售单',
        },
        component: Sales,
      },
      {
        path: '/accountCenter',
        name: 'AccountCenter',
        meta: {
          title: '账户中心',
        },
        component: AccountCenter,
      },
      {
        path: '/order',
        name: 'Order',
        meta: {
          title: '订单管理',
        },
        component: Order,
      },
      {
        path: '/work',
        name: 'Work',
        meta: {
          title: '工作台-康恩诺',
        },
        component: Work,
      },
      {
        path: '/drug',
        name: 'Drug',
        meta: {
          title: '产品管理',
        },
        component: Drug,
      },
      {
        path: '/drugAudit',
        name: 'DrugAudit',
        meta: {
          title: '产品审核',
        },
        component: DrugAudit,
      },
      {
        path: '/drugDraft',
        name: 'DrugDraft',
        meta: {
          title: '草稿箱',
        },
        component: DrugDraft,
      },
      {
        path: '/error404',
        name: 'error404',
        meta: {
          title: '页面不存在',
        },
        component: Error404,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: Login,
  },
]

/**
 * 菜单
 */
const menu = [
  {
    icon: require('@/assets/img/index-w.png'),
    index: '/work',
    title: '工作台',
    hiddenClose: true
  },
  {
    icon: require('@/assets/img/drug-w.png'),
    index: '1',
    title: '产品管理',
    subs: [
      {
        index: '/drugDraft',
        title: '草稿箱',
      },
      {
        index: '/drug',
        title: '产品管理',
      },
    ]
  },
  {
    icon: require('@/assets/img/order-w.png'),
    index: '/order',
    title: '订单管理',
  },
  {
    icon: require('@/assets/img/sales-w.png'),
    index: '/sales',
    title: '销售单',
  }
]

const r = {
  routeConfig,
  menu
}
export default r