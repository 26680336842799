




















import { Component, Vue, Emit } from 'vue-property-decorator'
import { UpdateDrugBatch as UpdateDrugBatchApi} from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import constant from '@/utils/constant'
import { timeFormate } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'

@Component({
  components: {
  },
})
export default class UpdateDrugBatch extends Vue {
  private constant = constant
  private show = false
  private data: any = {}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    batchNum: [this.requiredRule],
    validity: [this.requiredRule],
    prodDate: [this.requiredRule],
    stock: [this.requiredRule],
  }

  private get role() {
    return getRole();
  }

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
      this.data = {}
    }
  public open(param: any) {
    this.show = true
    this.data = param
  }

  public async save() {
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      await UpdateDrugBatchApi(this.data)
      Message.success('保存成功')
      this.close()
    })
  }
}
