
















import { Component, Vue, Emit } from 'vue-property-decorator'
import { UpdateDrugBatch as UpdateDrugBatchApi, QueryDrugBatchById} from '@/api'
import {  Message } from 'element-ui'
import { Form, Dialog } from 'vant';
import constant from '@/utils/constant'
import { timeFormate,DateFormat2 } from '@/utils/date/DateUtil'
import { getRole } from '@/store/userStore'
import router from '@/router';

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
  },
})
export default class UpdateDrugBatch extends Vue {
  private constant = constant
  private show = false
  private data: any = {}
  private requiredRule = { required: true, message: '不能为空', trigger: 'onBlur' }
  
  private showSelectProdDate = false
  private showSelectValidity = false
  private currentProdDate = new Date()
  private currentValidity = new Date()
  private supplierSelect = []
  private get role() {
    return getRole();
  }
  private drugInfo = {}

  public async created() {
    const id = router.currentRoute.query.id
    const d = await QueryDrugBatchById({id: id})
    this.data = d
    this.currentProdDate = new Date(d.prodDate)
    this.currentValidity = new Date(d.validity)
  }

  private DateFormat2(value:Date){
    if(!value){
      return ""
    }
    const s = timeFormate(new Date(value))
    console.log(s)
    return s
  }

  
  public beforeDestroy() {
    console.log('离开页面前')
    window.onbeforeunload = null
  }
  public destroyed() {
    console.log('离开页面了')

    window.onbeforeunload = null
  }

  private hintMessage = "页面中有正在编辑的内容，继续将不会保存"
  private showLeaveHint = false

  public mounted() {
    // window.onbeforeunload = (e:any)=>{
    //     e.returnValue = this.hintMessage;
    //     return this.hintMessage;
    // }
  }

  // * ---- 生命周期 ---- */
  // 改变url时，给出提示
  public beforeRouteLeave(to:any, from:any, next: any) {
    if(this.showLeaveHint){
      this.showLeaveHintFun(next);
    } else {
      next()
    }
    
  }

  public async showLeaveHintFun(next:any) {
    const a =await Dialog.confirm({
      title: "提醒",
      message: this.hintMessage
    })
    // const a = window.confirm(this.hintMessage)
    if (a) {
        next();
    } else {
        next(false);
    }
  }

  private selectProdDate(data:any){
    this.data.prodDate = timeFormate(new Date(data))
    this.showSelectProdDate = false
  }
  private selectValidity(data:any){
    this.data.validity = timeFormate(new Date(data))
    this.showSelectValidity = false
  }

  public async save() {
    await this.save2()
    this.showLeaveHint = false
    Message.success('保存成功')
  }

  public async save2(){
    const f = this.$refs.form as Form
    await f.validate()
    
    const id = await UpdateDrugBatchApi(this.data)
    this.data.id = id;
    router.back()
  }
  
}
