import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import menus from './menu'
import { getUser } from '@/store/userStore'
import defaultRoute from './modules/defaultRoute'
import supplierRoute from './modules/supplierRoute'
import platformRoute from './modules/platformRoute'
import operatorRoute from './modules/operatorRoute'
import role4Router from './modules/role4Router'
import role5Router from './modules/role5Router'

Vue.use(VueRouter)

const createRoute = () => {
  const userInfo = getUser()
  const role = userInfo?.user?.role
  let r = defaultRoute.routeConfig
  if(!role) {
    r = defaultRoute.routeConfig
  }else if (role == 1) {
    // 超级管理员
    r = platformRoute.routeConfig
    menus.menus = platformRoute.menu
  } else if (role == 2) {
    r = supplierRoute.routeConfig
    menus.menus = supplierRoute.menu
  } else if(role == 3){
    r = operatorRoute.routeConfig
    menus.menus = operatorRoute.menu
  } else if(role == 4){
    r = role4Router.routeConfig
    menus.menus = role4Router.menu
  } else if(role == 5){
    r = role5Router.routeConfig
    menus.menus = role5Router.menu
  }

  const newRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: r,
  })
  return newRouter
}

const router = createRoute()

router.beforeEach((to, from, next) => {
  // console.log('to')
  // console.log(to)
  // console.log(from)
  // 设置页面头部
  const u = getUser()
  const role = u?.user?.role
  let title = ''
  if (to.meta) {
    title = to.meta.title
  } else {
    title = '康恩诺ERP'
  }
  document.title = title
  const havePage  = to.matched.length > 0
  if(from?.path != '/'){
    if(to.meta){
      to.meta.from = from.path
    } else {
      to.meta = {from:from.path}
    }
  }
  if(!havePage && role == 3){
    // 错误页面
    next('/')
    return
  }else if(!havePage){
    // 错误页面
    next('/error404')
    return
  }

  const userInfo = getUser()
  const userName = userInfo.userName
  
  // 权限判断和登录判断
  if (!userName && to.path !== '/login') {
    next('/login')
  } else {
    next()
  }
})

// 加载路由
export const loadRoute = () => {
  const newRouter: any = createRoute()
  const r: any = router
  r.options = newRouter.options
  r.matcher = newRouter.matcher
}



export default router
