











import { Component, Vue } from 'vue-property-decorator'
import { QueryOrderSupplierInfo } from '@/api'
import { getRole, getUser } from '@/store/userStore'
import OrderSupplierItem from './componets/OrderSupplierItem.vue'
import router from '@/router'

@Component({
  components: {
    OrderSupplierItem
  },
})
export default class OperatorSupplierList extends Vue {
  private queryParam = {
    supplierName: "",
    orderBy: "od.create_time desc"
  }
  private selectValue = ""
  private tableTotal = 0
  private tableData: Array<any> = []
  private tableCurrntPage = 0
  private tableOver = false
  private supplierSelect= []
  private get role() {
    return getRole();
  }

  public async created() {
    // this.loadData()
  }

  public refresh(){
    this.tableCurrntPage = 1
    this.tableOver = false
    const sc = this.$refs.scroller as any
    sc.finishInfinite(true)
    this.loadData()
  }
  public infinite(){
    this.tableCurrntPage = this.tableCurrntPage+1
    this.loadData()
  }

  public async loadData() {
    const sc = this.$refs.scroller as any
    if(this.tableOver){
      sc.finishPullToRefresh(true)
      sc.finishInfinite(true)
      return
    }
    const param: any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * 10
    const result = await QueryOrderSupplierInfo(param)
    this.tableTotal = result.total
    this.tableOver = !result.hasNextPage
    if(this.tableCurrntPage == 1){
      this.tableData = result.list
    } else {
      this.tableData.push(...result.list)
    }

    sc.finishPullToRefresh()
    sc.finishInfinite()
  }


  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }
  private openDialog(dia: string, data?: any) {
    const f = this.$refs[dia] as any
    f.open(data)
  }
 
 private jumpToSet(){
    router.push("/h5/seting")
  }

private get headImgUrl(){
    const userInfo = getUser()
    if(userInfo?.user?.wxAccount?.headImgUrl){
      return userInfo?.user?.wxAccount?.headImgUrl
    }
    return require('@/assets/default_head_img.png')
  }
  
}
