// 转换
import constan from "../constant"


/** 后台用户，状态 */
export const StatusFormat = (status: Array<any>,type: any) => {
  const res = status.filter(e=>{
    return e.value == type
  })
  if(!res || res.length <= 0){
    return '-'
  }
  return res[0].label 
}

/** 后台用户，状态 */
export const PlatformStatusFormat = (type: number) => {
  const os = constan.PlatformStatus;
  const res = os.filter(e=>{
    return e.value == type
  })
  if(!res || res.length <= 0){
    return '-'
  }
  return res[0].label 
}


/** 导航栏状态 */
export const NacigationStatusFormat = (type: number) => {
  const os = constan.PlatformStatus;
  const res = os.filter(e=>{
    return e.value == type
  })
  if(!res || res.length <= 0){
    return '-'
  }
  return res[0].label 
}

/** 价格格式化 */
export const PriceFormat = (price: number) => {
  // price = price
  return price.toFixed ? price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0'
}
