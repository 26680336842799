import { Message } from "element-ui";

const copy = (text: string)=>{
  navigator.clipboard.writeText(text).then(()=>{
    Message.success('已成功复制到粘贴板')
  },()=>{
    Message.success('当前浏览器不支持复制')
  })
 
}

export default copy