









import { Component, Vue } from 'vue-property-decorator'
import { VQuery, VTable, QueryModel, TableHander, TableOperation } from '@/components/table'
import { QueryBrochureList, DeleteBrochure } from '@/api'
import { DateFormat } from '@/utils/date/DateUtil'
import { Message, MessageBox } from 'element-ui'
import router from '@/router'

@Component({
  components: {
    VQuery,
    VTable,
  },
})
export default class BrochureList extends Vue {
  private queryParam = {
  }
  private tableTotal = 0
  private tableData = []
  private tableCurrntPage = 1

  // 查询条件
  private queryModels: Array<QueryModel> = [
    { title: '名称', param: 'name' },
    { title: '头部title', param: 'pageTitle' },
  ]
  // 全局操作
  private queryOperation: Array<TableOperation> = [
    { title: '创建', type: 'primary', click: this.create },
  ]
  // 数据表头
  private tableHander: Array<TableHander> = [
    { title: '序号', param: 'id' },
    { title: '名称', param: 'name' },
    { title: '头部title', param: 'pageTitle' },
    { title: '小程序显示地址', param: 'id', formatter: this.xcxUrl },
    { title: '创建时间', param: 'createTime', formatter: DateFormat },
    {
      title: '操作', 
      width: 180,
      operation: [
        { title: '编辑', icon: 'el-icon-document',click: this.openEdit },
        { title: '删除', icon: 'el-icon-delete', class: 'red', click: this.deleteRow },
      ],
    },
  ]

  public async created() {
    this.loadData()
  }

  public create() {
    router.push('/editBrochure')
  }
  public openEdit(data: any) {
   router.push('/editBrochure?id='+data.id)
  }

  public async loadData() {
    const param: any = { ...this.queryParam }
    param.startRow = (this.tableCurrntPage - 1) * 10
    const result = await QueryBrochureList(param)
    this.tableTotal = result.total
    this.tableData = result.list
  }

  public search() {
    this.tableCurrntPage = 1
    this.loadData()
  }
  // 分页导航改变
  public pageChange(index: number) {
    this.tableCurrntPage = index
    this.loadData()
  }

  // 删除单行数据
  public deleteRow(data: any) {
    MessageBox.confirm('确定要删除吗？', '提示', {
      type: 'warning',
    }).then(async () => {
      const ruslt = await DeleteBrochure({ id: data.id })
      if (ruslt) {
        Message.success('删除成功')
        this.loadData()
      }
    })
  }

  private xcxUrl(value: string){
    return '/pages/show/show?id='+value
  }
}
