








import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { ImagePreview } from 'vant';


@Component({
  components: {},
})
export default class H5Image extends Vue {
  @Prop(String) private src!: string
  public showPop = false

  public openPop() {
    // this.showPop = true
    ImagePreview([this.src])
  }
}
